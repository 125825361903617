import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import "./Unauthorized.css";

export default class Unauthorized extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {    
     return (
        <Container className="p-5">
          <Row className="title-row">
            <Col md={12}>                
                <b><h2 className="h1 txt--upper txt--spacing rich-text__heading--background"><span>403 - Unauthorized</span></h2></b>
            </Col>
          </Row>
           <Row>
              <p>
                  Unfortunately you do not have the correct permissions to access this content.
              </p>
          </Row>
           <Row>
              <p>
                  If you require the permissions for this resource, please <a href="mailto:info@philkenyonputting.com">Contact us</a>.
              </p>
           </Row>
       </Container>
    );
  }
}


