import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../../store/user.store";

import "../../App.css";

class Activate extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState({ message: undefined });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorData !== this.props.errorData) {
      let message = this.props.errorData
        ? this.props.errorData.text
        : undefined;
      this.setState({ message });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    let message = this.validate();
    if(message){
        this.setState({message});
        return;
    }

    let path = window.location.pathname.split("/");
    let token = path.pop();
    let productId = path.pop();

    let model = {
      EmailAddress: this.state.email.trim(),
      Password: this.state.password.trim(),
      Token: token
    };

    this.props.activate(productId, model);
  }

  validate(){
    if(!this.state.email || this.state.email.trim().length === 0){
        return "Please enter email.";
    }

    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)) {
        return "Invalid email address.";            
    }

    if(!this.state.password || this.state.password.trim().length === 0){
        return "Please enter password.";
    }

    if(this.state.password.length < 8){
        return "Password must be at least 8 characters long.";
    }

    if (!/(?=.{9,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/.test(this.state.password)) {
        return "Invalid password.";            
    }

    if(!this.state.confirmPassword || this.state.confirmPassword.trim().length === 0){
        return "Please enter password confirmation.";
    }

    if(this.state.confirmPassword !== this.state.password){
        return "Password confirmation must be the same as the new password.";
    }
 }

  render() {
    return (
      <div className="container" style={{ backgroundColor: "#eee" }}>
        <div className="row mt-5" style={{ backgroundColor: "#eee" }}>
          <aside
            className="col-4 mx-auto text-center"
            style={{
              maxWidth: "400px",
              minWidth: "320px",
              backgroundColor: "#eee",
            }}
          >
            <img
              className="mb-3 mt-5"
              src="/assets/PKPA_1 mj.gif"
              width="270px"
              alt="logo"
            />

            <div className="card" style={{ textAlign: "left" }}>
              <article className="card-body">
                <h4 className="card-title mb-4 mt-1">Activate your account</h4>
                <form name="activate-form" onSubmit={e => this.onSubmit(e)}>
                  {" "}
                  <div className="form-group">
                    <label>Your email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="userEmail"
                      autoComplete="email"
                      placeholder="Please type your email address"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    {/* <a className="float-right" href="#">
                      Forgot?
                    </a> */}
                    <label>Your password</label>

                    <input
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      placeholder="Please type your password"
                      className="form-control"
                      onChange={(e) => this.setState({ password: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    {/* <a className="float-right" href="#">
                      Forgot?
                    </a> */}
                    <label>Confirm your password</label>

                    <input
                      type="password"
                      name="confirm-password"
                      autoComplete="current-password"
                      placeholder="Please confirm your password"
                      className="form-control"
                      onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <button className="btn btn-success">Activate</button>

                    <div className="message">{this.state.message}</div>                  
                  </div>
                </form>
              </article>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
  
export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(Activate);


