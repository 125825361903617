import React, { Component } from "react";
import "../App.css";
import "../css/Dashboard.css";
import { Link } from "react-router-dom";
import ChartistGraph from "react-chartist";
import { Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import VideoSearchComponent from '../components/search/search-component';

import UserProfile from './profile-component';
import NotificationsList from '../components/notifications/notifications.list';

import authService from '../services/auth-service';
import apiService from '../services/api-service';

import {mapGraphData} from '../helpers/graph-data-helper';

import './dashboard.page.css';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      showResults: false,
      posts: [],
      options: "",
      strokesGainedData: [],
    };
  }

 

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var today = new Date();
    today.setHours(today.getHours() + 1);

    const userID = this.props.user.userID;
    const cookieRetrieve = authService.getToken();

    fetch("api/Handicap/AddHandicap", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookieRetrieve}`,
      },
      body: JSON.stringify({
        handicapValue: this.state.handicapValue,
        ISID: userID,
        dateCreated: today,
      }),
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      .then((response) => console.log("Success:", response))
      .then(() => {
        fetch("api/Handicap/GetEveryHandicapByID/" + userID, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${cookieRetrieve}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({ posts: data, loading: false });
          });
      });
  };

  undoHandicap = () => {
    const cookieRetrieve = authService.getToken();
    const last = this.state.posts[this.state.posts.length-1];
    if(last && last.handicapID) {
      fetch("api/Handicap/" + last.handicapID, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${cookieRetrieve}`,
        },
      })
        .then(() => {})
        .then(() => {
          const updatedArray = [...this.state.posts];
          updatedArray.pop();
          this.setState({ posts: updatedArray, loading: false });
        });
    }
  };

  undoStrokesGained = () => {
    const cookieRetrieve = authService.getToken();
    const last = this.state.strokesGainedData[this.state.strokesGainedData.length-1];
    if(last && last.strokesGainedID) {
      fetch("api/StrokesGained/" + last.strokesGainedID, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${cookieRetrieve}`,
        },
      })
        .then(() => {})
        .then(() => {
          const updatedArray = [...this.state.strokesGainedData];
          updatedArray.pop();
          this.setState({ strokesGainedData: updatedArray, loading: false });
        });
    }
  };

  componentDidMount() {
    const userID = this.props.user.userID;
    if(!userID){
      return;
    }

    apiService.get("api/Handicap/GetEveryHandicapByID/" + userID, true)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ posts: data, loading: false });
      })
      .then(() => apiService.get("api/StrokesGained/GetEveryStrokeGainedByID/" + userID, true))
      .then((response) => response.json())
      .then((data) => {
        this.setState({ strokesGainedData: data, loading: false });
      })
      .catch((error) => {
        return;
      });
  }

  render() {
    let newLineData = {};
    let simpleLineOptions = {};

    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    newLineData = mapGraphData(this.state.posts, "dateCreated", "handicapValue");

    simpleLineOptions = {
      fullWidth: true,
      axisY: {
        onlyInteger: true,
        offset: 20,
      },
      showGrid: true,
      onlyInteger: true,
      chartPadding: {
        right: 40,
      },
    };

    let newStrokeData = {};
    let simpleStrokeOptions = {};    

    newStrokeData = mapGraphData(this.state.strokesGainedData, "dateUpdated", "strokesGainedScore");

    simpleStrokeOptions = {
      stackBars: true,
    };

    return (
      <React.Fragment>
        <div className="container p-3 dashboard-page">
          <div className="title-row row">
            <h2 className="cms-page-title h1 txt--upper txt--spacing rich-text__heading--background"><span>Welcome to your Academy Dashboard</span></h2>
          </div>
          <Row className="dashboard-row">
            <Col xl={6} className="dashboard-profile-col">
              <UserProfile user={this.props.user}></UserProfile>
            </Col>
            <Col xl={6}>
              <Row>
                <Col lg={12} className="dashboard-notifications">
                  <h4>Notifications</h4>
                  <div className="dashboard-notifications-list">
                    <NotificationsList></NotificationsList>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="divider"></Row>
          <VideoSearchComponent user={this.props.user}></VideoSearchComponent>
          <Row className="divider"></Row>
          <div className="row">
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card" style={{ border: "1px solid #adff2f7d" }}>
                <div className="card-body">
                    <h4 className="card-title" style={{ textAlign: "center" }}>Handicap Tracker</h4>
                  <ChartistGraph
                    className="ct-perfect-fourth"
                    data={newLineData}
                    type="Line"
                    options={simpleLineOptions}
                  />
                </div>

                <div
                  className="card-footer"
                  style={{ borderTop: "1px solid #adff2f7d" }}
                >
                  <Form onSubmit={this.handleSubmit}>
                    <div className="form-inline">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Handicap"
                          value={this.state.handicapValue}
                          onChange={this.handleChange}
                          name="handicapValue"
                          className="form-control"
                          style={{
                            borderBottomRightRadius: " 0",
                            borderTopRightRadius: "0",
                          }}
                        ></input>
                      </div>

                      <div className="form-group">
                        <button
                          onChange={this.handleClick}
                          className="btn mb-0"
                          type="submit"
                          style={{
                            color: "white",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            padding: "16px",
                            background: "black",
                            border: "none",
                          }}
                        >
                          Save
                        </button>
                      </div>
                      <div className="form-group">
                        {this.state.posts.length > 0
                        && <button type="button" onClick={this.undoHandicap} className="btn btn-primary mt-2 ml-2" style={{
                          background: 'black',
                          color: 'white',
                          borderColor: 'black'
                        }}>Undo</button>}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

            <div className="col-md-6 grid-margin stretch-card">
              <div className="card" style={{ border: "1px solid #adff2f7d" }}>
                <div className="card-body">
                    <h4 className="card-title" style={{ textAlign: "center" }}>Strokes Gained</h4>

                  <ChartistGraph
                    className="ct-perfect-fourth"
                    data={newStrokeData}
                    type="Line"
                    options={simpleStrokeOptions}
                  />
                </div>
                {this.state.strokesGainedData.length > 0 &&
                  <div className="card-footer">
                    <button className="btn btn-primary mt-2" onClick={this.undoStrokesGained} style={{
                      background: 'black',
                      color: 'white',
                      borderColor: 'black'
                    }}>
                      Undo
                    </button>
                  </div>
                }
              </div>
            </div>
          </div> 
          <Row className="divider"></Row>
          <div className="clearfix"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="text-left">
                <h4>Videos Categories </h4>
                <hr></hr>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
             
                <Link className="nav-link" to="/video-lessons/aim-bias">
                  <img
                    className="card-img-top"
                    src={require("../assets/images/01.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
              </div>
           

            <div className="col-md-6 mb-3">
                <Link className="nav-link" to="/video-lessons/stroke-dynamics/">
                  <img
                    className="card-img-top"
                    src={require("../assets/images/02.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
               
              </div>
          

            <div className="col-md-6 mb-3">
           
                <Link className="nav-link" to="/video-lessons/speed-dynamics/">
                  <img
                    className="card-img-top"
                    src={require("../assets/images/03.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
               
             
            </div>

            <div className="col-md-6 mb-3">
             
                <Link className="nav-link" to="/video-lessons/green-reading/">
                  <img
                    className="card-img-top"
                    src={require("../assets/images/04.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>

                
             
            </div>

            <div className="col-md-6 mb-3">
            
                <Link
                  className="nav-link"
                  to="/video-lessons/targeting-strategy/"
                >
                  <img
                    className="card-img-top"
                    src={require("../assets/images/05.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
               
            </div>

            <div className="col-md-6 mb-3">
            
                <Link
                  className="nav-link"
                  to="/video-lessons/ball-roll-dynamics/"
                >
                  <img
                    className="card-img-top"
                    src={require("../assets/images/06.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
                

            </div>

            <div className="col-md-6 mb-3">
              
                <Link className="nav-link" to="/video-lessons/putter-fitting">
                  <img
                    className="card-img-top"
                    src={require("../assets/images/07.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
              
          
            </div>

            <div className="col-md-6 mb-3">
            
                <Link className="nav-link" to="/video-lessons/practice-drills">
                  <img
                    className="card-img-top"
                    src={require("../assets/images/08.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
              
            
            </div>
          </div>
          <div></div>
     
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => state.users
)(Dashboard);
