import {apiErrors} from '../services/api-service';
import authService, { updateUserType } from '../services/auth-service';
import apiService from '../services/api-service';
import analytics from '../services/analytics-service';

const requestUserType = "FETCH_USER_REQUEST";
const receiveUserType = "FETCH_USER_RESPONSE";
const errorUserType = "FETCH_USER_ERROR";

const requestUserUpdate = "UPDATE_DETAILS_REQUEST";
const receiveUserUpdate = "UPDATE_DETAILS_RESPONSE";

const requestPasswordUpdate = "UPDATE_PASSWORD_REQUEST";
const receivePasswordUpdate = "UPDATE_PASSWORD_RESPONSE";
const errorPasswordType = "UPDATE_PASSWORD_ERROR";

const initialState = { userIsLoading: false, isAuthenticated: authService.isAuthenticated(), user: authService.getUser() };

export const userActions = {
  fetchUser: () => (dispatch) => {
    dispatch({type: requestUserType, isLoading: true});
  
    let user = authService.getUser();
    let authenticated = false;

    if (user) {
      authenticated = true;
    }
    dispatch({type: receiveUserType, isLoading: false, user: user, isAuthenticated: authenticated});
  },
  login: (loginForm) => (dispatch, getState) => login(loginForm, dispatch, getState().users),
  activate: (productId, activateForm) => (dispatch, getState) => activate(productId, activateForm, dispatch, getState().users),
  updateAvatar: (formData) => (dispatch, getState) => updateAvatar(formData, dispatch, getState().users),
  updateUserDetails: (data) => (dispatch, getState) => updateUserDetails(data, dispatch, getState().users),
  updatePassword: (data) => (dispatch, getState) => updatePassword(data, dispatch, getState().users),    
}
 
function login(loginForm, dispatch, state) {
    if (state.isLoading) {
      return;
    }

    dispatch({type: requestUserType, isLoading: true});
  
    let url = `api/user/CreateToken`;
    apiService.post(url, loginForm, false)
      .then(
        (resp)  => {
        return resp.json();
      })
      .then(
        (result) => {
          analytics.login();
          authService.setUser(result.token, result.refToken, true);
        }
    ).catch(error => {
        if (error.data) {
          dispatch({ type: errorUserType, errorData: error.data });            
        } else {
          dispatch({ type: errorUserType, errorData: { status: 0, text: error.message } });
        }
    });
};
 
function activate(productId, activateForm, dispatch, state) {
    if (state.isLoading) {
      return;
    }

    dispatch({type: requestUserType, isLoading: true});
  
    let url = `api/user/ActivateUser/${productId}`;
    apiService.post(url, activateForm, false)
      .then(
        (resp)  => {
        return resp.json();
      })
      .then(
        (result) => {
          analytics.login();
          authService.setUser(result.token, result.refToken, true);
        }
    ).catch(error => {
        if (error.data) {
          dispatch({ type: errorUserType, errorData: error.data });            
        } else {
          dispatch({ type: errorUserType, errorData: { status: 0, text: error.message } });
        }
    });
};

function updateAvatar(formData, dispatch, state) {
  if (state.isLoading) {
    return;
  }
  dispatch({ type: requestUserUpdate, isLoading: true });
  
  let url = `api/User/ChangeAvatar`;
  apiService.put(url, formData, true, "form")
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        authService.setUser(result.token, result.refToken, true);
        dispatch({ type: receiveUserUpdate, result }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorUserType, errorData: error.data });            
        } else {
          dispatch({ type: errorUserType, errorData: apiErrors.ERR000 });  
        }
      }  
  );
};

function updateUserDetails(data, dispatch, state) {
  if (state.isLoading) {
    return;
  }

  dispatch({ type: requestUserUpdate, isLoading: true });

  let url = `api/User/ChangeUserDetails`;
  apiService.put(url, data, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        authService.setUser(result.token, result.refToken, true);
        dispatch({ type: receiveUserUpdate }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorUserType, errorData: error.data });            
        } else {
          dispatch({ type: errorUserType, errorData: apiErrors.ERR000 });  
        }
      }  
  );
};

function updatePassword(data, dispatch, state) {
  if (state.isLoading) {
    return;
  }
  dispatch({ type: requestPasswordUpdate, isLoading: true });

  let url = `api/User/ChangePassword`;
  apiService.put(url, data, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({ type: receivePasswordUpdate }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorPasswordType, errorData: error.data });            
        } else {
          dispatch({ type: errorPasswordType, errorData: apiErrors.ERR000 });  
        }
      }  
    );
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestUserType) {
    return { ...state, isLoading: true, errorData: null, userUpdated: false };
  }

  if (action.type === receiveUserType) {
    return { ...state, isLoading: false, user: action.user, errorData: null, isAuthenticated: action.isAuthenticated };
  }
  
  if (action.type === requestUserUpdate) {
    return { ...state, isLoading: true, errorData: null, userUpdated: false };
  }
  
  if (action.type === receiveUserUpdate) {
    return { ...state, isLoading: false, userUpdated: true };
  }

  if (action.type === requestPasswordUpdate) {
    return { ...state, isLoading: true, passwordError: null, userUpdated: false };
  }

  if (action.type === receivePasswordUpdate) {
    return { ...state, isLoading: false, userUpdated: true };
  }
 
  if (action.type === updateUserType) {
    return { ...state, isLoading: false, user: action.user, isAuthenticated: action.isAuthenticated };
  }

  if (action.type === errorPasswordType) {
    return { ...state, isLoading: false, passwordError : action.errorData };
  }

  if (action.type === errorUserType) {
    return { ...state, isLoading: false, errorData : action.errorData };
  }

  return state;
};