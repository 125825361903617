import React, { Component } from 'react';
import { Container, Tabs, Tab, Row, Col } from 'react-bootstrap';
import Dropbox from '../../components/dropbox/dropbox.component';
import DownloadButton from '../../components/downloadButton';
import VideoResource from '../components/video.resource.js';

import './resources-swing-length.css';

export default class ResourcesSwingLength extends Component {
   constructor() {
      super();

      this.state = { folderRef: React.createRef() }
   }

   componentDidMount() {     
   }

   render() {
      return (
         <Container>
            <Tabs defaultActiveKey="cheat-sheets">
               <Tab eventKey="cheat-sheets" title="Swing Length Chart Flat Putts">
                  <Row>
                     <Col md={6}>
                        <VideoResource title='Swing length chart' url={require('../../assets/videos/puttranome/swing_length_chart_for_puttranome.mp4')}></VideoResource>
                     </Col>
                     <Col md={6}>
                        <Row><h3>Swing Length Chart Downloads</h3></Row>
                           <DownloadButton file={require('../../assets/downloads/swing/129bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="129bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/120bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="120bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/113bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="113bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/106bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="106bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/100bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="100bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/95bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="95bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/90bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="90bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/86_bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="86bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/82_bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="82bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/78_bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="78bpm Swing Length Cheat Sheet"/>

                           <DownloadButton file={require('../../assets/downloads/swing/75_bpm_Swing_Length_Cheat_Sheet.pdf')}
                                             name="75bpm Swing Length Cheat Sheet"/>                    
                     </Col>
                  </Row>
               </Tab>
               <Tab eventKey="with-slope" title="Swing Length Chart With Slope">
                  <Dropbox folderPath="/swing length sheets with slope"></Dropbox>
               </Tab>
            </Tabs>
         </Container>
      );
   }
}