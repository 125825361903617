import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../../store/user.store";

import "../../App.css";

class Login extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState({ message: undefined });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorData !== this.props.errorData) {
      let message = this.props.errorData
        ? this.props.errorData.text
        : undefined;
      this.setState({ message });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    let model = {
      Username: this.state.email,
      Password: this.state.password,
    };

    this.props.login(model);
  }

  render() {
    return (
      <div className="container" style={{ backgroundColor: "#eee" }}>
        <div className="row mt-5" style={{ backgroundColor: "#eee" }}>
          <aside
            className="col-4 mx-auto text-center"
            style={{
              maxWidth: "400px",
              minWidth: "320px",
              backgroundColor: "#eee",
            }}
          >
            <img
              className="mb-3 mt-5"
              src="/assets/PKPA_1 mj.gif"
              width="270px"
              alt="logo"
            />

            <div className="card" style={{ textAlign: "left" }}>
              <article className="card-body">
                <h4 className="card-title mb-4 mt-1">Sign in</h4>
                <form name="loginForm" onSubmit={e => this.onSubmit(e)}>
                  {" "}
                  <div className="form-group">
                    <label>Your email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="userEmail"
                      autoComplete="email"
                      placeholder="Please type your email address"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    {/* <a className="float-right" href="#">
                      Forgot?
                    </a> */}
                    <label>Your password</label>

                    <input
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      placeholder="Please type your password"
                      className="form-control"
                      onChange={(e) => this.setState({ password: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <button className="btn btn-success">Login</button>

                    <div className="message">{this.state.message}</div>                  
                  </div>
                </form>
              </article>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
  
export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(Login);


