import apiService from '../services/api-service';
import authService from '../services/auth-service';

const requestResultsType = "FETCH_RESULTS_PENDING";
const receiveResultsType = "FETCH_RESULTS_SUCCESS";

const requestUpdateType = "UPDATE_SELECTION_PENDING";
const receiveUpdateType = "UPDATE_SELECTION_SUCCESS";

const errorFetchResultsType = "FETCH_RESULTS_ERROR";
const errorUpdateType = "UPDATE_ERROR";

const initialState = { isLoading: false, results: [], saved: false };

export const actions = {
  requestResults: () => (dispatch, getState) => fetchResults(dispatch, getState().twelveWeek),
  addResult: (result) => (dispatch, getState) => addResult(result, dispatch, getState().twelveWeek)
};

function fetchResults(dispatch, state) {
  if(state.isLoading)
  {
    return;
  } 

  dispatch({type: requestResultsType });

  let url = `api/twelve-week/get-results`;
  apiService.get(url, true)
  .then(resp => {
    return resp.json();
  })
    .then(
      (result) => {
        dispatch({type: receiveResultsType, data: result });
      },
      (data) => {
        dispatch({ type: errorFetchResultsType, errorData: data });
      }
    );  
}

function addResult(result, dispatch, state) {
  if(state.isLoading)
  {
    return;
  } 

  dispatch({type: requestUpdateType });

  let url = `api/twelve-week/add-result`;
  apiService.post(url, result, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        authService.setUser(result.token, result.refToken, false);
        dispatch({type: receiveUpdateType, data: result });
      },
      (data) => {
        dispatch({ type: errorUpdateType, errorData: data });
      }  
  );
}

export const reducer = (state, action) => {
  state = state || initialState;
   
  if (action.type === requestResultsType) {   
      return { ...state, isLoading: true, errorData: null };
  }

  if (action.type === receiveResultsType) {
      return { ...state, isLoading: false, results: action.data};
  }
  
  if (action.type === requestUpdateType) {   
      return { ...state, isLoading: true, saved: false, errorData: null };
  }

  if (action.type === receiveUpdateType) {
      return { ...state, isLoading: false, saved: true};
  }

  if (action.type === errorFetchResultsType) {
      return { ...state, isLoading: false, errorData : action.errorData };
  }

  if (action.type === errorUpdateType) {
      return { ...state, isLoading: false, errorData : action.errorData };
  }

  return state;
}