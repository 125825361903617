import apiService, {apiErrors} from '../services/api-service';

const requestUsersType = "FETCH_USERS_REQUEST";
const receiveUsersType = "FETCH_USERS_RESPONSE";

const requestRolesType = "FETCH_ROLES_REQUEST";
const receiveRolesType = "FETCH_ROLES_RESPONSE";

const requestPasswordGenerate = "GENERATE_PASSWORD_REQUEST";
const receivePasswordGenerate = "GENERATE_PASSWORD_RESPONSE";

const errorAdminType = "ERROR_ADMIN_RESPONSE";

const initialState = { isLoading: false, userList: [], roles: [] };

export const actions = {
  fetchUsers: () => (dispatch, getState) => fetchUsers(dispatch, getState().admin),
  fetchRoles: () => (dispatch, getState) => fetchRoles(dispatch, getState().admin),
  updateUser: (userId, data) => (dispatch, getState) => updateUser(userId, data, dispatch, getState().admin),
  generatePassword: (userId) => (dispatch, getState) => generatePassword(userId, dispatch, getState().admin)
}

function fetchUsers(dispatch, state) {
  dispatch({ type: requestUsersType });
  
  let url = `api/User/GetUserList`;
  apiService.get(url, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({ type: receiveUsersType, result }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorAdminType, errorData: error.data });            
        } else {
          dispatch({ type: errorAdminType, errorData: apiErrors.ERR000 });  
        }
      }  
  );
};

function fetchRoles(dispatch, state) {
  dispatch({ type: requestRolesType });
  
  let url = `api/User/GetRoleList`;
  apiService.get(url, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({ type: receiveRolesType, result }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorAdminType, errorData: error.data });            
        } else {
          dispatch({ type: errorAdminType, errorData: apiErrors.ERR000 });  
        }
      }  
  );
};

function updateUser(userId, data, dispatch, state) {
  if (state.isLoading) {
    return;
  }
  dispatch({ type: requestUsersType });
  
  let url = `api/User/UpdateUser/${userId}`;
  apiService.put(url, data, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({ type: receiveUsersType, result }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorAdminType, errorData: error.data });            
        } else {
          dispatch({ type: errorAdminType, errorData: apiErrors.ERR000 });  
        }
      }  
  );
};

function generatePassword(userId, dispatch, state) {
  if (state.isLoading) {
    return;
  }
  dispatch({ type: requestPasswordGenerate });
  
  let url = `api/User/GeneratePassword/${userId}`;
  apiService.put(url, null, true)
    .then(
      (resp)  => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({ type: receivePasswordGenerate, newPassword: result.newPassword }); 
      },
      (error) => {
        if (error.data) {
          dispatch({ type: errorAdminType, errorData: error.data });            
        } else {
          dispatch({ type: errorAdminType, errorData: apiErrors.ERR000 });  
        }
      }  
  );
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestUsersType) {
    return { ...state, isLoading: true, errorData: null };
  }

  if (action.type === receiveUsersType) {
    return { ...state, isLoading: false, userList: action.result};
  }

  if (action.type === requestRolesType) {
    return { ...state, isLoading: true, errorData: null };
  }

  if (action.type === receiveRolesType) {
    return { ...state, isLoading: false, roles: action.result};
  }

  if (action.type === requestPasswordGenerate) {
    return { ...state, isLoading: true, newPassword: undefined, errorData: null };
  }

  if (action.type === receivePasswordGenerate) {
    return { ...state, isLoading: false, newPassword: action.newPassword};
  }

  if (action.type === errorAdminType) {
    let loadState = {...state.loadState };
    loadState[action.page] = false;
  
    return { ...state, loadState, errorData : action.errorData };
  }

  return state;
}