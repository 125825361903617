import React, {Component} from "react";
import { Form, FormGroup, Button, Row} from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../../store/performance-stats.store";

import AnalyticsService from '../../../../services/analytics-service';

class LeaderboardInput extends Component {
   constructor(props) {
      super();

      this.state = { score: 0, handicap: 0 };
   }


   submitScores() {   
      AnalyticsService.addEvent("100 Ft Drills", "Stats-Interaction", "Add drill");
      this.props.addLeaderboard(this.state.score, this.state.handicap);
      this.setState({ score: 0, handicap: 0 })
   }

   undoScore() {
      AnalyticsService.addEvent("100 Ft Drills", "Stats-Interaction", "Remove drill");
      this.props.removeLeaderboard();
   }

   render() {
      return (
         <Form onSubmit={this.submitScores} className="drillgraph-footer">
             <Row className="form-inline leaderboard-input">
               <FormGroup>
                     <label htmlFor="leaderboard-input-score">Your Score:</label>
                     <select
                        value={this.state.handicap.toString()}
                        onChange={(e) => this.setState({handicap: parseInt(e.target.value)})}
                     >
                        {this.renderHandicaps()}
                     </select>
                     <input
                        id="leaderboard-input-score"
                        type="number"
                        value={this.state.score.toString()}
                        onChange={(e) => this.setState({ score: parseInt(e.target.value)})}
                        placeholder="Enter your 100ft score"
                        className="leaderboard-input-textbox score-input"
                        >
                     </input>
               </FormGroup>
               <Button className="btn btn-primary" onClick={() => this.submitScores()}>Save</Button>
               <Button className="btn btn-primary" onClick={() => this.undoScore()} disabled={this.props.total === 0}>Undo</Button>
            </Row>
         </Form>
      );
   }

   renderHandicaps() {
      var handicaps = [
         <option key="0" value="0">[Select Handicap]</option>,
         <option key="99" value="99">Pro</option>,
         <option key="999" value="999">N/A</option>
      ];

      for (let h = 1; h < 30; h++)
      {
         handicaps.push(<option key={h} value={h}>{h}</option>);
      }

      handicaps.push(<option key="30" value="30">30+</option>);

      return handicaps;
   }
}
export default  connect(
   state => state.performanceStats,
   dispatch => bindActionCreators(actions, dispatch)
 )(LeaderboardInput)