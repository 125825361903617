import React from 'react';
import {
  Switch,
   Route
} from "react-router-dom";

import Dashboard from "../pages/dashboard/dashboard.page";
import FAQ from "../../FAQ";

import PerformanceStats from "../../pages/performance-stats/performance-stats.page.component";

import StrokesGained from "../../Strokes-Gained";

import VideoLessonRouting from '../../pages/video-lessons/video-lessons.routing.component';
import UserProfile from '../../pages/user-profile/user-profile.page.component';

import CmsPage from "../../cms/page.component";
import DefaultRouting from '../../shared/routing/default-routing.component';
   
const SecureRouting = props => {
   return (
      <Switch>
         <Route exact path="/user-profile" component={UserProfile}></Route>
         <Route exact path="/FAQ" component={FAQ}></Route>
         <Route exact path="/terms-and-conditions"
            component={() => <CmsPage pageTag="website/terms-and-conditions" ></CmsPage>}>         
         </Route>
         <Route exact path="/your-stats" component={PerformanceStats}></Route>
         <Route exact path="/strokes-gained" component={StrokesGained}></Route>
         
         <Route path="/Video-Lessons" component={VideoLessonRouting}></Route>

         <Route path="/dashboard" component={Dashboard}></Route>
         
         <Route path="/" render={() => <DefaultRouting {...props} default="/dashboard" />} />
      </Switch>
   );
}

export default SecureRouting;