import React from "react";
import AnalyticsService from "../../services/analytics-service";

export default props => {
   return (
      <div className="thumbnail">
         <div className="thumbnail-image-row">
            <a href={props.uri}
               target="_blank"
               rel="noopener noreferrer"
               onClick={e => AnalyticsService.addEvent("File Download", "Downloads", props.path)}
            ><img className="thumbnail-image" alt={props.path} src={"data:image/png;base64," + props.thumbnail}></img></a>
         </div>
         <div className="thumbnail-text">
            {props.name}
         </div>
      </div>
   );
}