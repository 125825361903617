import React from 'react';
import ReactPlayer from "react-player";

export default  (props) => (
   <div className={"cms-block video-block " + (props.className ? props.className : "")}>
      <div className="video-title"><h3>{props.title}</h3></div>
      <div className="video-text" dangerouslySetInnerHTML={{__html: props.text}} ></div>
      <div className="player-wrapper">
      <ReactPlayer
         className="react-player"
         controls={true}
         width="100%"
         height="100%"
         url={props.url}
         playing={props.playing}
         onPause={props.onPause}
         onStart={props.onStart}
      />
      </div>
      <div className="video-title"><h3>{props.caption}</h3></div>
   </div>
);