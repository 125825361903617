import React, { Component } from "react";
import LineGraph from "../../../../components/graph/line.graph.component"
import GraphSeries from "../../../../components/graph/graph-series.component";
import DataPoint from "../../../../components/graph/data-point";

import { LegendContainer, Legend } from "../../../../components/graph/legend.component";
import DrillMedian from "./drill.median.component";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../../store/performance-stats.store";

import { calculateMedian } from "./../../../../shared/Utils";

import LeaderboardInput from '../leaderboard/leaderboard.input.component';

import moment from 'moment';

class HundredFtGraph extends Component {
   constructor(props) {
      super();

      this.state = {
         labels: [],
         drills: [],
         medians: [],
         showMedians: true,
         handicapRange: {
            minRange: props.handicap,
            maxRange: props.handicap
         },
         optionValue: {},
         optionValues: [
            { title: "Tournament winner", value: { hundred: 100 } }
         ]
      };
   }

   componentDidMount() {
      this.props.requestDrills(this.props.handicap, this.props.handicap, "hundred");
   }

   componentDidUpdate(prevProps) {
      if (this.props.leaderboard.updated && prevProps.leaderboard.updated !== this.props.leaderboard.updated) {
         let range = this.state.handicapRange;
         this.props.requestDrills(range.minRange, range.maxRange, "hundred");
      }

      if (prevProps.hundred.series === this.props.hundred.series) {
         return;
      }
      this.setSeries(this.state.optionValue);
   }

   handleOptionChange(value) {
      this.setState({ optionValue: value }, () => this.setSeries(value));
   }

   handleMedianChange(minHandicap, maxHandicap) {
      if (this.state.handicapRange.minRange === minHandicap && this.state.handicapRange.maxRange === maxHandicap) {
         return;
      }

      let handicapRange = {
         minRange: minHandicap,
         maxRange: maxHandicap
      }
      this.setState({ handicapRange }, () => this.props.requestDrills(minHandicap, maxHandicap, "hundred"));
   }

   render() {
      return (
         <LineGraph title="100 Ft Drills" className="hundredftgraph" labels={this.state.labels}>
            {this.state.showMedians &&
               <GraphSeries className="drillgraph-100ft-median" color="light-green" labels={this.state.labels} datapoints={this.state.medians}></GraphSeries>
            }
            <GraphSeries className="drillgraph-100ft" color="green" labels={this.state.labels} datapoints={this.state.drills}></GraphSeries>
            <DrillMedian
               id="100-ft-graph"
               handicap={this.props.handicap}
               active={this.state.showMedians}
               options={this.state.optionValues}
               onOptionChange={(value) => this.handleOptionChange(value)}
               onHandicapChange={(minRange, maxRange) => this.handleMedianChange(minRange, maxRange)}
               setActive={active => this.setState({ showMedians: active })}
            ></DrillMedian>
            <LeaderboardInput total={this.state.drills.length}></LeaderboardInput>
            <LegendContainer>
               <Legend title="100ft" color="green" value={calculateMedian(this.state.drills.map(x => x.value))}></Legend>
               {this.state.showMedians &&
                  <Legend title="100ft(Avg)" color="light-green" value={calculateMedian(this.state.medians.map(x => x.value))}></Legend>
               }
            </LegendContainer>
         </LineGraph>
      );
   }

   getLabels() {
      let series = this.props.hundred.series;
      if (!series) {
         return;
      }

      let dates = series.map(x => moment(x.dateUpdated).format("YYYYMMDD HHmmss"));
      let sorted = dates.sort((a, b) => a - b);
      let labels = sorted.map(x => { return { display: moment(x).format("DD-MMM-YYYY HH:mm:ss"), value: x }; });

      return labels
   }

   setSeries({ hundred }) {
      let series = this.props.hundred.series;
      if (!series) {
         return;
      }

      let drills = series.map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), x.score));
      let medians = series.map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), hundred ? hundred : x.medianScore));
      let labels = this.getLabels();

      this.setState({ drills, medians, labels })
   }
}

export default connect(
   state => state.performanceStats,
   dispatch => bindActionCreators(actions, dispatch)
)(HundredFtGraph);