import React from "react";
import { Row } from 'react-bootstrap';
import Pagination from './search-pagination-component';
import VideoSearchBlock from './video-search.block.component';

export default (props) => {
    return (
        <div>
            <div className={"dashboard-search_list"}>
                {renderBlocks(props)}
            </div>
            {!props.search.loading && props.search.total > 0 &&
                <Pagination current={props.search.page} total={props.search.total} onPageChange={props.onChange}>
                </Pagination>
            }
        </div>
    )
}

function renderBlocks(props) {
    let search = props.search;

    if(search.loading)
    {
        return (<Row className="dashboard-search_message">Searching {props.title} items for search term {props.currentSearch}</Row>)
    }

    if(search.error){
        return (<Row className="dashboard-search_error">{search.error}</Row>)
    }

    if(search.items.length > 0){
        return search.items.map((v,i)=> {
            const isActive = props.active && props.currentVideo === v.id
            return (
              <VideoSearchBlock key={i} {...v}
                playing={isActive}
                onPause={() => props.onPause()}
                onStart={() => props.onStart(v.id)}
              ></VideoSearchBlock>);
        });
    }

    if(search.items.length === 0){
        return (<Row className="dashboard-search_message">No {props.title} items found for search term {props.currentSearch}</Row>);
    }
}
