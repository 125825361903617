import React, { Component } from "react";
import "./App.css";
import {
    Switch,
    Route,
    useLocation
} from "react-router-dom";

import "./App.scss";
import "./css/custom.css";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userActions } from "./store/user.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./css/main.css";
import PublicLayout from "./shared/layouts/public-layout.component";
import PrivateLayout from "./shared/layouts/private-layout.component";
import LightPrivateLayout from "./performance-programme/layouts/private-layout.component";

import authService from './services/auth-service'

class App extends Component {
    constructor() {
        super();

        this.state = {
            isFullPageLayout: false
        };
    }

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        if (this.props.isAuthenticated === undefined) {
            return null;
        }

        return (
            <>
                <ReactNotification />
                <Switch>
                    <Route path="/">{this.renderLayout()}</Route>
                </Switch>
            </>
        );
    }

    renderLayout() {
        if (!this.props.isAuthenticated) {
            return (<PublicLayout location={() => useLocation()} />);
        }

        if (this.props.user.roles.includes("PPMember")) {
            return (
                <LightPrivateLayout location={() => useLocation()}></LightPrivateLayout>
            );
        }

        if (this.props.user.roles.includes("AcademyMember")) {
            return (<PrivateLayout location={() => useLocation()}></PrivateLayout>);
        }

        authService.clearUser();
    }
}

export default connect(
    state => state.users,
    dispatch => bindActionCreators(userActions, dispatch)
)(App)
