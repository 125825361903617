import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';

import { useHistory } from "react-router-dom";
import authService from "../services/auth-service";

import "./Unauthorized.css";

const ErrorPage = props => {
  const history = useHistory();

  if (authService.getUser()) {
    authService.clearUser();
  }
  
  function logIn() {
    history.push("/login");
  }

  return (
      <Container className="p-5">
        <Row className="title-row">
          <Col md={12}>                
              <b><h2 className="h1 txt--upper txt--spacing rich-text__heading--background"><span>An Error Has Occured</span></h2></b>
          </Col>
        </Row>
          <Row>
            <p>
                Unfortunately we were unable to fulfil your request.
            </p>
        </Row>
          <Row>
            <p>
                Please <a href="mailto:info@philkenyonputting.com">Contact us</a> so we can help you resolve the issue.
            </p>
          </Row>
          <Row>
            <p>
              Alternatively click on the button below to attempt to log in.
            </p>
          </Row>
          <Row>
            <Button onClick={() => logIn()}>Log In</Button>
          </Row>
      </Container>
  );
}

export default ErrorPage;
