import React, { Component } from "react";
import { config } from "../../config";

let rootUrl = config.API_ROOT;

const defaultImagePath = '../../assets/avatar/0.jpg';

export default class UserImage extends Component {
   constructor() {
     super();
     this.state = {
         imgPath : defaultImagePath
      };
   }

   componentDidMount(){
      if(!this.props.avatarId)
      {
         return;
      }

      let imgPath = rootUrl + "/api/asset/" + this.props.avatarId;
      this.setState({imgPath})
   }

   componentDidUpdate(prevProps){
      if(prevProps.avatarId === this.props.avatarId){
         return;
      }
      var imgPath = defaultImagePath;

      if(this.props.avatarId)
      {
         imgPath = rootUrl + "/api/asset/" + this.props.avatarId;
      }
      this.setState({imgPath})
   }
 
   render() {
     return <img className={this.props.className} src={this.state.imgPath} alt="profile" onError={() => this.setState({imgPath: defaultImagePath})}/>
   }
 }