import React from "react";
import authService from '../services/auth-service';
import { Container, Form, ButtonGroup, ToggleButton } from "react-bootstrap";
import VideoResource from '../Resources/components/video.resource.js';
import { store } from "react-notifications-component";

import AnalyticsService from '../services/analytics-service';

class StrokesGained extends React.Component {
    state = {
        practice: false,
        puttdistance1: 0,
        putts1: 0,
        puttdistance2: 0,
        putts2: 0,
        puttdistance3: 0,
        putts3: 0,
        puttdistance4: 0,
        putts4: 0,
        puttdistance5: 0,
        putts5: 0,
        puttdistance6: 0,
        putts6: 0,
        puttdistance7: 0,
        putts7: 0,
        puttdistance8: 0,
        putts8: 0,
        puttdistance9: 0,
        putts9: 0,
        puttdistance10: 0,
        putts10: 0,
        puttdistance11: 0,
        putts11: 0,
        puttdistance12: 0,
        putts12: 0,
        puttdistance13: 0,
        putts13: 0,
        puttdistance14: 0,
        putts14: 0,
        puttdistance15: 0,
        putts15: 0,
        puttdistance16: 0,
        putts16: 0,
        puttdistance17: 0,
        putts17: 0,
        puttdistance18: 0,
        putts18: 0,
        hol1Total: 0,
        strokesGained:0,
        strokesSaving: false
    };

    handleChangehole1 = (event, type) => {
        if (type === "select") {
            this.setState({ [event.target.name]: event.target.value });
        }
        else {
            var val = event.target.value.replace(/[^0-9]/g, "");
            if (val > 90) {
                val = 90;
            }
            this.setState({ [event.target.name]: val });
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.strokesSaving) return;
        this.setState({ strokesSaving: true });
        var today = new Date();
        today.setHours(today.getHours() + 1);

        const cookieRetrieve = authService.getToken();
        let user = authService.getUser()
        const userID = user ? user.userID : 0;
        
        AnalyticsService.addEvent("Strokes Gained Putting", "Stats-Interaction", "Add Stroke");
        fetch("api/StrokesGained/AddStrokesGained", {
            method: "post",
            headers: {
                Accept: "application/json; charset=utf-8",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${cookieRetrieve}`,
            },
            body: JSON.stringify({
                strokesGainedScore: this.state.strokesGained,
                isPractice: this.state.practice,
                userID: userID,
                dateUpdated: today,
            }),
        })
            .then((res) => res.json())
            .catch((error) => {
                this.setState({ strokesSaving: false });
                console.error("Error:", error)
            })
            .then((response) => {
                store.addNotification({
                    message: "Changes have been saved",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 1000,
                    },
                });


                setTimeout(() => {
                    this.setState({ strokesSaving: false });
                }, 1500);
            })
    };



    componentDidMount() {
        window.scrollTo(0, 0)
    }


    render() {
        var prodistances = [
            1.01,
            1.025,
            1.04,
            1.13,
            1.23,
            1.34,
            1.42,
            1.5,
            1.56,
            1.61,
            1.644,
            1.678,
            1.712,
            1.746,
            1.78,
            1.798,
            1.816,
            1.834,
            1.852,
            1.87,
            1.882,
            1.894,
            1.906,
            1.918,
            1.93,
            1.94,
            1.95,
            1.96,
            1.97,
            1.98,
            1.988,
            1.996,
            2.004,
            2.012,
            2.02,
            2.028,
            2.036,
            2.044,
            2.052,
            2.06,
            2.068,
            2.076,
            2.084,
            2.092,
            2.1,
            2.108,
            2.116,
            2.124,
            2.132,
            2.14,
            2.146,
            2.152,
            2.158,
            2.164,
            2.17,
            2.178,
            2.186,
            2.194,
            2.202,
            2.21,
            2.217,
            2.24,
            2.231,
            2.238,
            2.245,
            2.252,
            2.259,
            2.266,
            2.273,
            2.28,
            2.286,
            2.292,
            2.298,
            2.304,
            2.31,
            2.316,
            2.322,
            2.328,
            2.334,
            2.34,
            2.346,
            2.352,
            2.358,
            2.364,
            2.37,
            2.376,
            2.382,
            2.388,
            2.394,
            2.4,
        ];

        var dist1 = parseInt(this.state.puttdistance1);
        var putts1 = parseFloat(this.state.putts1);
        var num = parseFloat(prodistances[dist1 - 1]);
        if (isNaN(num)) {
            num = 0;
        }
        const numero1 = num;
        const hol1Total = num - putts1;

        var dist2 = parseInt(this.state.puttdistance2);
        var putts2 = parseFloat(this.state.putts2);
        var num2 = parseFloat(prodistances[dist2 - 1]);
        if (isNaN(num2)) {
            num2 = 0;
        }
        const numero2 = num2;
        const hol2Total = numero2 - putts2;

        var dist3 = parseInt(this.state.puttdistance3);
        var putts3 = parseFloat(this.state.putts3);
        var num3 = parseFloat(prodistances[dist3 - 1]);
        if (isNaN(num3)) {
            num3 = 0;
        }
        const numero3 = num3;
        const hol3Total = numero3 - putts3;

        var dist4 = parseInt(this.state.puttdistance4);
        var putts4 = parseFloat(this.state.putts4);
        var num4 = parseFloat(prodistances[dist4 - 1]);
        if (isNaN(num4)) {
            num4 = 0;
        }
        const numero4 = num4;
        const hol4Total = numero4 - putts4;

        var dist5 = parseInt(this.state.puttdistance5);
        var putts5 = parseFloat(this.state.putts5);
        var num5 = parseFloat(prodistances[dist5 - 1]);
        if (isNaN(num5)) {
            num5 = 0;
        }
        const numero5 = num5;
        const hol5Total = numero5 - putts5;

        var dist6 = parseInt(this.state.puttdistance6);
        var putts6 = parseFloat(this.state.putts6);
        var num6 = parseFloat(prodistances[dist6 - 1]);
        if (isNaN(num6)) {
            num6 = 0;
        }
        const numero6 = num6;
        const hol6Total = numero6 - putts6;


        var dist7 = parseInt(this.state.puttdistance7);
        var putts7 = parseFloat(this.state.putts7);
        var num7 = parseFloat(prodistances[dist7 - 1]);
        if (isNaN(num7)) {
            num7 = 0;
        }
        const numero7 = num7;
        const hol7Total = numero7 - putts7;


        var dist8 = parseInt(this.state.puttdistance8);
        var putts8 = parseFloat(this.state.putts8);
        var num8 = parseFloat(prodistances[dist8 - 1]);
        if (isNaN(num8)) {
            num8 = 0;
        }
        const numero8 = num8;
        const hol8Total = numero8 - putts8;

        var dist9 = parseInt(this.state.puttdistance9);
        var putts9 = parseFloat(this.state.putts9);
        var num9 = parseFloat(prodistances[dist9 - 1]);
        if (isNaN(num9)) {
            num9 = 0;
        }
        const numero9 = num9;
        const hol9Total = numero9 - putts9;

        var dist10 = parseInt(this.state.puttdistance10);
        var putts10 = parseFloat(this.state.putts10);
        var num10 = parseFloat(prodistances[dist10 - 1]);
        if (isNaN(num10)) {
            num10 = 0;
        }
        const numero10 = num10;
        const hol10Total = numero10 - putts10;

        var dist11 = parseInt(this.state.puttdistance11);
        var putts11 = parseFloat(this.state.putts11);
        var num11 = parseFloat(prodistances[dist11 - 1]);
        if (isNaN(num11)) {
            num11 = 0;
        }
        const numero11 = num11;
        const hol11Total = numero11 - putts11;

        var dist12 = parseInt(this.state.puttdistance12);
        var putts12 = parseFloat(this.state.putts12);
        var num12 = parseFloat(prodistances[dist12 - 1]);
        if (isNaN(num12)) {
            num12 = 0;
        }
        const numero12 = num12;
        const hol12Total = numero12 - putts12;

        var dist13 = parseInt(this.state.puttdistance13);
        var putts13 = parseFloat(this.state.putts13);
        var num13 = parseFloat(prodistances[dist13 - 1]);
        if (isNaN(num13)) {
            num13 = 0;
        }
        const numero13 = num13;
        const hol13Total = numero13 - putts13;

        var dist14 = parseInt(this.state.puttdistance14);
        var putts14 = parseFloat(this.state.putts14);
        var num14 = parseFloat(prodistances[dist14 - 1]);
        if (isNaN(num14)) {
            num14 = 0;
        }
        const numero14 = num14;
        const hol14Total = numero14 - putts14;

        var dist15 = parseInt(this.state.puttdistance15);
        var putts15 = parseFloat(this.state.putts15);
        var num15 = parseFloat(prodistances[dist15 - 1]);
        if (isNaN(num15)) {
            num15 = 0;
        }
        const numero15 = num15;
        const hol15Total = numero15 - putts15;

        var dist16 = parseInt(this.state.puttdistance16);
        var putts16 = parseFloat(this.state.putts16);
        var num16 = parseFloat(prodistances[dist16 - 1]);
        if (isNaN(num16)) {
            num16 = 0;
        }
        const numero16 = num16;
        const hol16Total = numero16 - putts16;

        var dist17 = parseInt(this.state.puttdistance17);
        var putts17 = parseFloat(this.state.putts17);
        var num17 = parseFloat(prodistances[dist17 - 1]);
        if (isNaN(num17)) {
            num17 = 0;
        }
        const numero17 = num17;
        const hol17Total = numero17 - putts17;

        var dist18 = parseInt(this.state.puttdistance18);
        var putts18 = parseFloat(this.state.putts18);
        var num18 = parseFloat(prodistances[dist18 - 1]);
        if (isNaN(num18)) {
            num18 = 0;
        }
        const numero18 = num18;
        const hol18Total = numero18 - putts18;

        const TotalStrokes = putts1 + putts2 + putts3 + putts4 + putts5 + putts6 + putts7 + putts8 + putts9 + putts10 + putts11 + putts12 + putts13 + putts14 + putts15 + putts16 + putts17 + putts18;
        const TotalStrokesGained = hol1Total + hol2Total + hol3Total + hol4Total + hol5Total + hol6Total + hol7Total + hol8Total + hol9Total + hol10Total + hol11Total + hol12Total + hol13Total + hol14Total + hol15Total + hol16Total + hol17Total + hol18Total;
        const TotalStrokesExpected = numero1 + numero2 + numero3 + numero4 + numero5 + numero6 + numero7 + numero8 + numero9 + numero10 + numero11 + numero12 + numero13 + numero14 + numero15 + numero16 + numero17 + numero18;
        this.state.strokesGained = TotalStrokesGained;

        const introvideo = {url: "https://pro-fitpt-1.wistia.com/medias/guu0pgzs7r"};
        
        return (

            <form>
                <div>
                    <div className="container p-3">
                        <div className="text-left">
                            <h4 class="squashie">Strokes Gained Calculator</h4>
                        </div>
                        <hr></hr>

                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12">
                                <Container className="strokes-gained-intro">
                                    <VideoResource {...introvideo} ></VideoResource>
                                </Container>
                            </div>
                        </div>
                        <div className="row strokes-gained-buttons">
                            <div className="col-md-12 col-12 col-sm-12">                        
                                <ButtonGroup toggle>
                                    <ToggleButton
                                        key={0}
                                        type="radio"
                                        variant="primary"
                                        name="radio"
                                        value={0}
                                        checked={!this.state.practice}
                                        onChange={(e) => this.setState({practice: false})}
                                    >
                                        Golf Course
                                    </ToggleButton>
                                    <ToggleButton
                                        key={1}
                                        type="radio"
                                        variant="primary"
                                        name="radio"
                                        value={1}
                                        checked={this.state.practice}
                                        onChange={(e) => this.setState({ practice: true })}
                                    >
                                        Practice
                                    </ToggleButton>
                                </ButtonGroup>
                             </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12 col-xl-6">
                                <table className="table table-resposive">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="glyphicon glyphicon-flag"></span>
                                            </th>
                                            <th style={{ width: "20%" }}>Putt distance</th>
                                            <th>No. of Putts</th>
                                            <th className="results-table">Pro Avg Putt</th>
                                            <th className="results-table">Strokes Gained</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="results-text">1</td>
                                            <td>
                                                <input
                                                    name="puttdistance1"
                                                    type="text"
                                                    value={this.state.puttdistance1}
                                                    maxLength="2"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    id="ContentPlaceHolder1_distance_1"
                                                    class="form-control form-control-sm"
                                                    style={{ width: "50px" }}
                                                />
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts1"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_1"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">[Putts]</option>
                                                            <option value="0">0</option>
                                                            <option value="1">
                                                                1
                              </option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_1_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {numero1 ? numero1 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_1_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {hol1Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">2</td>
                                            <td>
                                                <input
                                                    name="puttdistance2"
                                                    type="text"
                                                    value={this.state.puttdistance2}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts2"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero2 ? numero2 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol2Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">3</td>
                                            <td>
                                                <input
                                                    name="puttdistance3"
                                                    type="text"
                                                    value={this.state.puttdistance3}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts3"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero3 ? numero3 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol3Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">4</td>
                                            <td>
                                                <input
                                                    name="puttdistance4"
                                                    type="text"
                                                    value={this.state.puttdistance4}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts4"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero4 ? numero4 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol4Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">5</td>
                                            <td>
                                                <input
                                                    name="puttdistance5"
                                                    type="text"
                                                    value={this.state.puttdistance5}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts5"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero5 ? numero5 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol5Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">6</td>
                                            <td>
                                                <input
                                                    name="puttdistance6"
                                                    type="text"
                                                    value={this.state.puttdistance6}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts6"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero6 ? numero6 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol6Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">7</td>
                                            <td>
                                                <input
                                                    name="puttdistance7"
                                                    type="text"
                                                    value={this.state.puttdistance7}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts7"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero7 ? numero7 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol7Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">8</td>
                                            <td>
                                                <input
                                                    name="puttdistance8"
                                                    type="text"
                                                    value={this.state.puttdistance8}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts8"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero8 ? numero8 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol8Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">9</td>
                                            <td>
                                                <input
                                                    name="puttdistance9"
                                                    type="text"
                                                    value={this.state.puttdistance9}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts9"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero9 ? numero9 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol9Total.toFixed(2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-md-12 col-12 col-sm-12 col-xl-6">
                                <table class="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span class="glyphicon glyphicon-flag"></span>
                                            </th>
                                            <th>Putt distance</th>
                                            <th>No. of Putts</th>
                                            <th class="results-table">Pro Avg Putts</th>
                                            <th class="results-table">Strokes Gained</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr></tr>

                                        <tr>
                                            <td class="results-text">10</td>
                                            <td>
                                                <input
                                                    name="puttdistance10"
                                                    type="text"
                                                    value={this.state.puttdistance10}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts10"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero10 ? numero10 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol10Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">11</td>
                                            <td>
                                                <input
                                                    name="puttdistance11"
                                                    type="text"
                                                    value={this.state.puttdistance11}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts11"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero11 ? numero11 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol11Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">12</td>
                                            <td>
                                                <input
                                                    name="puttdistance12"
                                                    type="text"
                                                    value={this.state.puttdistance12}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts12"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero12 ? numero12 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol12Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">13</td>
                                            <td>
                                                <input
                                                    name="puttdistance13"
                                                    type="text"
                                                    value={this.state.puttdistance13}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts13"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero13 ? numero13 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol13Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">14</td>
                                            <td>
                                                <input
                                                    name="puttdistance14"
                                                    type="text"
                                                    value={this.state.puttdistance14}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts14"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero14 ? numero14 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol14Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">15</td>
                                            <td>
                                                <input
                                                    name="puttdistance15"
                                                    type="text"
                                                    value={this.state.puttdistance15}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts15"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero15 ? numero15 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol15Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">16</td>
                                            <td>
                                                <input
                                                    name="puttdistance16"
                                                    type="text"
                                                    value={this.state.puttdistance16}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts16"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero16 ? numero16 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol16Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">17</td>
                                            <td>
                                                <input
                                                    name="puttdistance17"
                                                    type="text"
                                                    value={this.state.puttdistance17}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts17"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero17 ? numero17 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol17Total.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="results-text">18</td>
                                            <td>
                                                <input
                                                    name="puttdistance18"
                                                    type="text"
                                                    value={this.state.puttdistance18}
                                                    maxLength="2"
                                                    id="ContentPlaceHolder1_distance_2"
                                                    class="form-control form-control-sm"
                                                    onChange={e => this.handleChangehole1(e, "number")}
                                                    style={{ width: "50px" }}
                                                />{" "}
                                            </td>
                                            <td>
                                                <div class="input-group stroke-button-group">
                                                    <span class="input-group-btn">
                                                        <select
                                                            name="putts18"
                                                            onChange={e => this.handleChangehole1(e, "select")}
                                                            id="ContentPlaceHolder1_DDL_2"
                                                            class="form-control form-control-sm"
                                                        >
                                                            <option selected="selected" value="-1">
                                                                [Putts]
                              </option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                id="ContentPlaceHolder1_hole_2_pro_strokes"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {numero18 ? numero18 : 0}
                                            </td>

                                            <td
                                                id="ContentPlaceHolder1_hole_2_strokes_gained"
                                                class="results-text results-table"
                                            >
                                                {" "}
                                                {hol18Total.toFixed(2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-sm-offset-1 col-sm-10 results-table big-results">
                            <div class="panel panel-primary">
                                <table class="table table2">
                                    <tr>
                                        <th>Total Strokes</th>
                                        <td id="overall_strokes">
                                            <span id="ContentPlaceHolder1_TotalStrokes">
                                                {TotalStrokes}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total Expected Strokes</th>
                                        <td id="overall_pro_strokes">
                                            <span id="ContentPlaceHolder1_TotalExpectedStrokes">
                                                {TotalStrokesExpected.toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total Strokes Gained</th>
                                        <td id="overall_strokes_gained">
                                            <span id="ContentPlaceHolder1_TotalStrokesGained">
                                                {TotalStrokesGained.toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <Form onSubmit={this.handleSubmit}>
                            <div className="form-inline">
                                <div className="form-group">
                                    <button
                                      disabled={this.state.strokesSaving}
                                        onChange={this.handleClick}
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </form>
        );
    }
}

export default StrokesGained;
