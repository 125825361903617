import React from "react";
import UserImage from '../../components/user-image/user-image.component';
import { Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import {useFromNow} from "../../helpers/datetime-helpers";

export default props => {
   return (
      <Row id={"notification-" + props.targetId} className={"notification-item" + (props.read ? " read" : "")} ref={props.focusRef} onClick={() => props.onClick(props.targetId, props.url)}>
         <Col lg={3} md={1} sm={1} xs={3} className="notification-referrer-icon">
            <UserImage avatarId={props.user ? props.user.avatarId : null} className="img-sm rounded-circle" />
         </Col>
         <Col lg={9} md={10} sm={9} xs={9}>
            <Row className="notification-text">{props.comment}</Row>
            <Row className="notification-date">
               {useFromNow(props.createdDate) &&
                  <span>posted:  <Moment fromNow>{props.createdDate}</Moment></span>
               }
               {!useFromNow(props.createdDate) &&
                  <Moment date={props.createdDate} format="YYYY-MM-DD HH:mm" />
               }
            </Row>
         </Col>
      </Row>
   )
}