import React, {useEffect} from "react";
import "react-notifications-component/dist/theme.css";
import ReactPlayer from "react-player";
import DownloadButton from '../../components/downloadButton';

function ResourcesPuttranome() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="container p-3">
          <div className="text-left">
            <h4 className="squashie">Puttranome</h4>
          </div>
          <hr></hr>

          <div className="blog-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-8 blog-left">

                  <div className="row">
                    <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                      <div className="col-md-12" align="center"><h3>Introduction to using puttranome</h3></div>
                      <div className="player-wrapper">
                        <ReactPlayer
                          className="react-player"
                          controls={true}
                          width="100%"
                          height="100%"
                          url='https://pro-fitpt-1.wistia.com/medias/2et3iq8ao1'
                          playing={false}
                        />
                      </div>
                    </div>


                  </div>
                </div>

                <div className="col-md-4 sidebar2 pull-right">
                  <div className="widget">
                    <h4 className="mt-5">
                      Audio File Downloads
                    </h4>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_129bpm.mp3')}
                                    name="Putt 129bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_120bpm.mp3')}
                                    name="Putt 120bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_113bpm.mp3')}
                                    name="Putt 113bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_106bpm.mp3')}
                                    name="Putt 106bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_100bpm.mp3')}
                                    name="Putt 100bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_95bpm.mp3')}
                                    name="Putt 95bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_90bpm.mp3')}
                                    name="Putt 90bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_86bpm.mp3')}
                                    name="Putt 86bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_82bpm.mp3')}
                                    name="Putt 82bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_78bpm.mp3')}
                                    name="Putt 78bpm audio"/>

                    <DownloadButton file={require('../../assets/downloads/swing/Putt_75bpm.mp3')}
                                    name="Putt 75bpm audio"/>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourcesPuttranome;
