import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { mapBlocks } from "./helpers/cms-block-helper";

export default class CmsTabPage extends Component { 
  constructor(){
    super();
    
    this.state = {
      currentVideo: undefined
    }
  }
  
  componentDidMount(){
    let params = new URLSearchParams(this.props.location.search);
    let videoId = params.get("videoId");

    if(videoId && videoId !== this.state.currentVideo){
      this.changeVideo(videoId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.videoId !== this.props.videoId) {
      if (this.props.videoId) {
        this.changeVideo(this.props.videoId);
      }
    }
  }
  
  changeVideo(videoId) {
    if (videoId && this.state.currentVideo === videoId) {
      return;
    }

    this.setState({ currentVideo: videoId }, () => this.props.onChangeVideo(videoId));
  }

  render() {
    return (
      <Container className={"cms-page cms-page-" + this.props.pageTag.replace("/", "_")}>
        {this.props.title &&
          <Row className="title-row">
            <Col md={12}>
              <h2 className="cms-page-title h1 txt--upper txt--spacing rich-text__heading--background"><span>{this.props.title}</span></h2>
            </Col>
          </Row>
        }
          <Row className="cms-page-body">
            <Col md={12}>
               {this.renderBlocks()}
            </Col>
          </Row>
      </Container>
    );
  }

  renderBlocks(){
     let blocks = this.props.content;
     return mapBlocks(blocks, this, this.props.mapper);
  }
}
