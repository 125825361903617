import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import RichTextBlock from './rich-text.block.component';
import RichTextList from '../../components/rich-text/rich-text-list.component';

import "../../App.css";

export default class CmsRichTextListBlock extends Component {
   constructor(){
      super();
      this.state = {
         item: undefined
      }
   }
   
   componentDidMount() {
      window.scrollTo(0, 0)
      
      if (this.props.items) {
         let item = this.props.items[0];
         this.setState({ item });         
      }
   }

   componentDidUpdate(prevProps) {
      if (this.props.items && prevProps.items !== this.props.items) {
         let item = this.state.item ? this.state.item : this.props.items[0];

         if (item && item.id !== this.state.item.id) {
            this.setState({ item });            
         }          
      }
      
      if (prevProps.itemId !== this.props.itemId) {
         this.change(this.props.itemId)
      }
   }

   change(itemId) {
      if (itemId === this.state.item.id) {
         return;
      }

      const item = this.props.items.find(x => x.id === itemId);
      if (item) {
         this.setState({ item: item ? item : {} });          
      } 
   }

   render(){
      return(
         <Row className="rich-text-list">
            <Col md={8}>
               {this.state.item && <RichTextBlock {...this.state.item}></RichTextBlock>}
            </Col>
            <Col md={4}>
               {this.state.item && <RichTextList items={this.props.items} activeItem={this.state.item.id} onChange={vId => this.change(vId)}></RichTextList>}
            </Col>
         </Row>   
      )
   }
}