import React, { Component } from "react";
import { Container, Modal, Button, Row, Col } from "react-bootstrap";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { videoActions } from "../../../store/video.store";

import CommentsSection from '../../../components/comments/comments-section.component';
import CommentInput from '../../../components/comments/comment.input.component';

import "../video-lessons.css";

class VideoComments extends Component {
  constructor(){
    super();
    this.state = {
       showWarning: false,
       reference: undefined
    }
  }
   
   componentDidUpdate(prevProps) {
      if (this.props.reference && this.props.reference !== prevProps.reference) {
         this.setState({reference: this.props.reference}, () => this.props.requestComments(this.state.reference));
      }
   }

   saveComment(comment) {
    let referralUrl = window.location.pathname + `?tabId=${this.props.tabId}&videoId=${this.state.reference}`;
    let commentId = this.state.currentComment ? this.state.currentComment.commentId : undefined;
    let newComment = { videoGuid: this.state.reference, commentText: comment, parentId: commentId, referralUrl: referralUrl };
    this.props.submitComment(newComment);
  }

  deleteComment(){
    this.props.deleteComment(this.props.reference, this.state.commentId);
    this.setState({showWarning: false});
  }

   render(){
      return (
         <Container className="content-comments">
            <Row>
               <Col md={8} className="video-comments-container">
                  <Row className="video-comments">
                     <CommentsSection comments={this.props.comments}
                        sort="desc"
                        canDelete={this.props.user.roles.includes("Administrator") || this.props.user.roles.includes("VideoNotify")} 
                        onDelete={commentId => {
                           this.setState({commentId, showWarning: true})
                           }}
                        onCommentSelect={comment => this.setState({currentComment: comment})}
                        onCommentUnselect={() => this.setState({currentComment: undefined})}
                     ></CommentsSection>
                  </Row>
                  <CommentInput title="Video Comments" label={this.props.label} avatarId={this.props.user.avatarId} onSave={(comment) => this.saveComment(comment)}></CommentInput>
               </Col>
            </Row>
            <Modal className="warning-dialog" show={this.state.showWarning}>
               <Modal.Body>
                  <div>
                  Are you sure you wish to delete this comment.
                  </div>
                  <Button variant="secondary" onClick={() => this.setState({showWarning: false})}>
                     Cancel
                  </Button>
                  <Button variant="primary" onClick={() => this.deleteComment()}>
                     Continue
                  </Button>
               </Modal.Body>
            </Modal>
         </Container>
      )
   }
}

export default connect(
  state => state.videos,
  dispatch => bindActionCreators(videoActions, dispatch)
)(VideoComments);