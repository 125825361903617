import apiService from '../services/api-service';

const requestFolderType = "FETCH_FOLDER_PENDING";
const receiveFolderType = "FETCH_FOLDER_SUCCESS";
const errorFolderType = "FETCH_ERROR_ERROR";

const initialState = {
   isLoading: false,
   folder: {
      folderName: "",
      path: "/",
      folders: [],
      files: []
   },
   errorData: undefined
};

export const actions = {
    requestDropboxFolder: (path) => (dispatch, getState) => fetchLeaderboard(path, dispatch, getState().resources)
};

function fetchLeaderboard(path, dispatch, state) {
  if(state.isLoading === true)
  {
    return;
  }

   dispatch({ type: requestFolderType });
   
   var encoded = encodeURI(path);

  let url = `api/resources/dropbox-folder?path=${encoded}`;
  apiService.get(url, true)
  .then(resp => {
    return resp.json();
  })
    .then(
      (result) => {
        dispatch({type: receiveFolderType, folder: result});
      },
      (data) => {
        dispatch({ type: errorFolderType, errorData: data });
      }
    );
}

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestFolderType) {
    return { ...state, isLoading: true, errorData: null };
  }

  if (action.type === receiveFolderType) {
    return { ...state, isLoading: false, folder: action.folder };
  }

  if (action.type === errorFolderType) {
    return { ...state, isLoading: false, errorData : action.errorData };
  }

  return state;
};
