import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Unauthorized from "../Unauthorized";
import NotFound from "../NotFound";
import Error from "../Error";

const DefaultRouting = (props) => {
   return (
      <Switch>
         <Route path="/unauthorized" component={Unauthorized}></Route>
         <Route path="/not-found" component={NotFound}></Route>
         <Route path="/error" component={Error}></Route>
         <Route path="/" render={routeProps => <Redirect to={getRedirect(routeProps, props.default)} />} />
      </Switch>
   );
}

function getRedirect(route, defaultRoute) {
   let location= route.location;
   let params = new URLSearchParams(location.search);
   let redirect = params.get("redirect");
   
   return redirect ? decodeURI(redirect) : defaultRoute;   
}

export default DefaultRouting;