import React, {Component} from "react";
import { Image } from 'react-bootstrap';

import ReactPlayer from "react-player";

import "./drop-zone.css";

export default class DropZone extends Component {
  constructor(){
    super();    

    this.state = {
      contentSrc: undefined,
      file: undefined,
      playing: false,
      inputRef: React.createRef()
    };
  }

  componentDidUpdate(prevProps){
   if(prevProps.playing !== this.state.playing){
      this.setState({playing: this.props.playing});
   }
  }

  handleDragOver(e){
    e.preventDefault();
    e.stopPropagation();
  }

  handleClick(e){
    e.stopPropagation();

    this.state.inputRef.current.click();
  }

  handleFileDrop(e){
    e.preventDefault();
    e.stopPropagation();

    let files = [...e.dataTransfer.files];
    this.handleFiles(files);
  }

  handleFiles(files){
   if(!files || files.length === 0){
     return;
   }

   let file = files[0];
   if(!file.type.startsWith(this.props.contentType)){
      this.props.onError(`Content not of type: ${this.props.contentType}`);
      return;
   }

   let contentType;

   if(file.type.startsWith("image")){
     contentType = "image";
   } else if(file.type.startsWith("video")){
     contentType = "video";
   }

   var reader = new FileReader();
   reader.addEventListener("load", () => {
     this.setState({ contentType, file, contentSrc: reader.result }, () => this.props.onDrop(file));
   }, false);

   reader.readAsDataURL(file);
  }

  render() {
    return (
      <>
      <div className="drop-zone"
         onDragOver={e => this.handleDragOver(e)}
         onDrop={e => this.handleFileDrop(e)}
         onClick={e => this.handleClick(e)}
      >
         <p className="drop-zone-placeholder"></p>
         <div className="drop-zone-content">
            {(this.props.contentType === "image" && this.props.asset) &&                
               <Image className="drop-zone-image" src={this.state.contentSrc} alt={this.props.asset.filename} />
            }
            {(this.props.contentType === "video"  && this.props.asset) &&   
               <ReactPlayer
                  className="drop-zone-video"
                  controls={true}
                  url={this.state.contentSrc}
                  playing={this.state.playing}
                  onPlay={() => this.onPlay()}
                  onPause={() => this.setState({ playing: false })}
               />
            }
         </div>
         {this.state.file &&            
            <div>{this.state.file.filename}</div>
         }
      </div>
      <input ref={this.state.inputRef} type="file" 
         accept={this.props.contentType + "/*"} 
         className="drop-zone-file-input"
         onChange={e => this.handleFiles(e.target.files)}
      ></input>
      </>
    );
  }
}