import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
 
import { mapBlock } from "./twelve-week.mapper";
import CmsPage from "../../cms/page.component";

const TwelveWeekRouting = props => (
   <Switch>      
      <Route exact path="/12-Week-Programme"
         component={() => <CmsPage pageTag="twelve-week" api="twelve-week" location={props.location} mapper={mapBlock} ></CmsPage>}>         
      </Route>
      <Route exact path="/12-Week-Programme/Main"
         component={() => <CmsPage pageTag="twelve-week/main" api="twelve-week" location={props.location} mapper={mapBlock} ></CmsPage>}>         
      </Route>
   </Switch>
);

export default TwelveWeekRouting;