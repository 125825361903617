import React, { Component } from "react";
import LineGraph from "../../../../components/graph/line.graph.component"
import GraphSeries from "../../../../components/graph/graph-series.component";
import { Form, Button, Row } from "react-bootstrap";
import DataPoint from "../../../../components/graph/data-point";
import {LegendContainer, Legend} from "../../../../components/graph/legend.component";
import DrillInput from "./drill.input.component";
import DrillMedian from "./drill.median.component";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../../store/performance-stats.store";

import { calculateMedian } from "../../../../shared/Utils";
import AnalyticsService from '../../../../services/analytics-service';

import moment from 'moment';

class AltDrillGraph extends Component {
  constructor(props){
    super();

     this.state = {
      showMedians: true,
      data: {},
      labels: [],
      drills: {
         total: 0,
         drill5: [],
         drill10: [],
         drill15: []
      },
      medians: {
         total: 0,
         drill5: [],
         drill10: [],
         drill15: []
      },
      refs: {
         Med5: React.createRef(),
         Med10: React.createRef(),
         Med15: React.createRef()
       },
       handicapRange: {
          minRange: props.handicap,
          maxRange: props.handicap
       },
        optionValue: {},
        optionValues: [
           {title: "Best on tour", value: { five: 16, ten: 8, fifteen: 4 }},
           {title: "Average on tour", value: { five: 15, ten: 7, fifteen: 3 }},
           {title: "Worst on tour", value: { five: 14, ten: 6, fifteen: 1 }}
        ]
    };
  }

  componentDidMount(){
     this.props.requestDrills(this.props.handicap, this.props.handicap, "medians");
  }

  componentDidUpdate(prevProps){
     if(prevProps.medians.series !== this.props.medians.series){
        this.setSeries(this.state.optionValue);
     }
  }

   handleChange(e){
      let data = this.state.data;
      data[e.target.name] = e.target.value;
      this.setState({data : data});
   }
      
   handleOptionChange(value){
      this.setState({optionValue : value}, () => this.setSeries(value));      
   }
   
   handleMedianChange(minHandicap, maxHandicap) {
      if (this.state.handicapRange.minRange === minHandicap && this.state.handicapRange.maxRange === maxHandicap) {
         return;
      }

      let handicapRange = {
          minRange: minHandicap,
          maxRange: maxHandicap
      }
      this.setState({ handicapRange }, () => this.props.requestDrills(minHandicap, maxHandicap, "medians"));
   }

  handleSubmit(){
   let drills = [];
   let currDate = moment().toISOString();

   this.addDrill(currDate, "Med5", drills);
   this.addDrill(currDate, "Med10", drills);
   this.addDrill(currDate, "Med15", drills);
   
   AnalyticsService.addEvent("Broadie Average", "Stats-Interaction", "Add Drills");

   this.setState({ data: {} }, () => this.props.addDrills(drills, "medians"));
  }

  addDrill(currDate, drill, drills){
      if(this.state.data[drill]){
         drills.push({ drillType: drill, dateUpdated: currDate, score: this.state.data[drill] });
         
         let refs = this.state.refs;
         refs[drill].current.value = "";
         this.setState({ refs });
      }
  }

  removeDrills(){
     var maxDrillGroup = Math.max.apply(null, this.props.medians.series.map(x => x.drillGroup));

     AnalyticsService.addEvent("Broadie Average", "Stats-Interaction", "Remove Drills");
     this.props.removeDrills(maxDrillGroup, "medians");
   }
   
   renderMedians() {
      if (!this.state.showMedians) {
         return [];
      }
      return [
         <GraphSeries title="5ft-median" key="5" className="drillgraph-5ft-median" color="pink" labels={this.state.labels} datapoints={this.state.medians.drill5}></GraphSeries>,
         <GraphSeries title="10ft-median" key="10" className="drillgraph-10ft-median" color="light-blue" labels={this.state.labels} datapoints={this.state.medians.drill10}></GraphSeries>,
         <GraphSeries title="15ft-median" key="15" className="drillgraph-15ft-median" color="light-green" labels={this.state.labels} datapoints={this.state.medians.drill15}></GraphSeries>
      ];
   }
   
   renderMedianLegends() {
      if (!this.state.showMedians) {
         return [];
      }

      return [
         <Legend title="5ft(Avg)" key="5" color="pink" value={calculateMedian(this.state.medians.drill5.map(x => x.value))}></Legend>,
         <Legend title="10ft(Avg)" key="10" color="light-blue" value={calculateMedian(this.state.medians.drill10.map(x => x.value))}></Legend>,
         <Legend title="15ft(Avg)" key="15" color="light-green" value={calculateMedian(this.state.medians.drill15.map(x => x.value))}></Legend>
      ];
   }

  render() {
    return (
       <LineGraph title="5,10,15 ft Broadie Drill Average Scoring" className="drillgraph" interpolate="true" labels={this.state.labels}>
          {this.renderMedians()}
          <GraphSeries title="5ft" className="drillgraph-5ft" color="red" labels={this.state.labels} datapoints={this.state.drills.drill5}></GraphSeries>
          <GraphSeries title="10ft" className="drillgraph-10ft" color="blue" labels={this.state.labels} datapoints={this.state.drills.drill10}></GraphSeries>
          <GraphSeries title="15ft" className="drillgraph-15ft" color="green" labels={this.state.labels} datapoints={this.state.drills.drill15}></GraphSeries>
          <DrillMedian
             id="alt-drill-graph"
             handicap={this.props.handicap}
             active={this.state.showMedians}
             options={this.state.optionValues}
             onOptionChange={(value) => this.handleOptionChange(value)}
             onHandicapChange={(minRange, maxRange) => this.handleMedianChange(minRange, maxRange)}
             setActive={active => this.setState({showMedians: active})}
          ></DrillMedian>
         <Form onSubmit={this.handleSubmit} className="drillgraph-footer">
             <Row className="form-inline">
                  <DrillInput name="Med5" drill="5ft" onChange={e => this.handleChange(e)} min={-50} inputRef={this.state.refs.Med5}></DrillInput>
                  <DrillInput name="Med10" drill="10ft" onChange={e => this.handleChange(e)} min={-50} inputRef={this.state.refs.Med10}></DrillInput>
                  <DrillInput name="Med15" drill="15ft" onChange={e => this.handleChange(e)} min={-50} inputRef={this.state.refs.Med15}></DrillInput>
                     <button
                     onClick={() => this.handleSubmit()}
                     className="btn btn-primary"
                     type="button"
                     >
                        Save
                     </button>

                     <Button type="button" onClick={() => this.removeDrills()} className="btn btn-primary" disabled={this.state.drills.total === 0}>Undo</Button>
            </Row>
          </Form>
         <LegendContainer>
            <Legend title="5ft" color="red" value={calculateMedian(this.state.drills.drill5.map(x => x.value))}></Legend>
            <Legend title="10ft" color="blue" value={calculateMedian(this.state.drills.drill10.map(x => x.value))}></Legend>
            <Legend title="15ft" color="green" value={calculateMedian(this.state.drills.drill15.map(x => x.value))}></Legend>
            {this.renderMedianLegends()}
         </LegendContainer>
       </LineGraph>
    );
  }

   getLabels() {
      let series = this.props.medians.series
      if(!series)
      {
         return;
      }

      let dates = series.map(x => moment(x.dateUpdated).format("YYYYMMDD HHmmss"));
      let distinct = dates.filter((v,i, self) => self.indexOf(v) === i);
      let sorted  = distinct.sort((a,b) => moment(a) - moment(b));
      let labels = sorted.map(x => {return { display: moment(x).format("DD-MMM-YYYY HH:mm:ss"), value: x };});

      return labels;
  }

   setSeries({five, ten, fifteen}) {
      let series = this.props.medians.series
      if(!series)
      {
         return;
      }

      let drills = { total: series.length };
      drills.drill5 = series.filter(x => x.drillType === "Med5").map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), x.score));
      drills.drill10 = series.filter(x => x.drillType === "Med10").map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), x.score));
      drills.drill15 = series.filter(x => x.drillType === "Med15").map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), x.score));

      let medians = { total: series.length };
      medians.drill5 = series.filter(x => x.drillType === "Med5").map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), five ? five : x.medianScore));
      medians.drill10 = series.filter(x => x.drillType === "Med10").map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), ten ? ten : x.medianScore));      
      medians.drill15 = series.filter(x => x.drillType === "Med15").map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), fifteen ? fifteen : x.medianScore));

      let labels = this.getLabels();

      this.setState({drills, medians, labels});
   }
}

export default connect(
   state => state.performanceStats,
   dispatch => bindActionCreators(actions, dispatch)
 )(AltDrillGraph);