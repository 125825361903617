import React, {useEffect} from "react";
import "react-notifications-component/dist/theme.css";
import ReactPlayer from "react-player";

function PuttingSocialInterviews() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container p-3">
        <div className="text-left">
          <h4 className="squashie">Putting Social Interviews</h4>
        </div>
        <hr></hr>

        <div className="blog-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-8 blog-left">

                <div className="row">
                  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                    <div className="col-md-12" align="center"><h3>Matt Fitzpatrick</h3></div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                        url="https://pro-fitpt-1.wistia.com/medias/lhck9f2xf4"
                        playing={false}
                        onReady={() => {
                          this.setState({ready: true});
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                    <div className="col-md-12" align="center"><h3>Mike Kanski</h3></div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                        url="https://pro-fitpt-1.wistia.com/medias/p1xgo3jjc7"
                        playing={false}
                        onReady={() => {
                          this.setState({ready: true});
                        }}
                      />
                    </div>
                  </div>


                  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                    <div className="col-md-12" align="center"><h3>Thomas Bjorn</h3></div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                        url="https://pro-fitpt-1.wistia.com/medias/zv081kdufk"
                        playing={false}
                        onReady={() => {
                          this.setState({ready: true});
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                    <div className="col-md-12" align="center"><h3>Tommy Fleetwood</h3></div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                        url="https://pro-fitpt-1.wistia.com/medias/lm2vvv0v99"
                        playing={false}
                        onReady={() => {
                          this.setState({ready: true});
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                    <div className="col-md-12" align="center"><h3>Christian Marquardt - Yips</h3></div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                        url="https://pro-fitpt-1.wistia.com/medias/ijkrc2ra04"
                        playing={false}
                        onReady={() => {
                          this.setState({ready: true});
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
                    <div className="col-md-12" align="center"><h3>Ed Molinari</h3></div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                        url="https://pro-fitpt-1.wistia.com/medias/codfcpaznw"
                        playing={false}
                        onReady={() => {
                          this.setState({ready: true});
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 sidebar2 pull-right">

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PuttingSocialInterviews;
