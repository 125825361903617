import React, { Component } from "react";
import { Row } from 'react-bootstrap';
import CmsColumn from './column.block.component';

export default class TabBlock extends Component { 
  constructor(){
    super();
    
    this.state = {activeTab:"home", currentVideo: undefined}
  }

  componentDidMount(){
    this.setState({ activeTab: this.props.activeTab })
    
    let params = new URLSearchParams(this.props.location.search);
    let tabId = params.get("tabId");

    tabId = tabId ? tabId : this.props.activeTab;

    if(tabId && tabId !== this.state.activeTab){
      this.setState({activeTab: tabId});
    }
  }
  
  changeVideo(videoId) {
    if (this.state.currentVideo === videoId) {
      return;
    }

    this.setState({ currentVideo: videoId }, () => this.props.onChangeVideo(videoId));
  }

  renderColumns(){
     let columns = this.props.columns;
     if(columns.length === 0){
        return;
     }

     return columns.map((c,i) => 
       <CmsColumn
         key={i}
         active={this.state.activeTab === c.id}
         location={this.props.location}
         mapper={this.props.mapper}
         videoId={this.state.currentVideo}
         columns={this.props.columns}
         textValues={this.props.textValues}
         onChangeVideo={videoId => this.changeVideo(videoId)}
        {...c}></CmsColumn>
     );
  }

  render() {
    return (
       <Row>
          {this.renderColumns()}
      </Row>
    );
  }
}