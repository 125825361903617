import React, { Component } from "react";
import "../App.css";
import "../css/Dashboard.css";
import Accordion from "./Accordion";
import "./styles.css";

export default class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0
        };
    }

    render() {
        return (
            <div>
                <div className="container p-3">

                    <p style={{ textAlign: 'center', marginTop: '25px'}}>
                        <span style={{
                            color: "white", background: 'black', padding: '10px 30px',fontSize: '25px',fontWeight: 'bold',borderRadius: '5px',boxShadow: '2px 2px 3px #00000061' }}>
                                                FAQ
                        </span>

                    </p>

                    <Accordion
                        className="accordion mt-5"
                        selectedIndex={this.state.selectedIndex}
                        onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
                    >

                        <div style={{ margin: '15px 0' }} data-header="Is this only for low handicap golfers?" className="accordion-item">
                            <p>
                                Not at all.  The program will benefit everyone.  Whether you are a tour pro, play off single figures or are relatively new to the game, you will benefit greatly from the content and it will definitely improve your putting.
            </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="How long is the programme?" className="accordion-item">
                            <p>
                                One of the great things about the program is that the concepts learned will last you a lifetime. You will have exclusive access to the members site, your personal locker room, all videos, the stats trackers and the coaches area for a full 12 months.  You also have access to a personalised 12-week program that you can follow at any time during your membership period that will give you a roadmap to follow to improve your game. This means you can constantly refer to your specific videos, look at new videos, new drills and lessons as well as get the insights of fellow golfers, coaches and experts along the way.
                                  Founder members can also join a guided 12 wk programme upon launch that will involve live webinars to help answer any questions you have to maximise your involvement.
            </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="Why is this program different to anything else?" className="accordion-item">
                            <p>
                                This is only resource of its kind in the world.  The access to my system and the individual approach to your improvement is a unique opportunity.  A fully dedicated program specifically designed to help all golfers improve
                                      their putting has never been done in this way.  It's something I'm very proud of and think it will help you with all aspects of your putting.
            </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="What's the money back guarantee?" className="accordion-item">
                            <p>
           The value of the academy is huge.  I am so confident you will enjoy and really benefit from the system, videos and drills on offer, plus all the member interaction'that we will offer a 14-day money back guarantee.  If you personally email me and show me all the practice drills and tests that you have carried out, your performance and practice journal filled in and implemented, along with any problems you have found the program to cause, then I will personally respond and give you back every penny you have spent.   </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="What equipment do I need?" className="accordion-item">
                            <p>
                                The great thing about the system is that just by sitting and watching the videos you will become a better putter by having more accurate concepts and understanding .  The other great thing is that the only equipment you need is a putter and balls! (obviously)' 'so you really can improve from your home, office or practice putting green.
            </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="I need a 1-1 lesson, are they available?" className="accordion-item">
                            <p>
                                Yes. Many people would benefit from the input and experience of having their own coach to work on their specific areas, plus benefit from the tailored feedback and experience of the academy coaching team. You can apply for your 1-1 session via the '1-1 assessment' button in your members areas or you can email us directly and we will help you with your 1-1 plan.  The great thing about the 1-1 remote coaching we have developed, means we can help people all over the world.
            </p>
                        </div>


                        <div style={{ margin: '15px 0' }} data-header="How much time do I need to commit?" className="accordion-item">
                            <p>
                               Like anything, the more you practice the more you will improve.  However, to see improvements we would recommend getting 3-4 20 minute sessions in each week.  That's a pretty small amount of time if you think about it, compared to rest of the golf swing.  And the gains in scores can be huge.
            </p>
                        </div>


                        <div style={{ margin: '15px 0' }} data-header="I am a Golf Coach, will this benefit me?" className="accordion-item">
                            <p>
                                Yes, 100% it will benefit you as well as golfers.  This will give you insights into the art and science of putting that may help your understanding and therefore your ability to pass the information on to your students, and potentially give you a more valuable service.
            </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="Is there ongoing content?" className="accordion-item">
                            <p>
                                Yes!  You will become part of a community with different videos, insights, articles, member interaction, updates and guest appearances along the way. It's only going to get bigger.
            </p>
                        </div>

                        <div style={{ margin: '15px 0' }} data-header="What happens after the initial 12 week plan?" className="accordion-item">
                            <p>
                                You carry on improvement and going deeper in to the skills, drills and understanding of what makes a great putter.  With over 300 videos and lots of extra content being added, along with the additional features you can keep on learning and of course benefitting from the system during your 12 month long membership.  You will also benefit from any updates to the programme and any additional videos.
            </p>
                        </div>


                        <div style={{ margin: '15px 0' }} data-header="What payment method do you accept?" className="accordion-item">
                            <p>
                                Most major credit and debit cards are accepted.
            </p>
                        </div>


                        <div style={{ margin: '15px 0' }} data-header="Can I pay in instalments?" className="accordion-item">
                            <p>
                                Yes, if you don't want to benefit from the up front discount, you can pay for the academy over 3months at '149 per month.
            </p>
                        </div>



                    </Accordion>

                </div>
            </div>
        );
    }
}
