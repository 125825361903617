import React from "react";

export default (props) => {
   let id = "drill-input-" + props.drill;

   return (
      <div className="drill-input">
         <label htmlFor={id}>{props.drill}</label>
            <input
            id={id}
            ref={props.inputRef}
            type="number"
            onChange={e => props.onChange(e)}
            name={props.name}
            min={props.min ? props.min : 0}
            max={props.max ? props.max : 100}              
            >
         </input>
      </div>
   )
}