import React, { Component } from "react";
import { Container, Row, Button, Tabs, Tab } from 'react-bootstrap';

import Popup from '../popup/popup.component';
import VideoSearchList from './sub-components/video-search-list-component';
import CommunitySearchList from './sub-components/community-search-list-component';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { searchActions } from "../../store/search.store";

import './dashboard-search.css';

class Search extends Component {
  constructor() {
    super();

    this.state = {
      active: true,
      activeTab: "video",
      currentVideo: undefined,
      showList: false,
      currentSearch: undefined,
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.video && this.props.video && !this.state.showList && prevProps.video !== this.props.video){
      this.setState({ activeTab: "video" })
    }
    if(prevProps.community && this.props.community && !this.state.showList && prevProps.community !== this.props.community){
      this.setState({ activeTab: "community" })
    }
    if(prevProps.bonus && this.props.bonus && !this.state.showList && prevProps.bonus !== this.props.bonus){
      this.setState({ activeTab: "bonus" })
    }
  }

  changeVideo(videoId) {
    if (this.state.currentVideo === videoId) {
      return;
    }

    this.setState({ currentVideo: videoId });
  }

  onClose(){
    this.setState({ showList: false })
  }

  search(page, type){
    this.props.search(this.state.currentSearch, type, page);

    if(!this.state.showList){
      this.setState({ showList: true, activeTab: "video" })
    }
  }

  changeTab(tabId, page){
    this.setState({ activeTab: tabId, currentVideo: undefined }, 
      () => this.search(page, tabId));
  }

  render() {
    return (
      <Container className={"dashboard-search"}>
        <Row>
          <span><input type="text" className="dashboard-search_input" placeholder="Search Content" onChange={e => this.setState({ currentSearch: e.target.value })}></input></span>
          <span><Button className="dashboard-search_button" onClick={() => this.search(1, "video", 1)} disabled={!this.state.currentSearch}>Search</Button></span>
        </Row>
        <Popup 
          show={this.state.showList}
          heading={`Search results for '${this.state.currentSearch}'`}
          className="dashboard-search_dialog"
          onClose={() => this.onClose()}
        >
        <Tabs 
           className={"cms-block tab-block dashboard-search_tabs"}
           activeKey={this.state.activeTab}
           onSelect={(tabId) => this.changeTab(tabId, 1)}
         >
            {this.renderVideoTab("video", "Video Library", this.props.video)}
            {this.renderCommunityTab("community", "Community", this.props.community)}
            
            {/* { this.props.user.roles.includes("BonusUser") && this.renderVideoTab("bonus", "Bonus Page", this.props.bonus)} */}
         </Tabs>
        </Popup>
      </Container>
    );
  }

  renderVideoTab(eventKey, title, search) {
    return (
      <Tab  eventKey={eventKey} title={title} tabClassName="none">
        <VideoSearchList
          search={search}          
          active={this.props.active}
          currentSearch={this.state.currentSearch}
          currentVideo={this.state.currentVideo}
          onChange={page => this.search(page, eventKey)}
          onPause={() => this.setState({ currentVideo: undefined })}
          onStart={(videoId) => this.setState({ currentVideo: videoId })}>
        </VideoSearchList>
      </Tab>
    );
  }

  renderCommunityTab(eventKey, title, search){
    return (
      <Tab  eventKey={eventKey} title={title} tabClassName="none">
        <CommunitySearchList
          search={search}
          active={this.props.active}
          currentSearch={this.state.currentSearch}
          currentVideo={this.state.currentVideo}
          onChange={page => this.search(page, eventKey)}
          onPause={() => this.setState({ currentVideo: undefined })}
          onStart={(videoId) => this.setState({ currentVideo: videoId })}
        ></CommunitySearchList>
      </Tab>
    );
  }
}

export default connect(
    state => state.search,
    dispatch => bindActionCreators(searchActions, dispatch)
)(Search);
