import React from 'react';
import {
   Route,
   Switch
} from "react-router-dom";

import { mapBlock } from "./locker-room.mapper";

import { LockerRoom } from "./locker-room-page.component";
import CmsPage from "../../cms/page.component";

const LockerRoomRouting = props => (
   <Switch>
      <Route exact path="/Locker-Room/Locker-Aim"
         component={() => <CmsPage pageTag="locker-room/aim-bias" location={props.location} mapper={mapBlock}></CmsPage>}>         
      </Route>
      <Route exact path="/Locker-Room/Locker-Start-Line"
         component={() => <CmsPage pageTag="locker-room/start-line" location={props.location} mapper={mapBlock}></CmsPage>}>         
      </Route>
      <Route exact path="/Locker-Room/Locker-Speed"
         component={() => <CmsPage pageTag="locker-room/speed" location={props.location} mapper={mapBlock}></CmsPage>}>         
      </Route>
      <Route exact path="/Locker-Room/Locker-Green-Reading"
         component={() => <CmsPage pageTag="locker-room/green-reading" location={props.location} mapper={mapBlock}></CmsPage>}>         
      </Route>
      <Route exact path="/Locker-Room/Locker-Line-On-Ball"
         component={() => <CmsPage pageTag="locker-room/line-on-the-ball" location={props.location} mapper={mapBlock}></CmsPage>}>         
      </Route>
      <Route exact path="/Locker-Room/Locker-Performance-Tests"
         component={() => <CmsPage pageTag="locker-room/performance-tests" location={props.location}></CmsPage>}>         
      </Route>
      <Route exact path="/Locker-Room" component={LockerRoom}></Route>
   </Switch>
);

export default LockerRoomRouting;