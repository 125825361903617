import React from "react";
import authService from '../../services/auth-service';
import CmsVideoListBlock from "../../cms/blocks/video-list.block.component";
  
export function mapBlock(block, index, caller){
   switch(block.blockType){
      case "video-list": return renderVideoList(index, block, caller);
      default: return null;
   }
}

function renderVideoList(index, block, caller) {
   let user = authService.getUser();
   
   let videos = block.videoList.filter(video => {
      let intersection = new Set([...video.groups].filter(x => user.videoGroups.includes(x)));
      return intersection.size > 0;
   });
   block.videoList = videos;

   return <CmsVideoListBlock key={index} onChange={videoId => caller.changeVideo(videoId)} active={caller.props.active}  {...block}></CmsVideoListBlock>
  }