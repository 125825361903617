import React, { Component } from "react";
import { Form, Button, Row } from "react-bootstrap";
import LineGraph from "../../../../components/graph/line.graph.component"
import GraphSeries from "../../../../components/graph/graph-series.component";
import DataPoint from "../../../../components/graph/data-point";

import { LegendContainer, Legend } from "../../../../components/graph/legend.component";
import DrillMedian from "./drill.median.component";
import DrillInput from "./drill.input.component";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../../store/performance-stats.store";

import AnalyticsService from '../../../../services/analytics-service';

import { calculateMedian } from "./../../../../shared/Utils";

import moment from 'moment';

class PercentOfTwentyGraph extends Component {
   constructor(props) {
      super();

      this.state = {
         data: {},
         labels: [],
         drills: [],
         medians: [],
         showMedians: true,
         handicapRange: {
            minRange: props.handicap,
            maxRange: props.handicap
         },
         refs: {
            Per20: React.createRef()
         },
         optionValue: {},
         optionValues: [
            { title: "Top 10 Tour", value: { percentOfTwenty: 75 } },
            { title: "Top 150 Tour", value: { percentOfTwenty: 65 } }
         ]
      };
   }

   componentDidMount() {
      this.props.requestDrills(this.props.handicap, this.props.handicap, "percentOfTwenty");
   }

   componentDidUpdate(prevProps) {
      if (prevProps.percentOfTwenty.series === this.props.percentOfTwenty.series) {
         return;
      }

      this.setSeries(this.state.optionValue);
   }

   handleChange(e){
      let data = this.state.data;
      data[e.target.name] = e.target.value;
      this.setState({data : data});
   }

   handleOptionChange(value) {
      this.setState({ optionValue: value }, () => this.setSeries(value));
   }

   handleMedianChange(minHandicap, maxHandicap) {
      if (this.state.handicapRange.minRange === minHandicap && this.state.handicapRange.maxRange === maxHandicap) {
         return;
      }

      let handicapRange = {
         minRange: minHandicap,
         maxRange: maxHandicap
      }
      this.setState({ handicapRange }, () => this.props.requestDrills(minHandicap, maxHandicap, "percentOfTwenty"));
   }

  handleSubmit(){
   let drills = [];
   let currDate = moment().toISOString();

   this.addDrill(currDate, "Per20", drills);
   
   AnalyticsService.addEvent("4-8ft drill %", "Stats-Interaction", "Add Drill");
   this.setState({ data: {} }, () => this.props.addDrills(drills, "percentOfTwenty"));
  }

  addDrill(currDate, drill, drills){
      if(this.state.data[drill] && this.state.data[drill] > 0){
         drills.push({ drillType: drill, dateUpdated: currDate, score: this.state.data[drill] });
         
         let refs = this.state.refs;
         refs[drill].current.value = "";
         this.setState({ refs });
      }
  }

  removeDrills(){
     var maxDrillGroup = Math.max.apply(null, this.props.percentOfTwenty.series.map(x => x.drillGroup));

     AnalyticsService.addEvent("4-8ft drill %", "Stats-Interaction", "Remove Drill");
     this.props.removeDrills(maxDrillGroup, "percentOfTwenty");
   }

   render() {
      return (
         <LineGraph title="4-8ft drill % conversion" className="percentof20graph" labels={this.state.labels}>
            {this.state.showMedians &&
               <GraphSeries className="drillgraph-per20-median" color="light-green" labels={this.state.labels} datapoints={this.state.medians}></GraphSeries>
            }
            <GraphSeries className="drillgraph-per20" color="green" labels={this.state.labels} datapoints={this.state.drills}></GraphSeries>
            <DrillMedian
               id="per20-graph"
               handicap={this.props.handicap}
               active={this.state.showMedians}
               options={this.state.optionValues}
               onOptionChange={(value) => this.handleOptionChange(value)}
               onHandicapChange={(minRange, maxRange) => this.handleMedianChange(minRange, maxRange)}
               setActive={active => this.setState({ showMedians: active })}
            ></DrillMedian>
            <Form onSubmit={this.handleSubmit} className="drillgraph-footer">
               <Row className="form-inline">
                  <DrillInput name="Per20" drill="Score out of 20" onChange={e => this.handleChange(e)} inputRef={this.state.refs.Per20}></DrillInput>
                     <button
                     onClick={() => this.handleSubmit()}
                     className="btn btn-primary"
                     type="button"
                     >
                        Save
                     </button>

                     <Button type="button" onClick={() => this.removeDrills()} className="btn btn-primary" disabled={this.state.drills.total === 0}>Undo</Button>
               </Row>
            </Form>
            <LegendContainer>
               <Legend title="% of 20" color="green" value={calculateMedian(this.state.drills.map(x => x.value))}></Legend>
               {this.state.showMedians &&
                  <Legend title="% of 20(Avg)" color="light-green" value={calculateMedian(this.state.medians.map(x => x.value))}></Legend>
               }
            </LegendContainer>
         </LineGraph>
      );
   }

   getLabels() {
      let series = this.props.percentOfTwenty.series;
      if (!series) {
         return;
      }

      let dates = series.map(x => moment(x.dateUpdated).format("YYYYMMDD HHmmss"));
      let sorted = dates.sort((a, b) => a - b);
      let labels = sorted.map(x => { return { display: moment(x).format("DD-MMM-YYYY HH:mm:ss"), value: x }; });

      return labels
   }

   setSeries({ percentOfTwenty }) {
      let series = this.props.percentOfTwenty.series;
      if (!series) {
         return;
      }

      let drills = series.map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), calculatePercentOfTwenty(x.score)));
      let medians = series.map(x =>
         new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), percentOfTwenty ? percentOfTwenty : calculatePercentOfTwenty(x.medianScore))
      );
      let labels = this.getLabels();

      this.setState({ drills, medians, labels })
   }
}

export default connect(
   state => state.performanceStats,
   dispatch => bindActionCreators(actions, dispatch)
)(PercentOfTwentyGraph);

function calculatePercentOfTwenty(value) {
   return (value / 20) * 100;
}