import { config } from '../config';
import AuthService from './auth-service'

function gtag() { window.dataLayer.push(arguments); }

function initialize() {
   const gaScript = document.createElement("script");
   gaScript.async = true;
   gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${config.GA_KEY}`;
   document.getElementsByTagName('head')[0].appendChild(gaScript);

   gtag('js', new Date());
   addPageView();
}


function login() {
   gtag("event", "login");
}

function setUser(user) {
   addPageView();

   gtag('set', 'user_properties', {
      roles: user.roles.join(",")
   });
}

function addPageView() {
   let paths = window.location.pathname.split('/');
   let title = paths[paths.length - 1];
   let user = AuthService.getUser();

   let model = {
      page_title: title,
      page_location: window.location.origin,
      page_path: window.location.pathname + window.location.search
   };
   if (user) {
      model.user_id = user.userGuid;
   }

   gtag("config", config.GA_KEY, model);
}

function addEvent(action, category, label) {
   gtag("event", action, {
      event_category: category,
      event_label: label
   });
}

export default {
   initialize,
   login,
   setUser,
   addPageView,
   addEvent
}