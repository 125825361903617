import React, {Component} from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import FromNowComponent from '../../../helpers/from-now-component';
import UserImage from '../../../components/user-image/user-image.component';

import ReactPlayer from "react-player";

export default class CommunityPost extends Component {
  constructor(){
    super();

    this.state = {playing: false};
  }

  componentDidUpdate(prevProps) {
    if(this.props.playing === prevProps.playing)
      return;

    this.setState({playing: this.props.playing});
  }

  onClick(){
    this.setState({playing: false});

    if(!this.props.onClick)
      return;

      this.props.onClick();
  }

  render() {
    return (
      <Container id={"post-item-" + this.props.reference} className="community-post-item">
        <FontAwesomeIcon icon={faExpandArrowsAlt} className="expand-icon" onClick={() => this.onClick()} />
        <div className="community-post-overlay" onClick={() => this.onClick()}></div>
        <Row className="community-post-header">
           <Col sm={2}>
            <UserImage avatarId={this.props.user.avatarId} className="img-md rounded-circle" />
           </Col>
           <Col className="community-post-actor" sm={10}>
             <Row className="item-posted-by">{this.props.user.displayName}</Row>
             <Row className="item-posted-date">
                <FromNowComponent date={this.props.createdDate}></FromNowComponent>
             </Row>
             <hr />
             <Row className="community-post-title">
                 <div>{this.props.content.title}</div>                 
             </Row>
           </Col> 
        </Row>
        <Row className="community-post-content">
           {this.props.content.contentType !== "text" &&   
              <div className="media-content">
                {this.props.content.contentType === "video" &&   
                    <ReactPlayer
                      className="post-content-video"
                      controls={true}
                      url={this.props.content.url}
                      playing={this.state.playing}
                      onPlay={() => this.props.onPlay()}
                      onPause={() => this.setState({ playing: false })}
                    />
                }
                {this.props.content.contentType === "image" &&                
                  <Image className="post-content-image" src={this.props.content.url} alt={this.props.content.description} height="250px" />
                }
              </div>
          }
          <div className="community-post-comment" dangerouslySetInnerHTML={{__html: this.props.content.comment}} ></div>
          <div className="community-post-comment-count">
            <span><i className="fa fa-comments-o" aria-hidden="true"></i> Total comments: {this.props.commentCount}</span>
          </div>
        </Row>
      </Container>
    );
  }
}