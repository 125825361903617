import { dispatchTypes } from './notifications.middleware';

const initialState = {
    notifications: [],
    connected: false
};

export const actionCreators = {
  refreshNotifications: () => async (dispatch, getState) => {
      dispatch({ type: dispatchTypes.refreshNotificationsRequest });        
  },
  
  deleteNotification: update => async (dispatch, getState) => { 
    dispatch({ type: dispatchTypes.updateNotificationsRequest, update });        
  },        
  
  deleteNotifications: updates => async (dispatch, getState) => { 
    dispatch({ type: dispatchTypes.deleteNotificationsRequest, updates });   
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

   if(action.type === dispatchTypes.updateNotificationsResponse) {
      let notifications = state.notifications.filter(x => x.notificationId !== action.data.notificationId);
      notifications.push(action.data);
      
      return { ...state, notifications };
   }

   if(action.type === dispatchTypes.refreshNotificationsResponse) {
      return { ...state, notifications: action.data };
   }

  if (action.type === dispatchTypes.errorNotificationsResponse) {
      return { ...state, error: action.error };
   }

  return state;
};
