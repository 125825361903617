import React from 'react';
import {
   Route,
   Switch
} from "react-router-dom";

import CmsPage from "../../cms/page.component";
import FastTrackPage from "./fast-track.component";

const FastTrackRouting = props => (
   <Switch>
      <Route exact path="/fast-track/green-reading"
         component={() => <CmsPage pageTag={`fast-track/green-reading/introduction`} location={props.location}></CmsPage>}>
      </Route>
      <Route exact path="/fast-track/green-reading/tasks"
         component={() => <FastTrackPage section="green-reading" maxPage={21} location={props.location}></FastTrackPage>}>
      </Route>
   </Switch>
);

export default FastTrackRouting;