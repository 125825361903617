import React, { Component } from "react";
import { Row } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../../store/user.store";

import UserImage from '../../../components/user-image/user-image.component';

class ChangeAvatar extends Component {
   constructor() {
      super();

      this.state = {
         avatar: undefined,
         inputRef: React.createRef()
      };
   }

  
  handleClick(e){
    e.stopPropagation();

    this.state.inputRef.current.click();
  }

  handleFiles(files){
    if(!files || files.length === 0){
      return;
    }

    const formData = new FormData();
    formData.append("avatarFile", files[0], this.props.user.userID + ".jpg"); 

    this.props.updateAvatar(formData);
  }

   render() {
      return (
         <Row className="avatar">
            <div className="edit-container" onClick={e => this.handleClick(e)}>
               <UserImage className="profile-avatar" avatarId={this.props.user.avatarId}></UserImage>
               <div className="update-label">Click to update</div>
            </div>
            <input ref={this.state.inputRef} type="file" 
               accept="image"
               className="file-input"
               onChange={e => this.handleFiles(e.target.files)}
            ></input>
         </Row>
      );
   }
}

export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(ChangeAvatar);