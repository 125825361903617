import apiService from '../services/api-service';

const requestPostsType = "FETCH_POSTS_PENDING";
const receivePostsType = "FETCH_POSTS_SUCCESS";

const requestCommentsType = "FETCH_COMMENTS_PENDING";
const receiveCommentsType = "FETCH_COMMENTS_SUCCESS";

const errorPostsType = "FETCH_POSTS_ERROR";
const errorCommentsType = "FETCH_COMMENTS_ERROR";

const initialState = { isLoading: { posts: false, comments: false}, total: null, posts: [], comments: [] };

export const postActions = {
  requestPosts: (step) => (dispatch, getState) => fetchPosts(step, dispatch, getState().communityPage),
  createPost: (post) => (dispatch, getState) => createPost(post, dispatch, getState().communityPage)
};

export const commentActions = {
  requestComments: reference => (dispatch, getState) => fetchComments(reference, dispatch, getState().communityPage),
  submitComment: comment => (dispatch, getState) => submitComment(comment, dispatch, getState().communityPage),
  deleteComment: (reference, commentId) => (dispatch, getState) => deleteComment(reference, commentId, dispatch, getState().communityPage)
};

function fetchPosts(step, dispatch, state) {
  var skip = state.posts.length || 0;
  if(step === 0 || state.isLoading.posts === true)
  {
    return;
  } 

  dispatch({type: requestPostsType});

  let url = `api/community/posts?skip=${skip}&take=${step}`;
  apiService.get(url, true)
  .then(resp => {
    return resp.json();
  })
    .then(
      (result) => {
        dispatch({type: receivePostsType, total: result.total, posts: result.items, postCreated: false});
      },
      (data) => {
        dispatch({ type: errorPostsType, errorData: data });
      }
    );
}

function createPost(post, dispatch, state) {
  if(state.isLoading.posts === true)
  {
    return;
  } 

  dispatch({type: requestPostsType});

  let formData = new FormData();
  for (let prop in post) {
    if(post[prop]){
      formData.append(prop, post[prop]);
    }
  }

  let url = `api/community/posts/add`;
  apiService.post(url, formData, true, 'form')
  .then(resp => {
    return resp.json();
  })
    .then(
      (result) => {
        dispatch({type: receivePostsType, total: state.total++, posts: result, postCreated: true});
      },
      (data) => {
        dispatch({ type: errorPostsType, errorData: data });
      }
    );
}

function fetchComments(reference, dispatch, state) {
  if(state.isLoading.comments === true)
  {
    return;
  }

  dispatch({type: requestCommentsType});

  let url = `api/community/posts/comments?reference=${reference}`;
    apiService.get(url, true)
    .then(resp => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({type: receiveCommentsType, comments: result});
      },
      (data) => {
        dispatch({ type: errorCommentsType, errorData: data });
      }
    );
}

function submitComment(comment, dispatch, state) {
  if(state.isLoading.comments === true)
  {
    return;
  }

  dispatch({type: requestCommentsType});

  let url = `api/community/posts/comments/add`;
  apiService.post(url, comment, true)
    .then(resp => {
      return resp.json()
    })
    .then(
      (result) => {
        dispatch({type: receiveCommentsType, comments: result});
      },
      (data) => {
        dispatch({ type: errorCommentsType, errorData: data });
      }
    );
}

function deleteComment(reference, commentId, dispatch, state) {
  if(state.isLoading.comments === true)
  {
    return;
  }

  dispatch({type: requestCommentsType});

  let url = `api/community/posts/comments/${reference}/${commentId}`;
  apiService.delete(url, true)
    .then(resp => {
      return resp.json()
    })
    .then(
      (result) => {
        dispatch({type: receiveCommentsType, comments: result});
      },
      (data) => {
        dispatch({ type: errorCommentsType, errorData: data });
      }
    );
}

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestPostsType) {
    return { ...state, isLoading: {...state.isLoading, posts: true}, postCreated: false, errorData: null };
  }
  
  if (action.type === requestCommentsType) {
    return { ...state, isLoading: {...state.isLoading, comments: true}, errorData: null };
  }

  if (action.type === receivePostsType) {
    let posts = reducePosts(state, action);
    return { ...state, isLoading: {...state.isLoading, posts: false}, postCreated: action.postCreated, total: action.total, posts: posts};
  }

  if (action.type === receiveCommentsType) {
    return { ...state, isLoading: {...state.isLoading, comments: false}, comments: action.comments };
  }  

  if (action.type === errorPostsType) {
    return { ...state, isLoading: {...state.isLoading, posts: false}, errorData : action.errorData };
  }

  if (action.type === errorCommentsType) {
    return { ...state, isLoading: {...state.isLoading, comments: false}, errorData : action.errorData };
  }

  return state;
};

function reducePosts(state, action){
  let uniqueRefs = [...state.posts, ...action.posts].map(x => x.reference).filter((v,i,s) => s.indexOf(v) === i);
  return uniqueRefs.map(x => {
    let newPost = action.posts.find(p => p.reference === x);
    if(newPost){
      return newPost;
    }
    let oldPost = state.posts.find(p => p.reference === x);
    return oldPost;
  });
}