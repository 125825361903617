import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import { useFromNow } from '../../../helpers/datetime-helpers';

import UserImage from '../../user-image/user-image.component';

import ReactPlayer from "react-player";

export default (props) => {
    return (
        <Link to={`/community?reference=${props.reference}`}>
            <Container id={"post-item-" + props.reference} className="dashboard-search-item-block">
                <Row className="community-search-header">
                    <Col sm={2}>
                        <UserImage avatarId={props.user.avatarId} className="img-md rounded-circle" />
                    </Col>
                    <Col className="community-search-actor" sm={6}>
                        <Row className="item-posted-by">{props.user.displayName}</Row>
                        <Row className="item-posted-date">
                        {useFromNow(props.createdDate) &&
                            <span>posted:  <Moment fromNow>{props.createdDate}</Moment></span>
                        }
                        {!useFromNow(props.createdDate) &&
                            <Moment date={props.createdDate} format="YYYY-MM-DD HH:mm" />
                        }
                        </Row>
                    </Col>                 
                    <Col className="community-search-title">{props.content.title}</Col>
                </Row>
                <Row className="community-search-content">
                    <Col md={3} mt={4}>
                        {props.content.contentType !== "text" &&   
                            <div className="media-content community-search-item-block">
                                {props.content.contentType === "video" &&   
                                    <div className="player-wrapper">
                                        <ReactPlayer
                                            className="react-player"
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            url={props.content.url}
                                            playing={props.playing}
                                            onPause={props.onPause}
                                            onStart={props.onStart}
                                        />
                                    </div>
                                }
                                {props.content.contentType === "image" &&                
                                    <Image className="community-search-item-block" src={props.content.url} alt={props.content.description} height="250px" />
                                }
                            </div>
                        }
                    </Col>
                    <Col md={9} mt={8}>
                        <Row className="community-search-comment" dangerouslySetInnerHTML={{__html: props.content.comment}} ></Row>
                        {props.content.tags &&
                            <Row className="dashboard-search-tags">
                                {props.content.tags.map((v, i) => <span key={i} className="dashboard-search-tag">{v}</span>)}
                            </Row>
                        }
                    </Col>
                    <hr />
                </Row>
            </Container>
        </Link>
    );
}