import apiService from '../services/api-service';

const requestSearchType = "FETCH_SEARCH_PENDING";
const receiveSearchType = "FETCH_SEARCH_SUCCESS";
const errorSearchType = "FETCH_SEARCH_ERROR";

const searchUrls = {
    video: "api/video-lessons/search",
    community: "api/community/search",
    bonus: "api/bonus/search"
}

const initialState = {
    currentSearch: undefined,
    video: {
        loading: false,
        page: 1,
        total: 0,
        items: []
    },
    community: {
        loading: false,
        page: 1,
        total: 0,
        items: []
    },
    bonus: {
        loading: false,
        page: 1,
        total: 0,
        items: []
    }
};

export const searchActions = {
  search: (searchTerm, searchType, page) => (dispatch, getState) => search(searchTerm, searchType, page, dispatch, getState().search, true)
};

function search(searchTerm, searchType, page, dispatch, state) {
  if(state[searchType].loading)
  {
    return;
  }

  dispatch({type: requestSearchType, searchTerm, searchType, page});

  let url = `${searchUrls[searchType]}?search=${searchTerm}&page=${page}`;
    apiService.get(url, true)
    .then(resp => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({type: receiveSearchType, searchType, search: result});
      },
      (data) => {
        dispatch({ type: errorSearchType, searchType, errorData: data });
      }
    );
}

export const reducer = (state, action) => {
    state = state || initialState;
  
    if (action.type === requestSearchType) {
      let searchState = state[action.searchType];
      let newState = {...state, currentSearch: action.searchTerm};
      newState[action.searchType] = {...searchState, 
          loading: true,
          page: action.page,
          total: 0,
          items: [],
          error: undefined
      }
      return newState;
    }

    if (action.type === receiveSearchType) {
      let searchState = state[action.searchType];
      let newState = {...state};
      newState[action.searchType] = {...searchState, 
          loading: false,
          page: action.search.page,
          total: action.search.totalPages,
          items: action.search.items
      }
      return newState;
    }

    if (action.type === errorSearchType) {
      let searchState = state[action.searchType];
      let newState = {...state};
      newState[action.searchType] = {...searchState, 
          loading: false,
          error: action.error
      }
      return newState;
    }

    return state;
}