import React, { Component } from "react";
import { Row, Button } from 'react-bootstrap';

import {sortDescending} from "../../helpers/datetime-helpers";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/notifications.store";

import NotificationItem from './notification.item';

import "./notifications.css";

const urlPattern = /^(((http(s)?):\/\/)?(www\.)?([a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b))?\/([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;

class NotificationsList extends Component {
  click(targetId, url){
   var update = { targetId: targetId, read: true };
   this.props.deleteNotification(update);
   
   
   if (url && url.match(urlPattern)) {
      window.location.href = url;     
   }
  }

  clear(e){
    var updates = this.props.notifications.map((n, i) => { return { targetId: n.targetId, read: false } });
    this.props.deleteNotifications(updates);

    if (this.props.onClear) {
      this.props.onClear();
    }
  }

  render() {
    return (
      <>
        <Row><Button className="btn btn-primary notifications-clear" onClick={e => this.clear(e)} disabled={this.props.notifications.length === 0}>Clear All</Button></Row>
         {this.renderItems()}
      </>
    );
  }

  renderItems() {
     let data = this.props.notifications.sort((a,b) => sortDescending(a,b,"createdDate"));
     
     let grouped = data.reduce((p, c, i) => {
       let group = p.find(x => x.targetId === c.targetId);
       if(!group){
          group = {targetId: c.targetId, values: []};
          p.push(group);
       }
       group.values.push(c);
       return p;
     }, []);

     let notifications = grouped.map(group => {
       if(group.values.length === 1){
         return group.values[0]; 
       }
       let notification = group.values[0];
       notification.user = undefined;
       notification.comment = `You have ${group.values.length} unread notifications on ${notification.targetDescription}`;
       return notification;
     });

     return notifications.map((x,i)=> <NotificationItem key={i} {...x} onClick={(targetId, url) => this.click(targetId, url)}></NotificationItem>)
  }
}

export default connect(
  state => state.notifications,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(NotificationsList);