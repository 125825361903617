import React from "react";
import { Row, Col } from 'react-bootstrap';
import { useFromNow } from '../../helpers/datetime-helpers';
import Moment from 'react-moment';
import 'moment-timezone';

import UserImage from '../user-image/user-image.component';

export default props => {
   return (
      <Row className="comment" ref={props.focusRef} onClick={() => props.onClick()}>
         <Col lg={3} md={1} sm={1} className="comment-icon">
            <UserImage avatarId={props.user.avatarId} className="img-sm rounded-circle" />
         </Col>
         <Col lg={9} md={10} sm={9}>
            {props.active && 
               <Row className="comment-user"> Replying to: {props.user.displayName}</Row>
            }
            {!props.active && 
               <Row className="comment-user">{props.user.displayName}</Row>
            }
            <Row className="comment-text">{props.commentText}</Row>
            <Row className="comment-date">
               {useFromNow(props.commentDate) &&
                  <span>posted:  <Moment fromNow>{props.commentDate}</Moment></span>
               }
               {!useFromNow(props.commentDate) &&
                  <Moment date={props.commentDate} format="YYYY-MM-DD HH:mm" />
               }
               {(props.replyCount === 1 && !props.active) &&
                  <div className="comment-reply-count">{props.replyCount} Reply</div>
               }
               {(props.replyCount > 1 && !props.active) &&
                  <div className="comment-reply-count">{props.replyCount} Replies</div>
               }
            </Row>
         </Col>
         {props.canDelete &&
            <div className="comment-delete" onClick={() => props.onDelete(props.commentId)}></div>
         }
      </Row>
   );
}