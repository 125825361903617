import React from "react";

import CmsVideoBlock from '../blocks/video.block.component';
import CmsVideoListItemBlock from '../blocks/video-list-item.block.component';
import CmsRichTextBlock from '../blocks/rich-text.block.component';
import CmsRichTextListBlock from "../blocks/rich-text-list.block.component";
import CmsHtmlBlock from '../blocks/html.block.component';
import CmsTabBlock from '../blocks/tab.block.component';
import CmsColumnsBlock from '../blocks/columns.block.component';
import CmsVideoListBlock from "../blocks/video-list.block.component";
import CmsImageBlock from '../blocks/image.block.component';
import CmsImageListBlock from "../blocks/image-list.block.component";
import CmsDownloadCtaBlock from '../blocks/download-cta.block.component';
import CmsDownloadCtaListBlock from "../blocks/download-cta-list.block.component";

export function mapBlocks(blocks, caller, mapper){
   if(blocks.length === 0){
      return;
   }
   return blocks.map((b,i) => mapBlock(b, i, caller, mapper)).filter(x => x !== null);
}

function mapBlock(block, index, caller, mapper) {
   if (mapper) {
      var output = mapper(block, index, caller);
      if (output) {
         return output;
      }
   }

   switch(block.blockType){
      case "video": return renderVideo(index,block, caller);
      case "video-list": return <CmsVideoListBlock key={index} videoId={caller.state.currentVideo} onChange={videoId => caller.changeVideo(videoId)} active={caller.props.active} {...block} ></CmsVideoListBlock>;
      case "video-list-item": return renderVideoItem(index,block, caller);
      case "image": return <CmsImageBlock key={index} {...block}></CmsImageBlock>;
      case "image-list": return <CmsImageListBlock key={index} {...block} ></CmsImageListBlock>;
      case "download-cta": return <CmsDownloadCtaBlock key={index} {...block}></CmsDownloadCtaBlock>;
      case "download-cta-list": return <CmsDownloadCtaListBlock key={index} {...block} ></CmsDownloadCtaListBlock>;
      case "rich-text": return <CmsRichTextBlock key={index} {...block} values={caller.props.textValues}></CmsRichTextBlock>;
      case "rich-text-list": return <CmsRichTextListBlock key={index} {...block} ></CmsRichTextListBlock>;
      case "html": return <CmsHtmlBlock key={index} {...block}></CmsHtmlBlock>;
      case "tab": return <CmsTabBlock key={index} {...block} textValues={caller.props.textValues} onChangeVideo={videoId => caller.changeVideo(videoId)} location={caller.props.location} mapper={mapper}></CmsTabBlock>;
      case "columns": return <CmsColumnsBlock key={index} {...block} textValues={caller.props.textValues} onChangeVideo={videoId => caller.changeVideo(videoId)} location={caller.props.location} mapper={mapper}></CmsColumnsBlock>;
      default: return null;
   }
}  

function renderVideo(key, block, caller) {
   const isActive = caller.props.active && caller.state.currentVideo === block.id
   return (      
      <CmsVideoBlock key={key} {...block} 
         playing={isActive}
         onPause={() => caller.setState({currentVideo: undefined})}
         onStart={() => caller.setState({currentVideo: block.id})}
      ></CmsVideoBlock>);
}  

function renderVideoItem(key, block, caller){
   const isActive = caller.props.active && caller.state.currentVideo === block.id
   return (
      <CmsVideoListItemBlock key={key} {...block} 
         playing={isActive}
         onPause={() => caller.setState({currentVideo: undefined})}
         onStart={() => caller.setState({ currentVideo: block.id })}
      ></CmsVideoListItemBlock>);
  }