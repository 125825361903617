import React, {Component} from "react";
import { Container } from 'react-bootstrap';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'

import Comment from './comment';

import './comments-section.css';

export default class CommentsSection extends Component {
   constructor(){
      super();
      this.state = { 
         containerRef: React.createRef(), 
         focusRef: React.createRef(),
         currentComment: undefined,
         comments: []
      };
   }

   componentDidMount(){
      this.scrollToRef(this.state.focusRef);
      this.setState({comments: this.props.comments});
   }

   componentDidUpdate(prevProps){
      this.scrollToRef(this.state.focusRef.current);

      if(this.props.comments !== prevProps.comments){
         this.updateComments();
      }
   }

   scrollToRef(ref){
      let container = this.state.containerRef.current;
      if(ref == null)
         return;

      container.scrollTop = ref.offsetTop; 
   }

   exitComment(){
      this.setState({currentComment: undefined}, this.updateComments);
   }

   enterComment(comment){
      if(this.state.currentComment){
         return;
      }
      this.setState({currentComment: comment}, () => this.updateComments(comment));
   }

   updateComments(currentComment){
      if(!currentComment && this.state.currentComment){
         currentComment = this.props.comments.find(x => x.commentId === this.state.currentComment.commentId);
      }

      if(currentComment){
         let comments = this.props.comments.filter(x => x.parentId === currentComment.commentId);
         this.setState({currentComment, comments}, () => this.props.onCommentSelect(currentComment))
      } else {
         let comments = this.props.comments.filter(x => !x.parentId);
         this.setState({currentComment: undefined, comments}, () => this.props.onCommentUnselect())
      }
   }

   render() {
      return (
         <Container className="comments-section" fluid>
            {this.state.currentComment &&
               <div className="comments-reply">
                  <FontAwesomeIcon icon={faUndo} className="comments-return-icon" onClick={() => this.exitComment()} />
                  <Comment {...this.state.currentComment} onClick={() => this.exitComment()} active={true} ></Comment>
               </div>
            }
            <div className={`comments-container${this.state.currentComment ? " reply" : ""}`} ref={this.state.containerRef}>
               {this.renderComments()}
            </div>
         </Container>
      );
   }

   renderComments(){
      let comments = this.state.comments;
   
      let mapped = comments.map((c, i) => { return { dateKey: moment(c.commentDate).format('YYYYMMDDHHmmss'), comment: c } });
         
      let sorted = this.props.sort === "desc"
         ? mapped.sort((a, b) => a.dateKey > b.dateKey ? -1 : 1)
         : mapped.sort((a, b) => a.dateKey < b.dateKey ? -1 : 1);
      
      let focusIndex = this.props.sort === "desc" ? 0 : sorted.length - 1;
   
      return sorted.map((c, i) => 
         <Comment key={i} {...c.comment} 
            canDelete={this.props.canDelete} 
            focusRef={i === focusIndex ? this.state.focusRef : null } 
            onDelete={id => this.props.onDelete(id)}
            onClick={() => this.enterComment(c.comment)}
         >            
         </Comment>);
   }
 } 