import React, {Component} from "react";
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';
import FromNowComponent from '../../../helpers/from-now-component';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { commentActions } from "../../../store/community-post.store";

import CommentsSection from '../../../components/comments/comments-section.component';
import CommentInput from '../../../components/comments/comment.input.component';

import UserImage from '../../../components/user-image/user-image.component';

import ReactPlayer from "react-player";

import Popup from '../../../components/popup/popup.component';

class CommunityFullPost extends Component {
  constructor(){
    super();
    this.state = {
     showWarning: false 
    }
  }

  componentDidMount(){
    this.props.requestComments(this.props.reference);
  }

  saveComment(comment){
    let commentId = this.state.currentComment ? this.state.currentComment.commentId : undefined;
    let newComment = { postGuid: this.props.reference, commentText: comment, parentId: commentId };
    this.props.submitComment(newComment);
  }

  deleteComment(){
    this.props.deleteComment(this.props.reference, this.state.commentId);
    this.setState({showWarning: false});
  }

  render() {
    return (
      <Popup 
        show={true}
        className="community-page-dialog"
        onClose={() => this.props.onClose()}
      >
        <Container id={"post-item-" + this.props.reference} className="community-full-post-item">
          <Row className="community-post-header">
            <Col md={2}>
              <UserImage avatarId={this.props.user.avatarId} className="img-md rounded-circle" />
            </Col>
            <Col className="community-post-actor" md={10}>
              <Row className="item-posted-by">{this.props.user.displayName}</Row>
              <Row className="item-posted-date">
                <FromNowComponent date={this.props.createdDate}></FromNowComponent>
              </Row>
              <Row className="community-post-title">
                  {this.props.content.title}                
              </Row> 
              <Row className="community-search-tags">
                {this.props.content.tags && this.props.content.tags.map((v, i) => <span key={i} className="community-search-tag">{v}</span>)}
              </Row>
            </Col>
          </Row>
          <Row className="community-post-content">
            <Col className="media-content" xl={8} lg={6}>
              {this.props.content.contentType === "video" &&   
                  <ReactPlayer
                    className="post-content-video"
                    controls={true}
                    url={this.props.content.url}
                    playing={false}
                    onPlay={() => this.props.onPlay()}
                    onPause={() => this.setState({ playing: false })}
                    height="30vw"
                    width="50vw"
                  />
              }
              {this.props.content.contentType === "image" &&                
                <Image className="post-content-image" src={this.props.content.url} alt={this.props.content.description} />
              }
              <div className="community-post-comment" dangerouslySetInnerHTML={{__html: this.props.content.comment}} ></div>
            </Col>
            <Col xl={4} lg={6} md={12} className="content-comments">
              <CommentsSection comments={this.props.comments} 
                canDelete={this.props.user.userId === this.props.currentUser.userID
                  || this.props.currentUser.roles.includes("Administrator")}
                onDelete={commentId => {
                    this.setState({commentId, showWarning: true})
                  }}
                onCommentSelect={comment => this.setState({currentComment: comment})}
                onCommentUnselect={comment => this.setState({currentComment: undefined})}
              ></CommentsSection>
            </Col>
          </Row>
          <CommentInput title="Community Page Comments" label={this.props.content.title} avatarId={this.props.currentUser.avatarId} onSave={(comment) => this.saveComment(comment)}></CommentInput>
          <Modal className="warning-dialog" show={this.state.showWarning}>
              <Modal.Body>
                <div>
                Are you sure you wish to delete this comment.
                </div>
                <Button variant="secondary" onClick={() => this.setState({showWarning: false})}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => this.deleteComment()}>
                    Continue
                </Button>
              </Modal.Body>
          </Modal>
        </Container>
      </Popup>
    );
  }
}

export default connect(
  state => state.communityPage,
  dispatch => bindActionCreators(commentActions, dispatch)
)(CommunityFullPost);