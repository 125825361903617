import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import CmsPage from "../../cms/page.component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

import './fast-track.css';

export default class FastTrackPage extends Component {
    constructor(){
        super();
        this.state = { page: 1 }
    }

    componentDidMount(){        
      var pageNo = Cookie.get(`fast-track/${this.props.section}`);
      if(pageNo){
        this.setState({page: parseInt(pageNo)})
      }
    }

    changePage(inc){
        var pageNo = this.state.page + inc;
        if(pageNo < 1 || pageNo > this.props.maxPage)
            return;

        this.setState({page: pageNo}, () => Cookie.set(`fast-track/${this.props.section}`, pageNo));
    }

    render() {
        return (
        <Container className="fast-track">
            <Row className="fast-track_control">
                { this.state.page > 1 && 
                    <Col xs={3} onClick={() => this.changePage(-1)} className="fast-track_click">
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="expand-icon" />
                    </Col>
                }
                { this.state.page === 1 &&
                    <Col xs={3} className="fast-track_click">
                        <Link to="/fast-track/green-reading">
                            <FontAwesomeIcon icon={faArrowCircleLeft} className="expand-icon" />
                        </Link>
                    </Col>
                }
                <Col>Day {this.state.page}</Col>
                <Col xs={3} onClick={() => this.changePage(1)} className="fast-track_click">
                    { this.state.page < this.props.maxPage && <FontAwesomeIcon icon={faArrowCircleRight} className="expand-icon" />}
                </Col>
            </Row>
            <Row>            
                <CmsPage pageTag={`fast-track/${this.props.section}/day-${this.state.page}`} location={this.props.location}></CmsPage>
            </Row>
        </Container>
        );
    }
}
