import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

import "./video.css";

export default props => {
   return (
      <Container className="events-features">
        <Row>
            <Col>
            {props.videos
               .map((video,index) =>
               {
                  const videoId = video.videoId ? video.videoId : video.id;
                  const isActive = videoId === props.activeVideo ? "active" : "";
                  return (
                  <Row
                     onClick={() => props.onChange(videoId)}
                     key={index}
                     className={`video-item ${isActive}`}
                  >
                     <Col xs={9} className="videoItem_title">
                        {props.manualNumbering ? video.index : index + 1} : {video.title}
                     </Col>
                     <Col xs={1}>
                        <b>[{video.duration}]</b>
                     </Col>
                  </Row>
                  )
               })
            }
            </Col>
        </Row>
      </Container>
   )
}