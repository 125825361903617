import React, {Component} from "react";
import { Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import Moment from 'react-moment';
import 'moment-timezone';

export default class UserDetails extends Component {

  render() {
    return (
      <Row className="user-details">
         <Col lg={6}>
            <Row>
               <Col md={5}>
                  <label htmlFor="email-address">Email Address</label>
               </Col>
               <Col md={6}>
                  <input id="email-address" className="user-input" type="text" value={this.props.user.email} onChange={(e) => this.props.onChange("email", e.target.value )}></input>
               </Col>
            </Row>
            <Row>
               <Col md={5}>
                  <label htmlFor="display-name">Display Name</label>
               </Col>
               <Col md={7}>
                  <input id="display-name" className="user-input" type="text" value={this.props.user.displayName} onChange={(e) => this.props.onChange("displayName", e.target.value )}></input>
               </Col>
            </Row>
            <Row>
            <Col md={5}>
               <label htmlFor="last-name">Active Status</label>
            </Col>
            <Col md={7}>
               <ToggleButtonGroup name="active-status" value={this.props.user.isActive} onChange={(value) => this.props.onChange("isActive", value )}>
                  <ToggleButton name="active-status" value={true}>Active</ToggleButton>
                  <ToggleButton name="active-status" value={false}>Inactive</ToggleButton>
               </ToggleButtonGroup>
            </Col>
            </Row>
         </Col>
         <Col lg={6}>
            <Row>
            <Col md={5}>
               <label htmlFor="date-created">Date Created</label>
            </Col>
            <Col md={7}>
               <h4>
                  <Moment date={this.props.user.dateCreated} format="DD MMMM YYYY" />
               </h4>
            </Col>
            </Row>
            <Row>
            <Col md={5}>
               <label htmlFor="first-name">First Name</label>
            </Col>
            <Col md={7}>
               <input id="first-name" className="user-input" type="text" value={this.props.user.firstName} onChange={(e) => this.props.onChange("firstName", e.target.value )}></input>
            </Col>
            </Row>
            <Row>
            <Col md={5}>
               <label htmlFor="last-name">Last Name</label>
            </Col>
            <Col md={7}>
               <input id="last-name" className="user-input" type="text" value={this.props.user.lastName} onChange={(e) => this.props.onChange("lastName", e.target.value )}></input>
            </Col>
            </Row>
         </Col>
      </Row>
    );
  }
}