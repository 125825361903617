import React, {Component} from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';

import ChangeAvatar from './components/change-avatar.component';
import ChangeUserDetails from './components/change-details.component';
import ChangePasswordForm from './components/change-password.component';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../store/user.store";

import ReactPlayer from "react-player";

import './user-profile.page.css';

class UserProfile extends Component {
  constructor(){
    super();
    this.state = {
      avatar: undefined
    }
  }

  componentDidUpdate(prevProps) {
      if (prevProps.userUpdated !== this.props.userUpdated && this.props.userUpdated) {
         this.setState({ notify: true }, () => {
            setTimeout(() => this.setState({ notify: false }), 2000)
         });
      }
      if (prevProps.errorData !== this.props.errorData && this.props.errorData) {
         this.setState({ error: true }, () => {
            setTimeout(() => this.setState({ error: false }), 2000)
         });
      }    
  }
  
  closePasswordModal(){
    this.setState({ changePassword: false });
  }

  render() {
    return (
      <Container id="user-profile-page" className="profile-page p-5">
        <Row className="title-row">
          <h2 className="cms-page-title h1 txt--upper txt--spacing rich-text__heading--background"><span>My Profile Page</span></h2>
        </Row>
        <Row className="content-row">
          <Col xl={5} lg={7}>
            <ChangeAvatar></ChangeAvatar>
          </Col>
          <Col xl={6} lg={5} className="user-details">
            <ChangeUserDetails></ChangeUserDetails>
            <Row className="change-password">
              <Button onClick={(e) => this.setState({changePassword: true})}>Change Password</Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <div className="text-left">
              <h4>Welcome Video </h4>
              <hr></hr>
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <div className="player-wrapper">
              <ReactPlayer
                ref={this.ref}
                className="react-player"
                controls={true}
                width="100%"
                height="100%"
                url={
                  this.props.user.roles.includes("AcademyMember")
                  ? "https://pro-fitpt-1.wistia.com/medias/yu588iq8a9"
                  : "https://pro-fitpt-1.wistia.com/medias/loembdjqab"
                }
                playing={false}
                onReady={() => {
                  this.setState({ ready: true });
                }}
                onProgress={this.onProgress}
              />
            </div>
          </Col>
        </Row>
        <ChangePasswordForm
          show={this.state.changePassword}
          onClose={() => this.closePasswordModal()}
        ></ChangePasswordForm>
        <div className={"change-notify " + (this.state.notify ? "active" : "")}>
            <div className="change-notify-text">User has been updated successfully.</div>
        </div>
        <div className={"change-notify error " + (this.state.error ? "active" : "")}>
            <div className="change-notify-text">this.props.errorData</div>
        </div>
      </Container>
    );
  }
}

export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(UserProfile);