import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../App.css";
import { NavItem, Dropdown, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../store/user.store";

class Sidebar extends Component {
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/Dashboard">
              <i className="icon-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

            <li className="nav-item">
              <Link className="nav-link" to="/12-Week-Programme">
                <i className="icon-calendar menu-icon"></i>
                <span className="menu-title">12 Week Journey</span>
              </Link>
            </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Video-Lessons">
              <i className="icon-film menu-icon"></i>
              <span className="menu-title">Video Library</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Community">
              <i className="icon-emotsmile menu-icon"></i>
              <span className="menu-title">Community</span>
            </Link>
          </li>
            
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink}>
                <i className="icon-docs menu-icon"></i>
                <span className="menu-title">Resources</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="nav-link" href='/Resources/PuttingSocialInterviews'><span className="menu-title">Putting social interviews</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/Resources/Puttranome'><span className="menu-title">Puttranome</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/Resources/PuttingTools'><span className="menu-title">Putting tools</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/Resources/BreakCharts'><span className="menu-title">Break Charts</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/Resources/SwingLength'><span className="menu-title">Swing Length Charts</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/resources/putt-distance-charts'><span className="menu-title">Putt Distance Charts</span></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <li className="nav-item">
              <Link className="nav-link" to="/Leaderboard">
                <i className="icon-trophy menu-icon"></i>
                <span className="menu-title">Leaderboard </span>
              </Link>
            </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Your-Stats">
              <i className="icon-graph menu-icon"></i>
              <span className="menu-title">Performance Stats</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Strokes-Gained">
              <i className="icon-calculator menu-icon"></i>
              <span className="menu-title">Strokes Gained</span>
            </Link>
          </li>

          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink}>
              <i className="icon-rocket menu-icon"></i>
              <span className="menu-title">Fast Track</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="nav-link" href='/fast-track/green-reading'><span className="menu-title">21 Day Green Reading</span></Dropdown.Item>
            </Dropdown.Menu>
           </Dropdown>

          <li className="nav-item">
            <Link className="nav-link" to="/masterclass">
              <i className="icon-graduation menu-icon"></i>
              <span className="menu-title">MF Masterclass</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/webinars">
              <i className="icon-graduation menu-icon"></i>
              <span className="menu-title">Webinars</span>
            </Link>
          </li>

          {this.props.user.roles.includes("BonusUser") &&
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink}>
                <i className="icon-camrecorder menu-icon"></i>
                <span className="menu-title">Bonuses</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="nav-link" href='/bonus/posture-core'><span className="menu-title">Posture &amp; Core</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/bonus/mental-game'><span className="menu-title">The Mental Game</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/bonus/nutrition'><span className="menu-title">Nutrition for Golf</span></Dropdown.Item>
                <Dropdown.Item className="nav-link" href='/bonus/coach-interviews'><span className="menu-title">Coach Interviews</span></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }

            <li className="nav-item">
              <Link className="nav-link" to="/Locker-Room">
                {/* <i className="menu-icon"></i> */}
                <i className="icon-login  menu-icon"></i>

                <span className="menu-title">Locker Room </span>
              </Link>
            </li>
        </ul>
      </nav>
    );
  }
}
  
export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(withRouter(Sidebar));
