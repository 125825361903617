import React from "react";

var LegendContainer = (props) =>{
   return (
      <div className="legend-container">
         {props.children}
      </div>
   )
}

var Legend = (props) =>{
   return (
      <div className={"legend-item legend-" + props.color}>
         <div className="legend-title">{props.title}</div>
         <div className="legend-value">{`(${props.value})`}</div>            
      </div>
   )
}

export {
   LegendContainer,
   Legend
}