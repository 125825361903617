import React from 'react';
import {
   Route,
   Switch
} from "react-router-dom";
import CmsPage from "../cms/page.component";

import ResourcesPuttingSocialInterviews from './PuttingSocialInterviews';
import ResourcesPuttranome from './Puttranome';
import ResourcesPuttingTools from './PuttingTools';
import ResourcesSwingLength from './SwingLengthCharts/resources-swing-length';
import ResourcesBreakCharts from './PuttingTools/resources-break-charts';

const ResourcesRouting = props => (
   <Switch>
      <Route exact path="/resources/PuttingSocialInterviews" component={ResourcesPuttingSocialInterviews}></Route>
      <Route exact path="/resources/SwingLength" component={ResourcesSwingLength}></Route>
      <Route exact path="/resources/Puttranome" component={ResourcesPuttranome}></Route>
      <Route exact path="/resources/PuttingTools" component={ResourcesPuttingTools}></Route>
      <Route exact path="/resources/BreakCharts" component={ResourcesBreakCharts}></Route>
      <Route exact path="/resources/putt-distance-charts"  component={() => <CmsPage pageTag="resources/putt-distance" location={props.location} ></CmsPage>}></Route>
   </Switch>
);

export default ResourcesRouting;