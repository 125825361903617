import React, { Component } from "react";
import { Row, Col } from 'react-bootstrap';

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

import AnalyticsService from '../../services/analytics-service';
import UserImage from '../user-image/user-image.component';

export default class CommentInput extends Component {
   constructor(){
      super();

      this.state = { showEmojiPicker: false, newMessage: ""}
   }

   toggleEmojiPicker(){
      this.setState({ showEmojiPicker: !this.state.showEmojiPicker })
   }

   addEmoji(emoji){
      const newMessage = this.state.newMessage;
      const text = `${newMessage}${emoji.native}`;

      this.setState({
        newMessage: text,
        showEmojiPicker: false,
      });
   }

   handleInput(e) {
      this.setState({
         newMessage: e.target.value
      });
   }

   handleKeyUp(e) {
      if (e.key === 'Enter') {
         let message = this.state.newMessage;
         if(!message || message.trim() === ""){
            return;
         }

         if(this.props.onSave){
            this.props.onSave(message.trim());

            AnalyticsService.addEvent(this.props.title, "Comments", this.props.label);            
         }
         this.setState({ newMessage: "" });
      }
   }

   render() {
      return (
         <div>
            {this.state.showEmojiPicker && 
               <Picker set="facebook" onSelect={(emoji) => this.addEmoji(emoji)}></Picker>
            }
            <Row className="comment-input">
               <Col md={1} xs={2} sm={1} className="comment-icon">
                  <UserImage avatarId={this.props.avatarId}  className="img-sm rounded-circle" />
               </Col>
               <Col xl={11} md={10} sm={11} xs={10} className="comments-text-input-wrapper">
                  <Row>
                        <textarea
                           className="comments-text-input"
                           value={this.state.newMessage}  
                           onChange={(e) => this.handleInput(e)}
                           placeholder="Type your message and hit ENTER to send"
                           onKeyUp={(e) => this.handleKeyUp(e)}
                        />
                        <span role="img" className="comments-emoji-picker" aria-label="Emoji Picker" onClick={() => this.toggleEmojiPicker()}>🙂</span>
                  </Row>
               </Col>
            </Row>
         </div>
      );
   }
}