import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Dashboard from "../../Dashboard/dashboard.page";
import FAQ from "../../FAQ";

import Leaderboard from "../../pages/leaderboard/leaderboard.page.component";

import Community from "../../pages/community/community.page.component";
import PerformanceStats from "../../pages/performance-stats/performance-stats.page.component";

import StrokesGained from "../../Strokes-Gained";

import ResourcesRouting from '../../Resources/resources.routing.component';
import TwelveWeekRouting from '../../pages/twelve-week/twelve-week.routing.component';
import LockerRoomRouting from '../../pages/locker-room/locker-room.routing.component';
import VideoLessonRouting from '../../pages/video-lessons/video-lessons.routing.component';
import FastTrackRouting from '../../pages/fast-track/fast-track.routing.component';
import BonusPageRouting from '../../pages/bonus-page/bonus-page.routing.component';
import UserProfile from '../../pages/user-profile/user-profile.page.component';
import SiteAdmin from '../../pages/site-admin/site-admin.page.component';

import CmsPage from "../../cms/page.component";
import DefaultRouting from './default-routing.component';

const SecureRouting = props => (
   <Switch>
      <Route exact path="/user-profile" component={UserProfile}></Route>
      <Route path="/site-admin" component={
         () => {
            if (props.user.roles.includes("Administrator")) {
               return <SiteAdmin></SiteAdmin>
            } else {
               return <Redirect to="/unauthorized"></Redirect>
            }
         }
      }></Route>
      <Route exact path="/FAQ" component={FAQ}></Route>
      <Route exact path="/terms-and-conditions"
         component={() => <CmsPage pageTag="website/terms-and-conditions" ></CmsPage>}>         
      </Route>

      <Route exact path="/leaderboard" component={Leaderboard}></Route>

      <Route exact path="/community" component={Community}></Route>
      
      <Route exact path="/masterclass"
         component={() => <CmsPage pageTag="masterclass/fitzpatrick" location={props.location} ></CmsPage>}>         
      </Route>
      
      <Route exact path="/webinars"
         component={() => <CmsPage pageTag="webinars" location={props.location} ></CmsPage>}>         
      </Route>

      <Route path="/resources" component={ResourcesRouting}></Route>

      <Route exact path="/your-stats" component={PerformanceStats}></Route>
      <Route exact path="/strokes-gained" component={StrokesGained}></Route>
      
      <Route path="/12-Week-Programme" component={TwelveWeekRouting}></Route>
      <Route path="/Video-Lessons" component={VideoLessonRouting}></Route>
      <Route path="/fast-track" component={FastTrackRouting}></Route>

      <Route path="/bonus" render={
         () => {
            if (props.user.roles.includes("BonusUser")) {
               return <BonusPageRouting {...props}></BonusPageRouting>
            } else {
               return <Redirect to="/unauthorized"></Redirect>
            }
         }
      }></Route>

      <Route path="/Locker-Room" component={LockerRoomRouting}></Route>

      <Route path="/dashboard" component={Dashboard}></Route>

      <Route path="/" render={() => <DefaultRouting {...props} default="/dashboard" />} />
   </Switch>
);

export default SecureRouting;