import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import authService from '../services/auth-service';
import * as User from "./user.store";
import * as Admin from "./admin.store";
import * as Community from "./community-post.store";
import * as PerformanceStats from "./performance-stats.store";
import * as TwelveWeek from "./twelve-week.store";
import * as Notifications from "./notifications.store";
import * as Videos from "./video.store";
import * as Cms from "./cms.store";
import * as Resources from "./resources.store";
import * as Search from "./search.store";
import { invokeSignalRHub } from './notifications.middleware';

export default function configureStore(history, initialState) {
  const reducers = {
    users: User.reducer,
    admin: Admin.reducer,
    cms: Cms.reducer,
    search: Search.reducer,
    communityPage: Community.reducer,
    performanceStats: PerformanceStats.reducer,
    twelveWeek: TwelveWeek.reducer,
    notifications: Notifications.reducer,
    videos: Videos.reducer,
    resources: Resources.reducer
  };

  const middleware = [
    thunk, 
    routerMiddleware(history),
    invokeSignalRHub
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === "development";
  if (
    isDevelopment &&
    typeof window !== "undefined" &&
    window.devToolsExtension
  ) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer,
  });

  var store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  authService.configureStore(store);

  return store;
}
