import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";

import analytics from "./analytics-service";
import { refreshCmsPageType } from "../store/cms.store";

import moment from "moment";

const authState = { user: undefined, store: undefined };

const roleType = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

const updateUserType = "UPDATE_USER_RESPONSE";
export {
   updateUserType
};

class AuthService {
   configureStore(store) {       
      authState.store = store;
   }

   getToken() {
      return Cookie.get("token");
   }

   getRefreshToken() {
      return authState.refreshToken;
   }

   getUser() {
      if(!authState.user){
         let token = this.getToken();
         if(token)
         {
            authState.user = buildUser(token);
         }
      }
      return authState.user;   
   }

   setUser(token, refreshToken, setState) {   
      Cookie.set("token", token);

      if (refreshToken) {
         authState.refreshToken = refreshToken;         
      }
      
      authState.user = buildUser(token);

      if (setState) {
         analytics.setUser(authState.user);
         authState.store.dispatch({type: refreshCmsPageType});     
         return authState.store.dispatch({type: updateUserType, user: authState.user, isAuthenticated: true});    
      }
   }

   clearUser() {        
      Cookie.remove("token");
      authState.user = undefined;
      authState.refreshToken = undefined;
      return authState.store.dispatch({type: updateUserType, user: undefined, isAuthenticated: false});
   }
   
   isAuthenticated() {
      var session_token = this.getToken();
      if (!session_token)
      {
         return false;
      }
      
      let expired = checkExpiry(session_token);
      if (expired)
      {
         return false;
      }

      return true;
   }
}

function checkExpiry(token) {
   let decoded = jwt_decode(token);

   var expiry = moment.unix(decoded.exp);   
   return moment.utc().isAfter(expiry);
}

function buildUser(token) {
   let decoded = jwt_decode(token);

   if (!decoded) {
      return;
   }

   var roles = generateClaimArray(decoded[roleType])
   if (!(roles.includes("PPMember") || roles.includes("AcademyMember"))) {
      throw new Error("No valid user role found on user.");
   }

   return {
      userID: decoded.id ? parseInt(decoded.id) : 0,
      emailAddress: decoded.emailAddress,
      displayName: decoded.displayName,
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      coachID: decoded.coachId ? parseInt(decoded.coachId) : 0,
      userGuid: decoded.uid,
      userCmsGuid: decoded.cmsUid,
      avatarId: decoded.avatarId, 
      handicap: decoded.handicap ? Math.round(decoded.handicap) : 0,   
      roles: roles,      
      videoGroups: generateClaimArray(decoded.videoGroups)
   };
}

function generateClaimArray(claim) {
   if (!claim) {
      return [];
   }

   if (Array.isArray(claim)) {
      return claim;
   }

   return [claim];
}

const authService = new AuthService();
export default authService;
