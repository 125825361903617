import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import authService from '../../../../services/auth-service';
import { Form } from "react-bootstrap";

import AnalyticsService from '../../../../services/analytics-service';
import apiService from '../../../../services/api-service';

import {mapGraphData} from '../../../../helpers/graph-data-helper';

export class HandicapGraph extends Component {
  state = {
    posts: [],
    options: ""
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var today = new Date();
    today.setHours(today.getHours() + 1);
   
   AnalyticsService.addEvent("Handicap Tracker", "Stats-Interaction", "Add Handicap");
   
   apiService.post("api/Handicap/AddHandicap", {
    handicapValue: this.state.handicapValue,
    ISID: this.getUserId(),
    dateCreated: today,
  }, true)
   .then((response) => response.json())
    .catch((error) => console.error("Error:", error))
    .then((response) => console.log("Success:", response))
    .then(() => this.fetchHandicaps());
  }

  fetchHandicaps(){
    apiService.get("api/Handicap/GetEveryHandicapByID/" + this.getUserId(), true)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ posts: data, loading: false });
      })
      .catch((error) => {
        return;
      });
  }

  undoHandicap = () => {
    const last = this.state.posts[this.state.posts.length-1];
    if (last && last.handicapID) {   
      AnalyticsService.addEvent("Handicap Tracker", "Stats-Interaction", "Remove Handicap");

      apiService.delete("api/Handicap/" + last.handicapID, true)
        .then(() => {})
        .then(() => {
          const updatedArray = [...this.state.posts];
          updatedArray.pop();
          this.setState({ posts: updatedArray, loading: false });
        });
    }
  };

  getUserId(){
    let user = authService.getUser()
    return user ? user.userID : 0;
  }

  componentDidMount() {
    this.fetchHandicaps();
  }

    times = function (n) {
      return Array.apply(null, new Array(n));
    };

  render() {
    let newLineData = {};
    let simpleLineOptions = {};

    newLineData = mapGraphData(this.state.posts, "dateCreated", "handicapValue");
      
    return (
      <div className="card">
         <div className="card-body">
            <h4 className="card-title">Handicap Tracker</h4>
            <ChartistGraph
            className="ct-perfect-fourth"
            data={newLineData}
            type="Line"
            options={simpleLineOptions}
            />
         </div>

         <div className="card-footer">
            <Form onSubmit={this.handleSubmit}>
            <div className="form-inline">
               <div className="form-group">
                  <input
                  type="text"
                  placeholder="Handicap"
                  value={this.state.handicapValue}
                  onChange={this.handleChange}
                  name="handicapValue"
                  className="form-control"
                  ></input>
               </div>

               <div className="form-group ml-2 mt-2">
                  <button
                  onChange={this.handleClick}
                  className="btn btn-primary"
                  type="submit"
                  >
                  Save
                  </button>
               </div>

               <div className="form-group ml-2 mt-2">
                  {this.state.posts.length > 0
                  && <button type="button" onClick={this.undoHandicap} className="btn btn-primary">Undo</button>
                  }
               </div>
            </div>
            </Form>
         </div>
      </div>
    );
  }
}

export default HandicapGraph;
