import React, {Component} from "react";
import { Row, Col } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../store/admin.store";

class UserRoles extends Component {
  constructor(){
    super();
    this.state = {
      role: {},
      userRole: {}
    }
  }

  addRole() {
    let user = this.props.user;
    let role = this.state.role;
    if (!role.roleId || role.roleId === 0) {
      return;      
    }

    let userRole = user.roles.find(x => x.roleId === role.roleId);
    if (userRole) {
      return;
    }

    user.roles.push(role);
    user.roles = user.roles.sort();

    this.props.onChange("roles", user.roles);
  }

  removeRole() {
    let user = this.props.user;
    let role = this.state.userRole;
    if (!role.roleId || role.roleId === 0) {
      return;      
    }
    
    let userRole = user.roles.find(x => x.roleId === role.roleId);
    if (!userRole) {
      return;
    }

    const index =  user.roles.indexOf(userRole);
    if (index > -1) {
       user.roles.splice(index, 1);
    }
    user.roles = user.roles.sort();
    
    this.props.onChange("roles", user.roles);
  }

  renderRoles() {
    return this.props.roles.map((role, i) =>
      <Row key={i} className={`role-item ${role.roleId === this.state.role.roleId ? "active" : ""}`} onClick={() => this.setState({ role })}>
        {role.roleName}
      </Row>
      );
  }

  renderUserRoles() {
    return this.props.user.roles.map((role, i) =>
      <Row key={i} className={`role-item ${role.roleId === this.state.userRole.roleId ? "active" : ""}`} onClick={() => this.setState({ userRole: role })}>
        {role.roleName}
      </Row>
      );
  }

  render() {
    return (
      <Row className="role-details">
         <Col>
            <Row className="role-titles">
            <Col md={5}>
                  <label>All Roles</label>
            </Col>
            <Col md={1}>
            </Col>
            <Col md={5}>
                  <label>User Roles</label>
            </Col>
            </Row>
            <Row>
            <Col md={5} className="role-list">
               {this.renderRoles()}
            </Col>
            <Col md={1}>
               <Row className="role-button" onClick={() => this.addRole()}>
                  <i className="fa fa-angle-double-right btn btn-primary" aria-hidden="true"></i>
               </Row>
               <Row className="role-button" onClick={() => this.removeRole()}>
                  <i className="fa fa-angle-double-left btn btn-primary" aria-hidden="true"></i>
               </Row>
            </Col>
            <Col md={5} className="role-list">
               {this.renderUserRoles()}
            </Col>                
            </Row>
         </Col>
      </Row>
    );
  }
}

export default connect(
  state => state.admin,
  dispatch => bindActionCreators(actions, dispatch)
)(UserRoles);