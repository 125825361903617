import React, { Component } from "react";

import Sidebar from "../../shared/Sidebar";
import Navbar from "../../shared/Navbar";
import SecureRouting from "../routing/secure-routing.component";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../../store/user.store";

import analytics from "../../services/analytics-service";

class PrivateLayout extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    analytics.addPageView();
  }

  render() {
    return (
      <div className="App">
        <div>
          <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper mt-4">
                <Sidebar />
              <div className="main-panel">
                <div className="content-wrapper">
                  <SecureRouting {...this.props} user={this.props.user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(PrivateLayout);