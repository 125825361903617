import { Component } from 'react'

class GraphSeries extends Component {
  constructor(){
    super();

    this.state = { title: "", series: []};
  }

  componentDidMount(){
    this.setState({title: this.getTitle, series: this.getSeries});
  }

  componentDidUpdate(prevProps){
    if(prevProps.datapoints === this.props.datapoints){
      return;
    }

    this.setState({title: this.getTitle, series: this.getSeries});
  } 

  render() {
    return null;
  };
}


export default GraphSeries