import React, { useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import AnalyticsService from '../../services/analytics-service';
import "react-notifications-component/dist/theme.css";

import ReactPlayer from "react-player";

function ResourcesPuttingTools() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="container p-3">
          <div className="text-left">
            <h4 className="squashie">Putting Tools</h4>
          </div>
          <hr></hr>

          <div className="blog-wrap">
            <div className="container">
              <Row mt={3}>
                <Col xl={6}>
                  <div className="widget widget-categories">
                    <Row mt={3}>
                      <Col md={12}>
                        <a style={{color: 'white'}} target="_blank" rel="noopener noreferrer" 
                          onClick={e => AnalyticsService.addEvent("File Download", "Downloads", "Putting Warm Up Cheat Sheet")}
                          href={require("../../assets/downloads/golf_cheat_sheet.pdf")}>
                        <p>
                          <img className="resourceImage"
                            src={require("../../assets/images/golf-cheat-sheet.png")}
                            alt="golf cheat sheet"
                            />
                        </p>
                        <p style={{
                          background: 'black',
                          color: 'white',
                          padding: '20px',
                          marginBottom: '5px',
                          borderRadius: '5px',
                          textAlign: 'center',
                          cursor: 'pointer'
                        }}>Putting Warm Up Cheat Sheet</p></a>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="widget widget-categories">
                    <Row mt={3}>
                      <a style={{color: 'white'}} target="_blank" rel="noopener noreferrer"  
                          onClick={e => AnalyticsService.addEvent("File Download", "Downloads", "12 Week Practice Journal")}
                          href={require("../../assets/downloads/Phil Kenyon - 12 week practice journal.pdf")} download>
                        <img className="resourceImage"
                          src={require("../../assets/images/Phil ads-11.jpg")}
                          alt="golf cheat sheet"
                        />
                      </a>
                    </Row>
                    <Row mt={3}>
                      <Col md={6}>
                        <a style={{color: 'white'}} target="_blank" rel="noopener noreferrer"  
                          onClick={e => AnalyticsService.addEvent("File Download", "Downloads", "12 Week Practice Journal")}
                          href={require("../../assets/downloads/Phil Kenyon - 12 week practice journal.pdf")} download>
                        <p style={{
                          background: 'black',
                          color: 'white',
                          padding: '20px',
                          marginBottom: '5px',
                          borderRadius: '5px',
                          textAlign: 'center',
                          cursor: 'pointer'
                        }}>12 Week Practice Journal</p></a>
                      </Col>
                      <Col md={6}>
                        <a style={{color: 'white'}} target="_blank" rel="noopener noreferrer"
                          onClick={e => AnalyticsService.addEvent("File Download", "Downloads", "12 Week Practice Journal (Printable)")}
                          href={require("../../assets/downloads/Phil Kenyon 12 week practice journal printable .pdf")} download>
                        <p style={{
                          background: 'black',
                          color: 'white',
                          padding: '20px',
                          marginBottom: '5px',
                          borderRadius: '5px',
                          textAlign: 'center',
                          cursor: 'pointer'
                        }}>12 Week Practice Journal (Printable)</p></a>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                  <div className="col-md-12 mt-4" style={{padding: '10px 4%',border: '2px solid #eeeeee'}}>
                      <div className="col-md-12" align="center"><h3>Performance Stats Webinar</h3></div>
                      <div className="player-wrapper">
                          <ReactPlayer
                              className="react-player"
                              controls={true}
                              width="100%"
                              height="100%"
                              url="https://pro-fitpt-1.wistia.com/medias/soucl0mbf6"
                              playing={false}
                              onReady={() => {
                                  this.setState({ ready: true });
                              }}
                          />
                    </div>
                  </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourcesPuttingTools;
