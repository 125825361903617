import * as SignalR from '@microsoft/signalr';
import authService from "../services/auth-service";
import { config } from "../config";

let rootUrl = config.API_ROOT;

const connection = {
    hub: undefined
};

const connectionState = { connecting: 0, connected: 1, reconnecting: 2, disconnected: 4 };

export const dispatchTypes = {
    connectedResponse : 'NOTIFICATIONS_CONNECTED',
    getNotificationsRequest : 'FETCH_NOTIFICATIONS_REQUEST',
    deleteNotificationsRequest : 'DELETE_NOTIFICATIONS_REQUEST',
    updateNotificationsRequest : 'UPDATE_NOTIFICATIONS_REQUEST',
    updateNotificationsResponse : 'UPDATE_NOTIFICATIONS_RESPONSE',
    refreshNotificationsRequest : 'REFRESH_NOTIFICATIONS_REQUEST',
    refreshNotificationsResponse : 'REFRESH_NOTIFICATIONS_RESPONSE',
    errorNotificationsResponse : 'ERROR_NOTIFICATIONS_RESPONSE'
}

const requests = {
    fetchNotifications: "FetchNotifications",
    deleteNotification: "DeleteNotification",
    deleteNotifications: "DeleteNotifications"
}

const responses = {
    refreshNotifications: "refreshNotifications",
    addNotification: "addNotification",
    notificationsError: "notificationsError"
}

export function invokeSignalRHub(store) {
    return (next) => (action) => {
        if (!connection.hub) {
            newConnection(store);
        }

        if (connection.hub.connectionState === connectionState.connecting
        || connection.hub.connectionState === connectionState.reconnecting) {
            next(action);
        }

        if (connection.hub.connectionState === connectionState.disconnected) {
            startConnection(store);
        }

        switch(action.type){
            case dispatchTypes.getNotificationsRequest:
                connection.hub.invoke(requests.fetchNotifications, true);
                break;
            case dispatchTypes.updateNotificationsRequest:
                connection.hub.invoke(requests.deleteNotification, action.update, true);
                break;
            case dispatchTypes.deleteNotificationsRequest:
                connection.hub.invoke(requests.deleteNotifications, action.updates, true);
                break;
            case dispatchTypes.refreshNotificationsRequest:
                connection.hub.stop().then(() => startConnection(store));
                break;
            default: next(action);
        }
    };
}

function newConnection(store) {
    const token = authService.getToken();

    connection.hub = new SignalR.HubConnectionBuilder().withUrl(`${rootUrl}/hub/notifications`, { 
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token 
    }).build();
    
    connection.hub.on(responses.refreshNotifications, data => {
        store.dispatch({ type: dispatchTypes.refreshNotificationsResponse, data });
    });
    
    connection.hub.on(responses.addNotification, data => {
        store.dispatch({ type: dispatchTypes.updateNotificationsResponse, data });
    });
    
    connection.hub.on(responses.notificationsError, data => {
        store.dispatch({ type: dispatchTypes.errorNotificationsResponse, error: data });
    });

    connection.hub.onclose(() => startConnection(store));

    startConnection(store);
}

function startConnection(store) {
    if (!authService.isAuthenticated()) {
        return;
    }

    connection.hub.start()
    .then(() =>  store.dispatch({ type: dispatchTypes.connectedResponse }))
        .catch(err => store.dispatch({ type: dispatchTypes.errorNotificationsResponse, error: err }));    
}