import React, { Component } from "react";
import { Card } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import Chartist from 'chartist';
import GraphSeries from './graph-series.component';
import {mapLabels} from '../../helpers/graph-data-helper';
import './graph.css';

export default class LineGraph extends Component {
  constructor(){
    super();

    this.state = {
      options: {
        fullWidth: true,
        axisY: {
          onlyInteger: true,
          offset: 20,
        },
        showGrid: true,
        onlyInteger: false,
        chartPadding: {
          right: 40,
        }
      },
      data: {
        labels: [],
        series: []
      }
    };
  }

  componentDidMount(){
    this.update();
  }

  componentDidUpdate(prevProps){
    if(prevProps.children !== this.props.children || prevProps.labels !== this.props.labels){
      this.update();
    }   
  }

  update(){
    let options = this.state.options;
    if(this.props.interpolate){
      options.lineSmooth = Chartist.Interpolation.simple({
        divisor: 2,
        fillHoles: true
      });
    }

    let series = [];

    if(this.props.children instanceof Array)
    {
      let flattened = this.props.children.flat();
      let dataSeries = flattened.filter(x => x && x.type === GraphSeries);  
      series = dataSeries.map(x => this.parseGraphSeries(x));
    } else {
      let dataSeries = this.props.children;
      series.push(this.parseGraphSeries(dataSeries));
    }
    
    this.setState({data: { labels: mapLabels(this.props.labels, "display"), series, options }});
  }
  
  parseGraphSeries(series){
    if(!series.props.datapoints || !this.props.labels)
    {
      return;
    }

    let mapped = this.props.labels.map((l,i) => {
      let datapoint = series.props.datapoints.find(x => x.label === l.value);
      if(!datapoint){
        return null;
      }
      return datapoint.value;
    });

    return { name: series.props.title, data: mapped, className: "dataseries-" + series.props.color };
  }

  renderFooter(){
    if(this.props.children instanceof Array)
    {      
      let flattened = this.props.children.flat();
      var footer = flattened.filter(x => x && x.type !== GraphSeries)
      return footer;
    }
  }

  render() {
    return (
      <Card className={this.props.className}>
        <div className="card-body line-graph">
          <h4 className="card-title">{this.props.title}</h4>
          <ChartistGraph
            className=" ct-perfect-fourth"
            data={this.state.data}
            type="Line"
            options={this.state.options}
            li
          />
        </div>

        <div className="card-footer">
          {this.renderFooter()}
        </div>
      </Card>
    );
  }
}