import moment from 'moment';

function useFromNow(date){
   let currDateTime = moment();
   let dateMoment = moment(date);
 
   return currDateTime.diff(dateMoment, 'days') < 7;
}

function sortAscending(a, b, property){
   return moment(a[property]).format("YYYYMMDDHHmmss") < moment(b[property]).format("YYYYMMDDHHmmss") ? -1 : 1;
}

function sortDescending(a, b, property){
   return moment(a[property]).format("YYYYMMDDHHmmss") > moment(b[property]).format("YYYYMMDDHHmmss") ? -1 : 1
}

export {
   useFromNow,
   sortAscending,
   sortDescending
}