import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../store/twelve-week.store";

import AnalyticsService from '../../../services/analytics-service';
import Question from "./question.component";

import '../twelve-week.page.css';

class TwelveWeekQuestionBlock extends Component {
   constructor(){
      super();
      this.state = {
         notify: false,
         results: []
      }
   }
   
   componentDidMount() {
      window.scrollTo(0, 0);

      if(this.props.results.length === 0){
         this.props.requestResults();
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps.saved !== this.props.saved && this.props.saved) {
         this.setState({ notify: true }, () => {
            setTimeout(() => this.setState({ notify: false }), 2000)
         });
         this.props.requestResults();
      }

      if (this.props.questionGroups !== prevProps.questionGroups) {
         this.props.requestResults();         
      }

      if (this.props.results !== prevProps.results) {
         this.setState({ results: this.props.results });
      }
   }

   onChange(group, result) {
      var request = {
         Id: result.id,
         questionId: result.questionId,
         videoGroupId: result.videoGroupId
      }
      AnalyticsService.addEvent(`Update ${group}`, "12-Week-Update", result.displayName);      
      this.props.addResult(request);
   }

   render(){
      return(
         <Row className="question-block">
            <Col md={12}>
               <Row>
                  <h3>{this.props.heading}</h3>
               </Row>
               <Row>
                  <p>{this.props.text}</p>
               </Row>
               <Row className="question-groups">
                  {this.renderQuestionGroups()}
               </Row>               
            </Col>
            <div className={"question-notify " + (this.state.notify ? "active" : "")}>
               <div className="question-notify-text">Your selection has been saved to your locker room.</div>
            </div>
         </Row>
      )
   }

   renderQuestionGroups() {
      return this.props.questionGroups.map((g, i) => 
         <Col key={i} md={5} className="question-group">
            <Row>
               <h4>{g.title}</h4>
            </Row>
            <Row>
               {this.renderQuestions(g.title, g.questions)}
            </Row>
         </Col>
      );
   }

   renderQuestions(group, questions) {
      return questions.map((q, i) => {
         let result = this.state.results.find(x => x.questionId === q.id);
         return (
            <Col md={12} key={i}>
               <Question key={i} result={result} {...q} onChange={result => this.onChange(group, result)}></Question>
            </Col>
         )
      });
   }
}

export default connect(
   state => state.twelveWeek,
   dispatch => bindActionCreators(actions, dispatch)
 )(TwelveWeekQuestionBlock);