import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import VideoPlayer from '../../components/video/video-player.component';
import VideoList from '../../components/video/video-list.component';

import "../../App.css";

export default class CmsVideoListBlock extends Component {
   constructor(){
      super();
      this.state = {
         video: {}
      }
   }
   
   componentDidMount() {
      window.scrollTo(0, 0)
      
      if (this.props.videoList) {
         let video = this.props.videoList[0];
         this.setState({ video }, () => this.props.onChange(this.props.video ? this.props.video.id : undefined));         
      }
   }

   componentDidUpdate(prevProps) {
      if (this.props.videoList && prevProps.videoList !== this.props.videoList) {
         let video = this.props.videoList.find(x => x.id === this.state.video.id) ?? this.props.videoList[0];

         if (video && video.id !== this.state.video.id) {
            this.setState({ video }, () => this.props.onChange(video.id));            
         }          
      }

      if (this.props.active && prevProps.active !== this.props.active && this.state.video) {
         this.props.onChange(this.state.video.id)
      }
      
      if (prevProps.videoId !== this.props.videoId) {
         this.change(this.props.videoId)
      }
   }

   change(videoId) {
      if (videoId === this.state.video.id) {
         return;
      }

      const video = this.props.videoList.find(x => x.id === videoId);
      if (video) {
         this.setState({ video: video ? video : {} }, () => this.props.onChange(video.id));          
      } 
   }

   render(){
      return(
         <Row className="video-list">
            <Col md={8}>
               {this.state.video && <VideoPlayer url={this.state.video.url}></VideoPlayer>}
            </Col>
            <Col md={4}>
               {this.state.video && <VideoList videos={this.props.videoList} manualNumbering={this.props.manualNumbering} activeVideo={this.state.video.id} onChange={vId => this.change(vId)}></VideoList>}
            </Col>
         </Row>   
      )
   }
}