import React, {Component} from "react";
import { Row, Col } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../../store/user.store";

import {apiErrors} from '../../../services/api-service';
import Popup from '../../../components/popup/popup.component';

const initialState = {
   data: {
      oldPassword: undefined,
      newPassword: undefined,
      confirmPassword: undefined
   },
   isValid: false,
   errorMessage: undefined
};

class ChangePasswordForm extends Component {
  constructor(){
    super();    

    this.state = initialState;
  }
   
   componentDidUpdate(prevProps) {
      if (this.props.passwordError !== prevProps.passwordError && this.props.passwordError) {
         if (this.props.passwordError && this.props.passwordError.status === apiErrors.ERR400.status) {
            this.setState({ isValid: false, errorMessage: "Invalid current password" });
         } else {
            this.setState({ isValid: false, errorMessage: this.props.passwordError ? this.props.passwordError.text : undefined });
         }
      }
      
      if (this.props.userUpdated !== prevProps.userUpdated && this.props.userUpdated) {
         this.closeModal();
      }
   }

  onSave(){
    if(this.validate(this.state.data, true)){
      this.props.updatePassword(this.state.data);
    }
  }

  closeModal(){
    this.setState({...initialState, userId : this.props.user.userId});
    this.props.onClose();
  }

  change(name, value){
    let data = this.state.data;
    data[name] = value.trim();
    this.setState({data}, () => this.validate(false));
  }

   validate(showMessage){
      let data = this.state.data;
      let message = undefined;
      let valid = true;

      if(!data.confirmPassword || data.confirmPassword.length === 0){
         message = "Please enter password confirmation.";
      }

      if(!data.newPassword || data.newPassword.length === 0){
         message = "Please enter new password.";
      }

      if(!data.oldPassword || data.oldPassword.length === 0){
         message = "Please enter current password.";
      }

      if (!message) {
         if(data.newPassword.length < 8){
            message = "New password must be at least 8 characters long.";
         }
      }

      if (!message) {
         if(data.oldPassword === data.newPassword){
            message = "New password cannot be the same as the current password.";
         }
      }

      if (!message) {
         if (!/(?=.{9,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/.test(data.newPassword)) {
            message = "Invalid new password.";            
         }
      }

      if (!message) {
         if(data.confirmPassword !== data.newPassword){
            message = "Password confirmation must be the same as the new password.";
         }
      }

      if(message){
         valid = false;
      }

      this.setState({isValid: valid, errorMessage: showMessage ? message : undefined});
      return valid;
  }

  render() {
    return (
       <Popup
        heading="Change Password"
        className="change-password-dialog"
        show={this.props.show}
        onClose={() => this.closeModal()}
        saveButton="true"
        onSave={() => this.onSave()}
        isValid={this.state.isValid}
        errorMessage={this.state.errorMessage}
      >
          <Row>
            <Col md={5}>
                <label htmlFor="old-password">Old Password</label>
            </Col>
            <Col md={7}>
               <input id="old-password" className="password-input" type="password" value={this.state.oldPassword} onChange={(e) => this.change("oldPassword", e.target.value )}></input>
            </Col>
         </Row>
         <Row>
            <Col md={5}>
               <label htmlFor="new-password">New Password</label>
            </Col>
            <Col md={7}>
               <input id="new-password" className="password-input" type="password" value={this.state.newPassword} onChange={(e) => this.change("newPassword", e.target.value )}></input>
            </Col>
         </Row>
         <Row>
            <Col md={5}>
               <label htmlFor="confirm-password">Confirm Password</label>
            </Col>
            <Col md={7}>
               <input id="confirm-password" className="password-input" type="password" value={this.state.confirmPassword} onChange={(e) => this.change("confirmPassword", e.target.value )}></input>
            </Col>
         </Row>
         <Row>
             <span className="password-caption">Passwords must be at least 8 characters, with at least one character from each of the 4 character classes (alphabetic lower and upper case; numeric, symbols).</span>
         </Row>
      </Popup>
    );
  }
}

export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(ChangePasswordForm);