import React, { Component } from "react";
import { FormGroup } from "react-bootstrap";
import RangeSlider from 'react-bootstrap-range-slider';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

export default class DrillMedian extends Component
{
   constructor(props) {
      super();

      this.state = { custom: true, minRange: props.handicap, maxRange: props.handicap };
   }

   renderOptions() {
      let options = [...this.props.options];

      options.unshift({title: "Custom Handicap"});
      return options.map((o,i) => <option key={i} value={o.value ? JSON.stringify(o.value) : o.title}>{o.title}</option>);
   }

   changeOption(value) {
      if (value === "Custom Handicap") {
         this.setState({ custom: true }, () => this.props.onOptionChange({}));
         return;
      }

      let obj = JSON.parse(value);
      this.setState({ custom: false }, () => this.props.onOptionChange(obj));
   }

   render() {
      return (
         <FormGroup>
            <div className='median-toggle custom-control custom-switch'>
               <input
                  type='checkbox'
                  className='custom-control-input'
                  id={`median-switch-${this.props.id}`}
                  onChange={e => this.props.setActive(e.target.checked)}
                  defaultChecked
               />
               <label className='custom-control-label' htmlFor={`median-switch-${this.props.id}`}>
                  Show handicap {this.props.isMedian ? "medians" : "average"}
               </label>
            </div>
            {this.props.active &&
               <>
                  {this.props.options && this.props.options.length > 0 &&
                     <div className="median-options">
                        <select onChange={e => this.changeOption(e.target.value)}>
                           {this.renderOptions()}
                        </select>
                     </div>
                  }
                  <div>
                        <label htmlFor="min-handicap">Minimum Handicap: </label>
                        <RangeSlider
                           id="min-handicap"
                           value={this.state.minRange}
                           tooltip="on"
                           tooltipPlacement="bottom"
                           tooltipLabel={() => getToolTip(this.state.minRange)}
                           onChange={e => {
                              let range = parseInt(e.target.value)
                              range = range > this.state.maxRange ? range : this.state.maxRange;
                              this.setState({ minRange: e.target.value, maxRange: range });
                           }}
                           onAfterChange={e => this.props.onHandicapChange(this.state.minRange, this.state.maxRange)}
                           min={0}
                           max={50}
                           disabled={!(this.props.active && this.state.custom)}
                        ></RangeSlider>
                        <label htmlFor="min-handicap">Maximum Handicap: </label>
                        <RangeSlider
                           id="max-handicap"
                           value={this.state.maxRange}
                           tooltip="on"
                           tooltipPlacement="bottom"
                           tooltipLabel={() => getToolTip(this.state.maxRange)}
                           onChange={e => {
                              let range = parseInt(e.target.value);
                              range = range < this.state.minRange ? range : this.state.minRange;
                              this.setState({ maxRange: e.target.value, minRange: range });
                           }}
                           onAfterChange={e => this.props.onHandicapChange(this.state.minRange, this.state.maxRange)}
                           min={0}
                           max={50}
                           disabled={!(this.props.active && this.state.custom)}
                        ></RangeSlider>
                     </div>
               </>
            }
         </FormGroup>
      )
   }
}

function getToolTip(range) {
   if (range.toString() === "50") {
      return "50+";
   }

   return range.toString();
}