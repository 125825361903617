import React, { Component } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../store/user.store";
import { postActions } from "../../store/community-post.store";

import Timeline from '../../components/timeline/timeline.component';
import CommunityPost from './components/community.post.component';
import CommunityFullPost from './components/community.full-post.component';
import CommunityNewPost from './components/community.new-post.component';

import './community.page.css';

class CommunityPage extends Component { 
  constructor(){
    super();
    
    this.state = {
      currentVideoId: null,
      contentRef: React.createRef(),
      step: 20,
      newPost: false
    }
  }

  componentDidMount(){
    if(!this.props.user){
      this.props.fetchUser();
    }
    
    this.props.requestPosts(this.state.step);
  }

  componentDidUpdate(prevProps){
    if(prevProps.posts !== this.props.posts){
      this.redirectToPost()
    }
  }

  loadPosts()
  {
    this.props.requestPosts(this.state.step);
  }

  redirectToPost(){
    let params = new URLSearchParams(this.props.location.search);
    let ref = params.get("reference");
    let selected = this.props.posts.find(x => x.reference === ref);

    if(selected){
      this.openPostModal(ref);
    }
  }

  render() {
    return (
      <>
        <Container className="community-page p-5">
          <Row className="title-row">
            <Col md={12}>                
                <b><h2 className="h1 txt--upper txt--spacing rich-text__heading--background"><span> Your Community </span></h2></b>
            </Col>
          </Row>
          <Row>
          </Row>
          {
            this.props.user.coachID > 0 &&
            <Row>
              <Card className="start-post-panel" onClick={() => this.openNewPostModal()}>
                  <i className="icon-pencil"><span className="start-post-text">Start a post</span></i> 
              </Card>
            </Row>
          }
          <Row className="page-content">
              <Timeline load={() => this.loadPosts()} isLoading={this.props.isLoading.posts} height="75vh">
                {this.renderPosts()}
              </Timeline>
          </Row>
        </Container>
          {
            this.props.user.coachID > 0 &&
            <CommunityNewPost user={this.props.user} show={this.state.newPost} onClose={() => this.closeNewPostModal()}></CommunityNewPost>
          }
          {this.state.activePost &&
            <CommunityFullPost currentUser={this.props.user} onClose={() => this.closePostModal()} {...this.state.activePost}></CommunityFullPost>            
          }
      </>
    );
  }

  openPostModal(ref){
    let currPost = this.props.posts.find(x => x.reference === ref);
    let newUrl = window.location.origin + window.location.pathname + `?reference=${ref}`;
    window.history.pushState({path:newUrl},'',newUrl);

    this.setState({ currentVideoRef: null, activePost: currPost, show: true});
  }

  closePostModal(){
    let newUrl = window.location.origin + window.location.pathname;
    window.history.pushState({path:newUrl},'',newUrl);

    this.setState({ activePost: null, show: false });
  }

  openNewPostModal(){
    this.setState({ newPost: true});
  }

  closeNewPostModal(){
    this.setState({ newPost: false });
  }
  
  renderPosts(){
    let items = this.props.posts;

    if(!items || items.length === 0){
      return null;
    }
    return items.map((v, i) => {
    return (<CommunityPost 
        key={i} 
        date={v.createdDate}
        playing={this.state.currentVideoId === v.reference} 
        onClick={() => this.openPostModal(v.reference)}
        onPlay={() => this.setState({ currentVideoId: v.reference })}
        {...v}
      ></CommunityPost>)
    });
  }
}

export default connect(
  state => { 
    const { users, communityPage } = state;
    return {...users, ...communityPage }    
},
  dispatch => bindActionCreators(Object.assign({}, userActions, postActions), dispatch)
)(CommunityPage);
