import React, { Component } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import CmsTabPage from '../tab-page.component';

export default class TabBlock extends Component { 
  constructor(){
    super();
    
    this.state = {activeTab:"home", currentVideo: undefined}
  }

  componentDidMount(){
    this.setState({ activeTab: this.props.activeTab })
    
    let params = new URLSearchParams(this.props.location.search);
    let tabId = params.get("tabId");
    let videoId = params.get("videoId");

    tabId = tabId 
      ? tabId 
      : videoId 
        ? this.findTab(videoId) 
        : this.props.activeTab;

    if(tabId && tabId !== this.state.activeTab){
      this.setState({activeTab: tabId});
    }
  }

  changeTab(tabId) {
    this.setState({ activeTab: tabId, currentVideo: undefined });
  }
  
  changeVideo(videoId) {
    if (this.state.currentVideo === videoId) {
      return;
    }

    this.setState({ currentVideo: videoId }, () => this.props.onChangeVideo(videoId));
  }

  findTab(videoId){
    if(!videoId)
      return undefined;

    let tabs = this.props.content.filter(tab => findNestedVideo(tab.content, videoId));
    if(tabs.length === 0)
      return undefined;

    return tabs[0].id;
  }

  renderTabs(){
     let content = this.props.content;
     if(content.length === 0){
        return;
     }

     return content.map((c,i) => 
      <Tab key={i} eventKey={c.id} title={c.tabHeading} tabClassName="none">
         <CmsTabPage
           active={this.state.activeTab === c.id}
           location={this.props.location}
           mapper={this.props.mapper}
           videoId={this.state.currentVideo}
           onChangeVideo={videoId => this.changeVideo(videoId)}
           textValues={this.props.textValues}
           {...c}></CmsTabPage>
      </Tab>
     );
  }

  render() {
    return (
      <Tabs 
         className={"cms-block tab-block " + (this.props.className ? this.props.className : "")}
         activeKey={this.state.activeTab}
         onSelect={(tabId) => this.changeTab(tabId)}
       >
          {this.renderTabs()}
       </Tabs>
    );
  }
}

function findNestedVideo(content, videoId){
  return content.filter(block => findVideo(block, videoId)).length > 0;
}

function findVideo(block, videoId) { 
  switch(block.blockType){
     case "video": return block.id === videoId;
     case "video-list": return findNestedVideo(block.videoList, videoId);
     case "video-list-item": return block.id === videoId;
     case "tab": return findNestedVideo(block.content, videoId);
     case "columns": return findNestedVideo(block.content, videoId);
     case "column": return findNestedVideo(block.content, videoId);
     default: return false;
  }
}
