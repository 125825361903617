import React, { Component } from "react";
import { Container, Row } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../store/resources.store";

import DropboxFolder from './dropbox.folder.component';
import DropboxFile from './dropbox.file.component';
import Loader from '../../components/loader/loader.component';

import './dropbox.css';

class Dropbox extends Component {
   constructor(){
      super();

      this.state = {
      }
   }

   componentDidMount() {
      this.props.requestDropboxFolder(this.props.folderPath);
   }

   componentDidUpdate(prevProps) {
      if (prevProps.folder !== this.props.folder && prevProps.folder.path !== "/") {         
         this.setState({prev: prevProps.folder.path})         
      }
   }

   changePath(path) {
      this.props.requestDropboxFolder(path);
   }

   render() {
      return (
         <Container className="dropbox-container">
            <Row className="title">
               <h2 className="h3 txt--upper txt--spacing rich-text__heading--background"><span>{this.props.folder.name}</span></h2>
            </Row>
            <Row className="path">
               <i className={`fa fa-arrow-circle-left back ${this.state.prev ? "" : "disabled"}`} onClick={() => this.state.prev ? this.changePath(this.state.prev) : null}></i>
               {this.renderPath()}
            </Row>
            <Row className="items">
               <Loader active={this.props.isLoading}></Loader>
               <div className="item-list">
                  {this.renderItems()}  
               </div>
            </Row>
         </Container>
      );
   }

   renderPath() {
      let pathArray = this.props.folder.path.split('/');
      pathArray.shift();

      let path = "";
      let currentIndex = pathArray.length - 1;
      return pathArray.map((p, i) => <span key={i} className={`path-item ${i === currentIndex ? "current" : ""}`} onClick={() => i < currentIndex ? this.changePath(`${path}/${p}`) : undefined}>/{p}</span>);
   }

   renderItems() {
      let folders = this.props.folder.folders.map((f, i) => <DropboxFolder key={f.id} {...f} onClick={() => this.changePath(f.path)}></DropboxFolder>);
      let files = this.props.folder.files.map((f, i) => <DropboxFile key={f.id} {...f}></DropboxFile>);

      return [...folders, ...files];
   }
}

export default  connect(
   state => state.resources,
   dispatch => bindActionCreators(actions, dispatch)
 )(Dropbox);