import React from 'react';
import ReactPlayer from "react-player";


export default props => {
   return (
      <div className="player-wrapper">
         <ReactPlayer
            ref={props.ref}
            className="react-player"
            controls={true}
            width="100%"
            height="100%"
            url={props.url}
            playing={props.playing}
         />
      </div>
   )
}