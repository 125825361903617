import React, { Component } from "react";
import InsecureRouting from '../routing/insecure-routing.component';

export default class PublicLayout extends Component {
   render() {
      return (
         <div>
            <InsecureRouting {...this.props} />
         </div>
      )
   }
}