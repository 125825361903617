import React from 'react';
import ReactPlayer from "react-player";
import { Row, Col } from 'react-bootstrap';

export default (props) => (
   <Row  className={"cms-block video-list-item-block " + (props.className ? props.className : "")}>
      <Col md={3} mt={4}>
         <div className="player-wrapper">
         <ReactPlayer
            className="react-player"
            controls={true}
            width="100%"
            height="100%"
            url={props.url}
            playing={props.playing}
            onPause={props.onPause}
            onStart={props.onStart}
         />
         </div>
      </Col>
      <Col md={9} mt={8}>
         <div className="video-title rich-text__heading--background"><h3><span>{props.title}</span></h3></div>
         <Row className="video-text" dangerouslySetInnerHTML={{ __html: props.text }} ></Row>
         {props.duration && 
            <Row >Duration: {props.duration}</Row>     
         }                
      </Col>
   </Row>
);