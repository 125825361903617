import apiService from '../services/api-service';

const requestDrillsType = "FETCH_DRILLS_PENDING";
const receiveDrillsType = "FETCH_DRILLS_SUCCESS";
const errorDrillsType = "FETCH_DRILLS_ERROR";

const requestLeaderboardType = "FETCH_LEADERBOARD_PENDING";
const receiveLeaderboardType = "FETCH_LEADERBOARD_SUCCESS";
const errorLeaderboardType = "FETCH_LEADERBOARD_ERROR";

const requestStrokesType = "FETCH_STROKES_PENDING";
const receiveStrokesType = "FETCH_STROKES_SUCCESS";
const errorStrokesType = "FETCH_STROKES_ERROR";


export const constants = {
  LeaderboardStep: 50
}

export const drillSeries = {
  drills: ["Val5", "Val10", "Val15"],
  medians: ["Med5", "Med10", "Med15"],
  hundred: ["Val100"],
  percentOfTwenty: ["Per20"]
}

const initialState = {
  leaderboard: { loading: false, maxStep: constants.LeaderboardStep, count: 0, lines: [], winner: { hundred: 0, strokes: 0 } },
  drills: { loading: false, series: [], handicaps: { min: 0, max: 100 } },
  medians: { loading: false, series: [], handicaps: { min: 0, max: 100 } },
  hundred: { loading: false, series: [], handicaps: { min: 0, max: 100 }, updated: false },
  percentOfTwenty: { loading: false, series: [], handicaps: { min: 0, max: 100 }, updated: false },
  strokes: { loading: false, series: [], handicaps: { min: 0, max: 100 } }
};

export const actions = {
  requestDrills: (min, max, drillType) => (dispatch, getState) => fetchDrills(min, max, drillType, dispatch, getState().performanceStats),
  addDrills: (drills, drillType) => (dispatch, getState) => addDrills(drills, drillType, dispatch, getState().performanceStats),
  removeDrills: (iteration, drillType) => (dispatch, getState) => deleteDrills(iteration, drillType, dispatch, getState().performanceStats),
  requestStrokes: (min, max) => (dispatch, getState) => fetchStrokes(min, max, dispatch, getState().performanceStats),
  removeStrokes: (strokeId) => (dispatch, getState) => deleteStrokes(strokeId, dispatch, getState().performanceStats),
  requestLeaderboard: (max) => (dispatch, getState) => fetchLeaderboard(max, dispatch, getState().performanceStats),
  addLeaderboard: (score, handicap) => (dispatch, getState) => addLeaderboard(score, handicap, dispatch, getState().performanceStats),
  removeLeaderboard: () => (dispatch, getState) => deleteLeaderboard(dispatch, getState().performanceStats)
};

function fetchLeaderboard(max, dispatch, state) {
  if (state.leaderboard.loading === true) {
    return;
  }

  dispatch({ type: requestLeaderboardType, max });

  let url = `api/performance-stats/leaderboard?max=${max}`;
  apiService.get(url, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveLeaderboardType, leaderboard: result });
      },
      (data) => {
        dispatch({ type: errorLeaderboardType, errorData: data });
      }
    );
}

function addLeaderboard(score, handicap, dispatch, state) {
  if (state.leaderboard.loading === true)
    return;

  let max = state.leaderboard.maxStep;

  dispatch({ type: requestLeaderboardType, max });

  let url = `api/performance-stats/leaderboard?max=${max}`;
  apiService.post(url, { score, handicap }, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveLeaderboardType, leaderboard: result });
      },
      (data) => {
        dispatch({ type: errorLeaderboardType, errorData: data });
      }
    );
}

function deleteLeaderboard(dispatch, state) {
  if (state.leaderboard.loading === true)
    return;

  let max = state.leaderboard.maxStep;

  dispatch({ type: requestLeaderboardType, max });

  let url = `api/performance-stats/leaderboard?max=${max}`;
  apiService.delete(url, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveLeaderboardType, leaderboard: result });
      },
      (data) => {
        dispatch({ type: errorLeaderboardType, errorData: data });
      }
    );
}

function fetchDrills(min, max, drillType, dispatch, state) {
  if (state[drillType].loading === true)
    return;

  dispatch({ type: requestDrillsType, drillType, min, max });

  let url = `api/performance-stats/drills?min=${min}&max=${max}&${parseDrillTypes(drillSeries[drillType])}`;
  apiService.get(url, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveDrillsType, drillType, series: result });
      },
      (data) => {
        dispatch({ type: errorDrillsType, drillType, errorData: data });
      }
    );
}

function deleteDrills(drillGroup, drillType, dispatch, state) {
  if (state[drillType].loading === true)
    return;

  dispatch({ type: requestDrillsType, drillType });

  let min = state[drillType].handicaps.min;
  let max = state[drillType].handicaps.min;

  let url = `api/performance-stats/drills/${drillGroup}?min=${min}&max=${max}&${parseDrillTypes(drillSeries[drillType])}`;
  apiService.delete(url, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveDrillsType, drillType, series: result });
      },
      (data) => {
        dispatch({ type: errorDrillsType, drillType, errorData: data });
      }
    );
}

function addDrills(drills, drillType, dispatch, state) {
  if (state[drillType].loading === true)
    return;

  let min = state[drillType].handicaps.min;
  let max = state[drillType].handicaps.min;

  dispatch({ type: requestDrillsType, drillType, min, max });

  let url = `api/performance-stats/drills?min=${min}&max=${max}&${parseDrillTypes(drillSeries[drillType])}`;
  apiService.post(url, drills, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveDrillsType, drillType, series: result });
      },
      (data) => {
        dispatch({ type: errorDrillsType, drillType, errorData: data });
      }
    );
}

function fetchStrokes(min, max, dispatch, state) {
  if (state.strokes.loading === true)
    return;

  dispatch({ type: requestStrokesType, min, max });

  let url = `api/performance-stats/strokes?min=${min}&max=${max}`;
  apiService.get(url, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveStrokesType, strokes: result });
      },
      (data) => {
        dispatch({ type: errorStrokesType, errorData: data });
      }
    );
}

function deleteStrokes(strokesId, dispatch, state) {
  if (state.strokes.loading === true)
    return;

  dispatch({ type: requestStrokesType });

  let min = state.strokes.handicaps.min;
  let max = state.strokes.handicaps.min;

  let url = `api/performance-stats/strokes/${strokesId}?min=${min}&max=${max}`;
  apiService.delete(url, true)
    .then(resp => resp.json())
    .then(
      (result) => {
        dispatch({ type: receiveStrokesType, strokes: result });
      },
      (data) => {
        dispatch({ type: errorStrokesType, errorData: data });
      }
    );
};

function parseDrillTypes(types) {
  return types.map(v => `types=${v}`).join("&");
}

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestDrillsType) {
    let actionState = { ...state, errorData: null };
    actionState[action.drillType] = { ...state[action.drillType], loading: true, handicaps: { min: action.min, max: action.max } };
    return actionState;
  }

  if (action.type === receiveDrillsType) {
    let actionState = { ...state, errorData: null, leaderboard: { ...state.leaderboard, updated: false } };
    actionState[action.drillType] = { ...state[action.drillType], loading: false, series: action.series };
    return actionState;
  }

  if (action.type === errorDrillsType) {
    let actionState = { ...state, errorData: action.errorData };
    actionState[action.drillType] = { ...state[action.drillType], loading: false };
    return actionState;
  }

  if (action.type === requestLeaderboardType) {
    return { ...state, leaderboard: { ...state.leaderboard, loading: true, maxStep: action.max }, errorData: null };
  }

  if (action.type === receiveLeaderboardType) {
    return { ...state, leaderboard: { ...state.leaderboard, loading: false, ...action.leaderboard, updated: true } };
  }

  if (action.type === errorLeaderboardType) {
    return { ...state, leaderboard: { ...state.leaderboard, loading: false }, errorData: action.errorData };
  }


  if (action.type === requestStrokesType) {
    return { ...state, strokes: { ...state.strokes, loading: true, handicaps: { min: action.min, max: action.max } }, errorData: null };
  }

  if (action.type === receiveStrokesType) {
    return { ...state, strokes: { ...state.strokes, loading: false, series: action.strokes } };
  }

  if (action.type === errorStrokesType) {
    return { ...state, strokes: { ...state.strokes, loading: false }, errorData: action.errorData };
  }

  return state;
}