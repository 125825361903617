import React, {Component} from "react";
import { Modal, Container } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/notifications.store";

import NotificationsList from './notifications.list';

import "./notifications.css";

class Notifications extends Component {
  constructor(){
    super();    

    this.state = {
      show: false
    }
  }

  componentDidMount(prevProps) {
      this.props.refreshNotifications(); 
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.userGuid !== prevProps.user.userGuid) {
      this.props.refreshNotifications();      
    }
  }

  toggleState(){
    this.setState({show: !this.state.show});
  }

  closeModal(){
    this.setState({show: false});
  }

  render() {
    return (
       <>
         {(this.props.notifications && this.props.notifications.length > 0) &&
         <div className="notification" >
            <div className="notification-icon">
              <div className="notifications-indicator" onClick={() => this.toggleState()}>
                <div className="notifications-count">{this.props.notifications.length}</div>
              </div>               
            </div>
         </div>
         }
         <Modal className="notifications-dialog" show={this.state.show} onHide={() => this.closeModal()}>  
             <Modal.Header closeButton><Modal.Title>Notifications</Modal.Title></Modal.Header>
          <Modal.Body>            
            <Container className="notifications-list">
               <NotificationsList onClear={() => this.closeModal()}></NotificationsList>
            </Container>              
          </Modal.Body>
         </Modal>
      </>
    );
  }
}

export default connect(
  state => { 
    const { users, notifications } = state;
    return {...users, ...notifications }    
},
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Notifications);