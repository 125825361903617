import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import moment from "moment";

import TimeLineItem from './timeline.item.component'
import TimeLineSeparator from './timeline.separator.component'
import InfiniteScroll from 'react-infinite-scroll-component';

import './timeline.css';

export default class Timeline extends Component {
  constructor(){
    super();

    this.state = {items: []};
  }

  render() {
    return (
      
      <Container className="timeline-container">
        <InfiniteScroll
          className="timeline-scroll"
          dataLength={this.state.items.length} //This is important field to render the next data
          next={(e) => this.props.load(e)}
          hasMore={true}
          loader={this.props.isLoading && <h4>Loading...</h4>}
          height={this.props.height}
        >
          {this.state.items}
        </InfiniteScroll>
      </Container>
    );
  }

  componentDidUpdate(prevProps){
    if(this.props.children === prevProps.children){
      return;
    }

    var items = this.renderItems();
    this.setState({items});
  }

  renderItems(){
    let items = this.props.children;

    if(!items || items.length === 0){
      return null;
    }

    let separators = items.reduce((a, s) => {
      if(!a){
        a = { keys:[], values: [] };
      }

      let dateMoment = moment(s.props.date);
      if(!a.keys.includes(dateMoment.format('YYYYMM')))
      {
        a.keys.push(dateMoment.format('YYYYMM'));
        a.values.push(dateMoment);
      }
      return a;
    }, { keys:[], values: [] }).values
    .map((s, i) => <TimeLineSeparator key={i+1} dateKey={s.format('YYYYMM') + '99999999'} date={s}></TimeLineSeparator>);

    let mappedItems = items.map((v, i) => <TimeLineItem key={i*1000} dateKey={moment(v.props.date).format('YYYYMMDDHHmmss')}>{v}</TimeLineItem>);

    var output = [...separators, ...mappedItems];
    return output.sort((a,b )=> a.props.dateKey > b.props.dateKey ? -1 : 1);
  }
}