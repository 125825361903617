import React, {Component} from "react";
import { Container, Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import {Editor} from '@tinymce/tinymce-react';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { postActions } from "../../../store/community-post.store";

import Popup from '../../../components/popup/popup.component';

import UserImage from '../../../components/user-image/user-image.component';
import DropZone from '../../../components/drop-zone/drop-zone.component';
import UrlInput from './post.url.input.component';

const initialState = {
  data: {
    tags: [],
    title: undefined,
    comment: undefined,
    url: undefined,
    contentType: "text",
    isUrlPost: false,
    asset: undefined
  },
  isValid: false,
  urlPlaying: false,
  videoPlaying: false,
  errorMessage: undefined,
  tagInputRef: React.createRef(),
};

class CommunityNewPost extends Component {
  constructor(){
    super();    

    this.state = initialState;
  }

  componentDidMount(){
    this.setState({userId : this.props.user.userId});
  }

  componentDidUpdate(prevProps) {
      if (this.props.postCreated !== prevProps.postCreated && this.props.postCreated) {
         this.closeModal();
      }    
  }

  onSave(){
    if(this.validate(this.state.data, true)){
      this.props.createPost(this.state.data);
    }
  }

  closeModal(){
    this.setState({...initialState, userId : this.props.user.userId});
    this.props.onClose();
  }

  setTab(k){
    let data = {...this.state.data, url: undefined, asset: undefined};
    switch(k){
      case "image":
        data.isUrlPost = false;
        data.contentType = "image"
        break;
      case "video":
        data.isUrlPost = false;
        data.contentType = "video"
        break;
      case "image-url":
        data.isUrlPost = true;
        data.contentType = "image"
        break;
      case "video-url":
        data.isUrlPost = true;
        data.contentType = "video"
        break;
      default:
        data.isUrlPost = false;
        data.contentType = "text"
    }
    this.setState({data, urlPlaying: false, videoPlaying: false}, () => this.validate(false));
  }

  change(name, value){
    let data = this.state.data;
    data[name] = value;
    this.setState({data}, () => this.validate(false));
  }

  validate(showMessage){
    let data = this.state.data;
    let message = undefined;
    let valid = true;

    if(!data.title || data.title.trim().length === 0){
      message = "Missing title.";
    }

    if(data.contentType === "text"){
      if(!data.comment || data.comment.trim().length === 0){
        message = "Text post requires content.";
      }
    } else if(data.isUrlPost){
      if(!data.url){
        message = "Url post requires a valid url for the content type to be entered.";
      }
    } else if(data.contentType === "image" || data.contentType === "video") {
      if(!data.asset){
        message = "File posts require a selected file.";
      }
    }
    if(message){
      valid = false;
    }
    
    this.setState({isValid: valid, errorMessage: showMessage ? message : undefined});
    return valid;
  }

  addTag(){
    let tagVal = this.state.tagInputRef.current.value.trim();
    if(tagVal.length === 0){
      return;
    }

    let tags = this.state.data.tags;
    let existing = tags.find(x => x === tagVal);
    if(!existing){
      tags.push(tagVal);
      this.setState({data: {...this.state.data, tags }});
    }
    var tagInputRef = this.state.tagInputRef;
    tagInputRef.current.value = "";

    this.setState({ tagInputRef });
  }

  removeTag(tag){
    let tags = this.state.data.tags;
    let index = tags.indexOf(tag);

    tags.splice(index, 1);
    this.setState({data: {...this.state.data, tags }});
  }

  render() {
    return (
      <Popup 
        className="community-page-dialog"
        show={this.props.show}
        onClose={() => this.closeModal()}
        saveButton="true"
        onSave={() => this.onSave()}
        isValid={this.state.isValid}
        errorMessage={this.state.errorMessage}
      >
        <Container className="community-new-post-item">
          <Row className="community-post-header">
            <Col md={2}>
              <UserImage avatarId={this.props.user.avatarId} className="img-md rounded-circle" />
              <Row className="item-posted-by">{this.props.user.displayName}</Row>
            </Col>
            <Col className="community-post-actor" md={10}>
              <Row className="community-post-title">
                  <input type="text" placeholder="Title" onChange={e => this.change("title", e.target.value)}></input>
              </Row>
              <Row className="community-search-tags">
                {this.state.data.tags.map((v, i) => <span key={i} className="community-search-tag">{v} <span className="community-search-tag_close" onClick={() => this.removeTag(v)}>x</span></span>)}
              </Row>
              <Row className="community-add-tags">
                <input ref={this.state.tagInputRef} className="post-tag-input" type="text" placeholder="Search Tag" />  
                <Button onClick={() => this.addTag()}>Add Search Tag</Button>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="text" id="post-content-tabs"
                onSelect={(k) => this.setTab(k)}
              >
                <Tab eventKey="text" title="Text">
                </Tab>
                <Tab eventKey="image-url" title="Image Url">
                  <UrlInput contentType="image" 
                    onChange={url => this.change("url", url)} 
                    contentSrc={this.state.data.url}
                    onError={error => this.setState({errorMessage: error})}
                  ></UrlInput>
                </Tab>
                <Tab eventKey="video-url" title="Video Url">
                  <UrlInput contentType="video" 
                    onChange={url => this.change("url", url)}
                    contentSrc={this.state.data.url}
                    playing={this.state.urlPlaying}
                    onError={error => this.setState({errorMessage: error})}
                  ></UrlInput>
                </Tab>
                <Tab eventKey="image" title="Image">
                  <div className="community-post-content">
                    <DropZone contentType="image" 
                      onDrop={e => this.change("asset", e)}
                      asset={this.state.data.asset}
                      onError={error => this.setState({errorMessage: error})}
                    ></DropZone>
                  </div>
                </Tab>
                <Tab eventKey="video" title="Video">
                  <div className="community-post-content">
                    <DropZone contentType="video"
                      onDrop={e => this.change("asset", e)}
                      playing={this.state.videoPlaying}
                      asset={this.state.data.asset}
                      onError={error => this.setState({errorMessage: error})}
                    ></DropZone>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <Row className="content-text">
            <Editor className="content-editor"
              init={{
                menubar: false,
                plugins: [
                  'advlist autolink lists link charmap emoticons preview anchor',
                  'searchreplace fullscreen',
                  'insertdatetime table paste help wordcount'
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  charmap emoticons | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              onChange={e => this.change("comment", e.target.getContent())}
            >      
            </Editor>
          </Row>
        </Container>
      </Popup>
    );
  }
}

export default connect(
  state => state.communityPage,
  dispatch => bindActionCreators(postActions, dispatch)
)(CommunityNewPost);