import React from "react";
import { Row, Col } from 'react-bootstrap';

import UserImage from "../../../../components/user-image/user-image.component";
import Moment from 'react-moment';

export default function(props) {
   return (
      <Row ref={props.focusRef} className={`leaderboard-item ${props.current ? "active" : ""}`}>
         <Col xs={1}>{props.position}</Col>
         <Col xs={2} className="display-center"><UserImage className="leaderboard-avatar" avatarId={props.avatarId}></UserImage></Col>
         <Col xs={2}>{props.displayName}</Col>
         <Col xs={2} className="display-center">{props.handicap}</Col>
         <Col xs={2} className="display-center">{props.score}</Col>
         <Col xs={2}><Moment fromNow>{props.dateUpdated}</Moment></Col>
      </Row>
   )
}