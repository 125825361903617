import React from "react";
import { Row } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';

export default props => {
   return (
     <Row className="timeline-separator-item">
        <Moment date={props.date} format="MMMM YYYY" />
     </Row>
   );
}