function mapGraphData(data, dateField, valField){
    let maxLabels = 5;
    let divider = parseInt(data.length / maxLabels);
    let labelCount = 0;
    let labels = [];
    let series = [];

    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    data.map((post) => {
      let label = "";
      if(data.length <= maxLabels || labelCount % divider == 0)
      {
        label = new Date(post[dateField]).toLocaleDateString("en-GB", options);
      }
      labels.push(label);

      labelCount++;
      series.push(post[valField]);
    });

    let graphData = {
      labels,
      series: [series]
    }
    return graphData;
  }

  function mapLabels(data, dateField){
    let maxLabels = 5;
    let divider = parseInt(data.length / maxLabels);
    let labelCount = 0;
    let labels = [];

    data.map((post) => {
      let label = "";
      if(data.length < maxLabels || labelCount % divider == 0)
      {
        label = post[dateField];
      }
      labels.push(label);

      labelCount++;
    });

    return labels;
  }

  export {
    mapGraphData,
    mapLabels
  }
