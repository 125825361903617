import React, { Component } from "react";
import { Col } from 'react-bootstrap';

import { mapBlocks } from "../helpers/cms-block-helper";

export default class CmsColumnBlock extends Component { 
  constructor(){
    super();
    
    this.state = {
      currentVideo: undefined
    }
  }
  
  componentDidMount(){
    let params = new URLSearchParams(this.props.location.search);
    let videoId = params.get("videoId");

    if(videoId && videoId !== this.state.currentVideo){
      this.changeVideo(videoId);
    }
  }
  
  changeVideo(videoId) {
    if (this.state.currentVideo === videoId) {
      return;
    }

    this.setState({ currentVideo: videoId }, () => this.props.onChangeVideo(videoId));
  }

  render() {
    const width = 12 / this.props.columns.length;

    return (
      <Col sm={12} md={width} className="cms-column">
          {this.renderBlocks()}
      </Col>
    );
  }

  renderBlocks(){
     let blocks = this.props.content;
     return mapBlocks(blocks, this, this.props.mapper);
  }
}
