import React, {Component} from "react";
import { Button, Modal, Row } from 'react-bootstrap';

import './popup.css';


export default class Popup extends Component {
   constructor(){
      super();
      this.state = {show: false, isValid: true};
   }

   componentDidMount(){
      if(this.state.show !== this.props.show){
         this.setState({show: this.props.show});
      }
   }

   componentDidUpdate(prevProps){
      if(prevProps.show !== this.props.show){
         this.setState({show: this.props.show});
      }
      
      if(this.state.isValid !== this.props.isValid){
         this.setState({isValid: this.props.isValid});
      }
   }

   open() {
      this.setState({show: true});
   }

   close() {
      if(this.props.onClose){
         this.props.onClose();
      }
      this.setState({show: false});
   }

   save() {
      if(this.props.onSave){
         this.props.onSave();
      }
   }
 
   render(){
      return (
         <Modal className={this.props.className} show={this.state.show} onHide={() => this.close()}>  
            <Modal.Header closeButton>
            <Modal.Title>{this.props.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {this.props.children}  
            </Modal.Body>
            <Modal.Footer>           
               <Row className="error-message-row">
                  {this.state.isValid === false &&
                     <div className="pop-up-error-message"><i className="icon-exclamation-triangle"></i>{this.props.errorMessage}</div>
                  }
               </Row>
               <Row>
                  <Button variant="secondary" onClick={() => this.close()}>
                     {this.props.closeButtonText ? this.props.closeButtonText : "Close"}
                  </Button>
                  {this.props.saveButton && 
                     <Button variant="primary" onClick={() => this.save()} disabled={this.props.disabled}>
                        {this.props.saveButtonText ? this.props.saveButtonText : "Save Changes"}
                     </Button>
                  }
               </Row>
            </Modal.Footer>
         </Modal>
      );
   }
 }