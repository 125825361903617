import React, {Component} from "react";
import { Image } from 'react-bootstrap';

import ReactPlayer from "react-player";

export default class UrlInput extends Component {
   constructor(){
     super();    
 
     this.state = {
       contentSrc: undefined,
       playing: false,
       inputRef: React.createRef(),
       isValid: false
     };
   }

   componentDidUpdate(prevProps){
      if(prevProps.playing !== this.props.playing){
         this.setState({playing: this.props.playing});
      }
      if (prevProps.contentSrc !== this.props.contentSrc) {
         let inputRef = this.state.inputRef;
         inputRef.current.value = this.props.contentSrc ? this.props.contentSrc : "";
         this.setState({inputRef})
      }
   }

   onPlay(){
      this.setState({playing: true});
   }
   
   onChange(e){
      let urlString = e.target.value;
      if(!validURL(urlString)){
         return;
      }

      if(urlString && urlString.trim().length === 0){
         urlString = undefined;
      }

      this.setState({contentSrc: urlString, isValid: true});
   }

   error(message){
      this.props.onError(message);
      this.setState({isValid: false});
   }

   render(){
      return (                
         <div className="community-post-content">
            <div className="url-input-row">
               <input ref={this.state.inputRef} className="post-url-input" type="text" placeholder="Url for media content" onChange={e => this.onChange(e)} />               
            </div>
            <div className="media-content">         
               {(this.props.contentType === "image" && this.state.isValid) &&                
                  <Image className="post-content-image" 
                     src={this.state.contentSrc} alt="uploaded image"
                     onLoad={() => this.props.onChange(this.state.contentSrc)}
                     onError={() => this.error("Invalid image url.")} />
               }
               {(this.props.contentType === "video"  && this.state.isValid) &&   
                  <ReactPlayer
                     className="post-content-video"
                     controls={true}
                     url={this.state.contentSrc}
                     playing={this.state.playing}
                     onPlay={() => this.onPlay()}
                     onPause={() => this.setState({ playing: false })}
                     onReady={() => this.props.onChange(this.state.contentSrc)}
                     onError={() => this.error("Invalid video url.")}
                  />
               }
            </div>
         </div>
      )
   }
}

function validURL(str) {
   let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
   return !!pattern.test(str);
 }