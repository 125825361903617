import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../store/user.store";
import authService from '../services/auth-service';

import UserImage from '../components/user-image/user-image.component';
import Notifications from '../components/notifications/notifications.component';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      selectedFile: null,
    };
  }

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  uploadProfileImg = (e) => {};

  logoutHandler = (e) => {
    authService.clearUser();
  };

  render() {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <div
            className="col-md-3 pull-left"
            style={{ float: "left", textAlign: "left" }}
          >
            <img
              height="60px"
              src={require("../assets/images/logo-white.png")}
              alt="logo"
            ></img>
          </div>
          <div
            className="col-md-9 pull-right"
            style={{ float: "right", textAlign: "right" }}
          >
            <ul
              className="navbar-nav"
              style={{ float: "right", textAlign: "right", marginTop: "-5px" }}
            >
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">               
                    <UserImage className="img-sm rounded-circle" avatarId={this.props.user.avatarId}></UserImage>
                    <Notifications></Notifications>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="preview-list navbar-dropdown">
                    <Dropdown.Item as={Link} to="/user-profile"
                      className="dropdown-item preview-item"
                    >
                      <div className="d-flex align-items-center">
                        <span className="menu-title">My Profile</span>
                      </div>
                    </Dropdown.Item>
                    {this.props.user.roles.includes("Administrator") &&
                      <Dropdown.Item as={Link} to="/site-admin"
                        className="dropdown-item preview-item"
                      >
                        <div className="d-flex align-items-center">
                          <span className="menu-title">Site Admin</span>
                        </div>
                      </Dropdown.Item>
                    }
                    <Dropdown.Item as={Link} to="/FAQ"
                      className="dropdown-item preview-item"
                    >
                      <div className="d-flex align-items-center">
                        <span className="menu-title">FAQs</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/terms-and-conditions"
                      className="dropdown-item preview-item"
                    >
                      <div className="d-flex align-items-center">
                        <span className="menu-title">Terms And Conditions</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="mailto:info@philkenyonputting.com"
                      className="dropdown-item preview-item"
                    >
                      <div className="d-flex align-items-center">
                        <span className="menu-title">Contact Support</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={this.logoutHandler}
                      className="dropdown-item preview-item"
                    >
                      <div className="d-flex align-items-center">
                        <span className="menu-title">Logout</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              <li>
                {" "}
                <button
                  className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                  type="button"
                  onClick={this.toggleOffcanvas}
                >
                  <span className="ti-layout-grid2">
                    {" "}
                    <i className="icon-menu menu-icon"></i>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
  
export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(Navbar);
