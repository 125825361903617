import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import "./Unauthorized.css";

export default class NotFound extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {    
     return (
        <Container className="p-5">
          <Row className="title-row">
            <Col md={12}>                
                <b><h2 className="h1 txt--upper txt--spacing rich-text__heading--background"><span>404 - Page not found</span></h2></b>
            </Col>
          </Row>
           <Row>
              <p>
                  Unfortunately the content you are attempting to access is not available at this time.
              </p>
          </Row>
           <Row>
              <p>
                  If you require any assistance for this issue, please <a href="mailto:info@philkenyonputting.com">Contact us</a>.
              </p>
           </Row>
       </Container>
    );
  }
}


