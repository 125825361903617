import React from 'react';
import { Link } from "react-router-dom";
import DownloadButton from "../../components/downloadButton";

export default  (props) => (
   <div className={"cms-block download-cta-block " + (props.className ? props.className : "")}>
      {props.imageUrl &&
         <Link className="nav-link"
            to={props.newPage ? `${props.file}?inline=true` : props.file}
            download={props.newPage ? false : true}
            target={props.newPage ? "_blank" : "_self"}>
            <img
               className="card-img-top"
               src={props.imageUrl}
               alt={props.text}
            ></img>
         </Link>
      }
      {!props.imageUrl &&
         <DownloadButton file={props.downloadUrl} name={props.text} newPage={props.newPage}></DownloadButton>
      }
   </div>
);