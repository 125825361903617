import apiService from '../services/api-service';

const requestVideoCommentsType = "FETCH_VIDEO_COMMENTS_PENDING";
const receiveVideoCommentsType = "FETCH_VIDEO_COMMENTS_SUCCESS";
const errorVideoCommentsType = "FETCH_VIDEO_COMMENTS_ERROR";

const initialState = { isLoading: false, comments: [], search: undefined, errors: {} };

export const videoActions = {
  requestComments: (reference) => (dispatch, getState) => fetchComments(reference, dispatch, getState().videos, true),
  submitComment: (comment) => (dispatch, getState) => submitComment(comment, dispatch, getState().videos, true),
  deleteComment: (reference, commentId) => (dispatch, getState) => deleteComment(reference, commentId, dispatch, getState().videos, true)
};

function fetchComments(reference, dispatch, state) {
  if(state.isLoading)
  {
    return;
  }

  dispatch({type: requestVideoCommentsType});

  let url = `api/video-lessons/comments/${reference}`;
    apiService.get(url, true)
    .then(resp => {
      return resp.json();
    })
    .then(
      (result) => {
        dispatch({type: receiveVideoCommentsType, comments: result});
      },
      (data) => {
        dispatch({ type: errorVideoCommentsType, errorData: data });
      }
    );
}

function submitComment(comment, dispatch, state) {
  if(state.isLoading)
  {
    return;
  }

  dispatch({type: requestVideoCommentsType});

  let url = `api/video-lessons/comments`;
  apiService.post(url, comment, true)
    .then(resp => {
      return resp.json()
    })
    .then(
      (result) => {
        dispatch({type: receiveVideoCommentsType, comments: result});
      },
      (data) => {
        dispatch({ type: errorVideoCommentsType, errorData: data });
      }
    );
}

function deleteComment(reference, commentId, dispatch, state) {
  if(state.isLoading)
  {
    return;
  }

  dispatch({type: requestVideoCommentsType});

  let url = `api/video-lessons/comments/${reference}/${commentId}`;
  apiService.delete(url, true)
    .then(resp => {
      return resp.json()
    })
    .then(
      (result) => {
        dispatch({type: receiveVideoCommentsType, comments: result});
      },
      (data) => {
        dispatch({ type: errorVideoCommentsType, errorData: data });
      }
    );
}

export const reducer = (state, action) => {
  state = state || initialState;
   
  if (action.type === requestVideoCommentsType) {
      let isLoading = true;
      return { ...state, isLoading, errorData: null };
  }

  if (action.type === receiveVideoCommentsType) {
      let isLoading = false;
      return { ...state, isLoading, comments: action.comments};
  }

  if (action.type === errorVideoCommentsType) {
      let isLoading = false;
      return { ...state, isLoading, errorData : action.errorData };
  }

  return state;
}