import React from "react";
import { Pagination } from 'react-bootstrap';

export default (props) => {
    let pageItems = [
        <Pagination.First onClick={() => onPageChange(1, props)} />,
        <Pagination.Prev onClick={() => onPageChange(props.current - 1, props)} />
    ];
    for(let number = 1; number <= props.total; number++){
        pageItems.push(
            <Pagination.Item key={number} active={number==props.current} onClick={() => onPageChange(number, props)}>{number}</Pagination.Item>
        );
    }
    pageItems.push(
    <Pagination.Next onClick={() => onPageChange(props.current + 1, props)} />, 
    <Pagination.Last  onClick={() => onPageChange(props.total, props)} />
    );

    return (
        <Pagination>
            {pageItems}
        </Pagination>
    )
}

function onPageChange(page, props){
    if(page < 1 || page > props.total || page == props.current)
      return;

    props.onPageChange(page);
}