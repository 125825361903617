import React from "react";

export default props => {
   return (
      <div className="thumbnail" onClick={() => props.onClick(props.path)}>
         <div className="thumbnail-image-row">
            <img className="thumbnail-image" alt={props.path} src={require("../../assets/images/folder.png")}></img>
         </div>
         <div className="thumbnail-text">
            {props.name}
         </div>
      </div>
   );
}