import React, {Component} from "react";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../store/admin.store";

import UserList from './components/user-list.component';
import UserDetails from './components/user-details.component';
import UserRoles from './components/user-roles.component';
import GeneratePassword from './components/generate-password.component';

import './site-admin.page.css';

class SiteAdmin extends Component {
  constructor(){
    super();
    this.state = {
      oldUser: undefined,
      user: { email: "", displayName: "", firstName: "", lastName: "", roles: []},
      edited: false,
      showEditDialog: false,
      generatePassword: false
    }
  }

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.newPassword !== this.props.newPassword) {
      if (this.props.newPassword) {
        this.setState({generatePasswordDialog: true})
      }
    }
  }

  selectUser(user, reset) {
    if (this.state.edited && !reset) {
      this.setState({ userId: user.userId, showEditDialog: true });
      return;
    }

    let newUser = { ...user, roles: [...user.roles] };
    this.setState({ oldUser: user, user: newUser, edited: false })
  }

  change(prop, value) {
    let user = this.state.user;
    let oldUser = this.state.oldUser;

    user[prop] = value;

    let edited = false;
    if (prop === "roles") {
      edited = compareRoles(user, oldUser);
    } else {
      edited = user[prop] !== oldUser[prop];
    }

    this.setState({ user, edited });
  }

  saveChanges() {
    this.props.updateUser(this.state.user.userId, this.state.user);
  }

  cancelChanges() {
    let oldUser = this.state.oldUser;
    let user = { ...oldUser, roles: [...oldUser.roles] };

    this.setState({ user, showEditDialog: false, edited: false })    
  }

  render() {
    return (
      <Container id="site-admin-page" className="site-admin-page p-5">
        <Row className="title-row">
          <h2 className="cms-page-title h1 txt--upper txt--spacing rich-text__heading--background"><span>Website Administration</span></h2>
        </Row>
        <Row className="content-row">
          <Col xl={4}>
            <UserList user={this.state.user} onChange={(user, reset) => this.selectUser(user, reset)}></UserList>
          </Col>
          <Col xl={8} className="user-form">
            <UserDetails user={this.state.user} onChange={(field, value) => this.change(field, value)}></UserDetails>
            <UserRoles user={this.state.user} onChange={(field, value) => this.change(field, value)}></UserRoles>
            <Row>
              <Col md={6}>
                <Button onClick={() => this.setState({ generatePassword: true })}>Generate Password</Button>
              </Col>
              <Col md={6}>
                <Button disabled={!this.state.edited} onClick={() => this.saveChanges()}>Save Changes</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal className="user-admin-dialog" show={this.state.showEditDialog}>
            <Modal.Body>
              <div>
                You have unsaved changes, are you sure you wish to continue.
              </div>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={() => this.cancelChanges()}>
                  Cancel changes
              </Button>
              <Button variant="secondary" onClick={() => this.setState({ showEditDialog: false })}>
                  Return to user edit
              </Button>
              <Button variant="primary" onClick={() => this.saveChanges()}>
                  Save changes
              </Button>
          </Modal.Footer>
        </Modal>
        <GeneratePassword show={this.state.generatePassword} user={this.state.user} onClose={() => this.setState({ generatePassword: false })}></GeneratePassword>
      </Container>
    );
  }
}

export default connect(
  state => state.admin,
  dispatch => bindActionCreators(actions, dispatch)
)(SiteAdmin);


function compareRoles(user, oldUser) {
  let roles = user.roles.map(r => r.roleId);
  let oldRoles = oldUser.roles.map(r => r.roleId);

  return roles.join(",") !== oldRoles.join(",");
}