import React from 'react';
import ReactPlayer from "react-player";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import '../../../cms/cms.css';

export default (props) => (
   <Link to={`/${props.locator[0]}/${props.locator[1]}?videoId=${props.id}`}>
      <Row  className={"cms-block dashboard-search-item-block " + (props.className ? props.className : "")}>
         <Col md={3} mt={4}>
            <div className="player-wrapper">
            {/* <ReactPlayer
               className="react-player"
               controls={true}
               width="100%"
               height="100%"
               url={props.url}
               playing={props.playing}
               onPause={props.onPause}
               onStart={props.onStart}
            /> */}
            </div>
         </Col>
         <Col md={9} mt={8}>
            <Row className="video-title rich-text__heading--background"><h3><span>{props.title}</span></h3></Row>
            <Row className="video-text" dangerouslySetInnerHTML={{ __html: props.text }} ></Row>
            {props.duration && 
               <Row className="video-duration" >Duration: {props.duration}</Row>     
            } 
            <Row className="dashboard-search-tags">
               {props.locator && props.locator.map((v, i) => <span key={i} className="dashboard-search-tag">{v}</span>)}
               {props.tags && props.tags.map((v, i) => <span key={i} className="dashboard-search-tag">{v}</span>)}
            </Row>
         </Col>
      </Row>
   </Link>
);