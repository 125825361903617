import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import ImageBlock from "./image.block.component";

import "../../App.css";

const displayFormat = {
   Columns1: 0,
   Columns2: 1,
   Columns3: 2,
   Columns4: 3,
   Thumbnails: 4
}

export default class CmsImageListBlock extends Component {   
   componentDidMount() {
      window.scrollTo(0, 0)
   }

   render(){
      return(
         <Row className="image-list">
            <Col md={12}>
               {this.renderImages()}
            </Col>
         </Row>   
      )
   }

   renderImages() {
      let rowLength = this.getRowLength();

      let rows = [];
      for (let i = 0; i < this.props.imageList.length; i += rowLength){
         rows.push(<Row key={i}>{this.renderImageRow(this.props.imageList.slice(i, i + rowLength))}</Row>);
      }

      return rows;
   }

   renderImageRow(images) {
      return images.map((v, i) => <Col key={i}><ImageBlock {...v}></ImageBlock></Col>);
   }

   getRowLength() {
      // eslint-disable-next-line default-case
      switch (this.props.displayFormat) {
         case displayFormat.Columns1: return 1;
         case displayFormat.Columns2: return 2;
         case displayFormat.Columns3: return 3;
         case displayFormat.Columns4: return 4;
         case displayFormat.Thumbnails: return 10;
      }
   }
}