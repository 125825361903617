import React from 'react'
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import LeaderBoard from './components/leaderboard/leaderboard.component';
import HandicapGraph from "./components/graphs/handicap-graph.component";
import StrokeGraph from "./components/graphs/stroke-graph.component";
import DrillGraph from "./components/graphs/drill-graph.component";
import AltDrillGraph from "./components/graphs/alt-drill-graph.component";
import HundredFtGraph from "./components/graphs/hundred-ft-graph.component";
import PercentOfTwentyGraph from "./components/graphs/percent-of-twenty-graph.component";
import CmsPage from '../../cms/page.component'; 

import './performance-stats.page.css';


window.scrollTo(0, 0)

function Stats(props) {
    const userId = useSelector(state => state.users.user.userID);
    const nameStr = useSelector(state => state.users.user.displayName);
    const handicap = useSelector(state => state.users.user.handicap);
    
    const roles = useSelector(state => state.users.user.roles);
    const pageTag = roles.includes("PPMember")
        ? "pk-light/performance-stats"
        : "performance-stats";

    return (
        <div className="performance-stats">
            <h4>Your Stats {nameStr}</h4>
            <hr></hr> 
            <Row>
                <Col md={12} className="col-md-6 grid-margin stretch-card">
                    <CmsPage className="performance-stats-videos" pageTag={pageTag} location={props.location} ></CmsPage>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="col-md-6 grid-margin stretch-card">
                    <HandicapGraph></HandicapGraph>
                </Col>
                <Col md={6} className="col-md-6 grid-margin stretch-card">
                    <StrokeGraph handicap={handicap}></StrokeGraph>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="col-md-6 grid-margin stretch-card">
                    <DrillGraph handicap={handicap}></DrillGraph>
                </Col>
                <Col md={6} className="col-md-6 grid-margin stretch-card">
                    <AltDrillGraph handicap={handicap}></AltDrillGraph>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="col-md-6 grid-margin stretch-card">
                    <HundredFtGraph handicap={handicap}></HundredFtGraph>
                </Col>
                <Col md={6} className="col-md-6 grid-margin stretch-card">
                    <PercentOfTwentyGraph handicap={handicap}></PercentOfTwentyGraph>
                </Col>
            </Row>
            <br></br>
            <LeaderBoard userId={userId}></LeaderBoard>
        </div>
    )
}

export default Stats;
