import React from 'react';
import {
   Route,
   Switch
} from "react-router-dom";

import CmsPage from "../../cms/page.component";

import { mapBlock } from "./video-lessons.mapper";

const VideoLessonRouting = props => (
   <Switch>
      <Route exact path="/Video-Lessons"
         component={() => <CmsPage pageTag="video-lessons" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>         
      </Route>
      <Route exact path="/Video-Lessons/Aim-Bias"
         component={() => <CmsPage pageTag="video-lessons/aim-bias" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>         
      </Route>
      <Route
         exact
         path="/Video-Lessons/Stroke-Dynamics"
         component={() => <CmsPage pageTag="video-lessons/stroke-dynamics" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
      <Route
         exact
         path="/Video-Lessons/Speed-Dynamics"
         component={() => <CmsPage pageTag="video-lessons/speed-dynamics" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
      <Route
         exact
         path="/Video-Lessons/Green-Reading"
         component={() => <CmsPage pageTag="video-lessons/green-reading" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
      <Route
         exact
         path="/Video-Lessons/Targeting-Strategy"
         component={() => <CmsPage pageTag="video-lessons/targeting-strategy" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
      <Route
         exact
         path="/Video-Lessons/Ball-Roll-Dynamics"
         component={() => <CmsPage pageTag="video-lessons/ball-roll-dynamics" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
      <Route
         exact
         path="/Video-Lessons/Putter-Fitting"
         component={() => <CmsPage pageTag="video-lessons/putter-fitting" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
      <Route
         exact
         path="/Video-Lessons/Practice-Drills"
         component={() => <CmsPage pageTag="video-lessons/practice-drills" api="video-lessons" location={props.location} mapper={mapBlock} ></CmsPage>}>   
      </Route>
   </Switch>
);

export default VideoLessonRouting;