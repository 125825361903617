import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../App.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../store/user.store";

class Sidebar extends Component {
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/Dashboard">
              <i className="icon-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Video-Lessons">
              <i className="icon-graduation menu-icon"></i>
              <span className="menu-title">Practice Drills</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Your-Stats">
              <i className="icon-graph menu-icon"></i>
              <span className="menu-title">Performance Stats</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Strokes-Gained">
              <i className="icon-graph menu-icon"></i>
              <span className="menu-title">Strokes Gained</span>
            </Link>
          </li>
            <li className="btn-upgrade">
              <Link to="/upgrade">
                Upgrade To Full Member
              </Link>
            </li>
        </ul>
      </nav>
    );
  }
}
  
export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(withRouter(Sidebar));
