import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";

import CmsPage from "../../cms/page.component";

const BonusPageRouting = props => (
   <Switch>
      <Route exact path="/bonus/posture-core"
         component={() => <CmsPage pageTag="bonus/posture-core" location={props.location} api="bonus" ></CmsPage>}>         
      </Route>
      <Route exact path="/bonus/mental-game"
         component={() => <CmsPage pageTag="bonus/mental-game" location={props.location} api="bonus" ></CmsPage>}>         
      </Route>
      <Route exact path="/bonus/nutrition"
         component={() => <CmsPage pageTag="bonus/nutrition" location={props.location} api="bonus" ></CmsPage>}>         
      </Route>
      <Route exact path="/bonus/coach-interviews"
         component={() => <CmsPage pageTag="bonus/coach-interviews" location={props.location} api="bonus" ></CmsPage>}>         
      </Route>
   </Switch>
);

export default BonusPageRouting;