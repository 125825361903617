import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { useFromNow } from './datetime-helpers';

const FromNowComponent = props => {
    return <>
        {useFromNow(props.date) &&
            <span>posted:  <Moment fromNow>{props.date}</Moment></span>
        }
        {!useFromNow(props.date) &&
            <Moment date={props.date} format="YYYY-MM-DD HH:mm" />
        }
    </>
}
export default FromNowComponent;