import React from 'react';
import ReactPlayer from "react-player";

export default  (props) => (
  <div className="col-md-12 mt-4" style={{padding: '10px 4%', border: '2px solid #eeeeee'}}>
   <div className="col-md-12" align="center"><h3>{props.title}</h3></div>
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        controls={true}
        width="100%"
        height="100%"
        url={props.url}
        playing={false}
      />
    </div>
 </div>
);
