import apiService from '../services/api-service';

export const refreshCmsPageType = "REFRESH_PAGE_PENDING";

const requestCmsPageType = "FETCH_PAGE_PENDING";
const receiveCmsPageType = "FETCH_PAGE_SUCCESS";

const errorCmsPageType = "FETCH_CMS_PAGE_ERROR";

const initialState = { loadState: {}, total: null, pages: {}, errorState: {}, refresh: false };

export const cmsActions = {
  requestPage: (page, api, query, secure) => (dispatch, getState) => fetchPage(page, api, query, secure, dispatch, getState().cms)
};

function fetchPage(page, api, query, secure, dispatch, state) {
  let loadState = state.loadState[page];
  if(loadState === true)
  {
    return;
  }

  if (secure === undefined) {
    secure = true;
  }

  dispatch({type: requestCmsPageType, page: page });

  var url = api
    ? `api/${api}/page?tags=${encodeURI(page)}`
    : `api/cms-page/page?tags=${encodeURI(page)}`;
  
  url = query ? `${url}&${query}` : url;
  
  apiService.get(url, secure)
  .then(resp => {
    return resp.json();
  })
    .then(result => dispatch({ type: receiveCmsPageType, page: page, data: result }))
    .catch(error => dispatch({ type: errorCmsPageType, page: page, errorData: error.data })
  );
}

export const reducer = (state, action) => {
  state = state || initialState;
  
  let loadState = {...state.loadState }; 
  let errorState = {...state.errorState };
  let pages = {...state.pages };

  if (action.type === refreshCmsPageType) {
    return { ...state, refresh: true };
  }

  if (action.type === requestCmsPageType) { 
    loadState[action.page] = true;
    errorState[action.page] = undefined;
    return { ...state, loadState, errorState };
  }

  if (action.type === receiveCmsPageType) {
    loadState[action.page] = false;
    pages[action.page] = action.data;
    return { ...state, loadState, pages};
  }

  if (action.type === errorCmsPageType) {
    loadState[action.page] = false;
    errorState[action.page] = action.errorData;
    return { ...state, loadState, errorState};
  }

  return state;
}