import React from 'react';
import { Link } from "react-router-dom";

export default (props) => {
   const name = props.locator[props.locator.length - 1];

   return (
   <div className={`cms-block image-block ${props.className ? props.className : ""} ${name}`}>
      {props.anchorUrl &&
         <Link className="nav-link" to={props.anchorUrl}>
            <img
               className="card-img-top"
               src={props.imageUrl}
               alt="Card cap"
            ></img>
         </Link>
      }
      {!props.anchorUrl &&      
         <img
            className="card-img-top"
            src={props.imageUrl}
            alt="Card cap"
         ></img>
      }
   </div>
)};