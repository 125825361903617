import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter
} from "react-router-dom";

import analytics from "./services/analytics-service";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import configureStore from './store/configureStore';

window.dataLayer = window.dataLayer || [];

const store = configureStore();

// Initialize the desired locales.
JavascriptTimeAgo.locale(en);

//Initialize Google Analytics
analytics.initialize();

ReactDOM.render(
  <BrowserRouter basename='/'>
    <Provider store={store}>
      <App></App>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
