import React from 'react';
import { Row } from 'react-bootstrap';

export default (props) => {
   const name = props.locator[props.locator.length - 1];

   return (
      <Row className={`cms-block rich-text-block ${props.className ? props.className : ""} ${name}`}>
      <div className="content" dangerouslySetInnerHTML={{__html: parseContent(props)}} ></div>
   </Row>
)};

function parseContent(props) {
   var element = document.createElement('html');
   let content = props.content;

   if (props.values) {
      for (let val in props.values) {
         content = content.replace(`[[${val}]]`, props.values[val]);
      }
   }
   element.innerHTML = content;

   let headings = element.getElementsByClassName("rich-text__heading--background")   
   Array.from(headings).forEach(heading => heading.innerHTML = `<span>${heading.innerHTML}</span>`);

   return element.innerHTML;
}