import React from 'react';
import { Container } from "react-bootstrap";

import "./rich-text.css";

export default props => {
   return (
      <Container className="events-features">
        <ul>
          {props.items
            .map((item,index) =>
            {
               const id = item.id ? item.id : item.id;
               const isActive = id === props.activeItem ? "active" : "";
               return (
                  <li
                     onClick={() => props.onChange(id)}
                     key={index}
                     className={`rich-text-item ${isActive}`}
                  >
                     {index + 1}: {item.title}
               </li>
               )
            }
            )}
        </ul>
      </Container>
   )
}