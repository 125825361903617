import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../../store/user.store";

class ChangeUserDetails extends Component {
   constructor() {
      super();

      this.state = {
         edit: false,
         displayName: undefined
      };
   }

  componentDidMount() {
    if (this.props.user) {
      this.setState({displayName: this.props.user.displayName});
    }    
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({displayName: this.props.user.displayName});
    }
  }

  handleEdit(e) {
    e.stopPropagation();
    this.setState({ edit: true });
  }
  
  handleSave(e) {
    e.stopPropagation();
     this.props.updateUserDetails({ displayName: this.state.displayName });
    this.setState({ edit: false });
  }

  handleCancel(e) {
    e.stopPropagation();
    this.setState({ edit: false, displayName: this.props.user.displayName });
  }

   render() {
      return (
         <>
            <Row className="display-name">
              <div className="edit-container">
                {!this.state.edit && 
                  <div onClick={e => this.handleEdit(e)}>
                    {this.state.displayName}
                    <i className="icon-pencil edit-icon"></i>
                  </div>
                }
                {this.state.edit && 
                  <Col xl={12}>
                    <Row>
                      <input type="text" value={this.state.displayName} onChange={(e) => this.setState({displayName: e.target.value})}></input>
                    </Row>
                    <Row>
                      <Col md={5} xs={5} className="no-padding">
                        <Button onClick={(e) => this.handleSave(e)}>Save</Button>
                      </Col>
                      <Col md={1} xs={1} className="no-padding">
                      </Col>
                      <Col md={5} xs={5} className="no-padding">
                        <Button onClick={(e) => this.handleCancel(e)}>Cancel</Button>
                      </Col>
                    </Row>
                  </Col>
                }
              </div>
            </Row>
            <Row className="email-address">{this.props.user.emailAddress}</Row>
          <Row>
            {this.props.user.roles.map((r, i) => <span className="role-tag" key={i}>{r}</span>)}
          </Row>
         </>
      );
   }
}

export default connect(
  state => state.users,
  dispatch => bindActionCreators(userActions, dispatch)
)(ChangeUserDetails);