import React, { Component } from "react";
import { Form, Button, FormGroup, ButtonGroup, ToggleButton } from "react-bootstrap";
import LineGraph from "../../../../components/graph/line.graph.component"
import GraphSeries from "../../../../components/graph/graph-series.component";
import DataPoint from "../../../../components/graph/data-point";

import {LegendContainer, Legend} from "../../../../components/graph/legend.component";
import DrillMedian from "./drill.median.component";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../../store/performance-stats.store";

import AnalyticsService from '../../../../services/analytics-service';

import moment from 'moment';

class StrokeGraph extends Component {
  constructor(props){
    super();

    this.state = {
      labels: [],
      drills: [],
      practice: [],
      medians: [],
      practiceMedians: [],
      showMedians: true,
      handicapRange: {
         minRange: props.handicap,
         maxRange: props.handicap
      },
      optionValue: {},
      optionValues: [
         //{title: "Tournament winner", value: { strokes: 1.3 }}
       ],
       isPractice: false
    };
  }
  
  componentDidMount(){
   this.props.requestStrokes(this.props.handicap, this.props.handicap);
}

  componentDidUpdate(prevProps){
     if(prevProps.strokes.series === this.props.strokes.series){
        return;
     }
     
     this.setSeries(this.state.optionValue);
   }
      
   handleOptionChange(value){
      this.setState({optionValue : value}, () => this.setSeries(value));      
   }
   
   handleMedianChange(minHandicap, maxHandicap) {
      if (this.state.handicapRange.minRange === minHandicap && this.state.handicapRange.maxRange === maxHandicap) {
         return;
      }

      let handicapRange = {
          minRange: minHandicap,
          maxRange: maxHandicap
      }
      this.setState({ handicapRange }, () => this.props.requestStrokes(minHandicap, maxHandicap));
   }

   togglePractice(isPractice) {
      this.setState({isPractice}, () => this.setSeries(this.state.optionValue));
   }

  removeStroke(){
     var strokeId = Math.max.apply(null, this.props.strokes.series.map(x => x.strokesGainedId));

     AnalyticsService.addEvent("Strokes Gained Putting", "Stats-Interaction", "Remove Stroke");
     this.props.removeStrokes(strokeId);
   }

  render() {
    return (
       <LineGraph title="Strokes Gained Putting"  className="stroke-graph" interpolate="true" labels={this.state.labels}>
          {this.state.showMedians &&
             <GraphSeries className="strokegraph-median" color="light-green" labels={this.state.labels} datapoints={this.state.medians}></GraphSeries>
          }
          {!this.state.isPractice &&
             <GraphSeries className="stroke-graph-series" color="green" labels={this.state.labels} datapoints={this.state.drills}></GraphSeries>
          }
          {this.state.isPractice &&
             <GraphSeries className="stroke-graph-series" color="blue" labels={this.state.labels} datapoints={this.state.drills}></GraphSeries>
          }
         <ButtonGroup toggle>
            <ToggleButton
                  key={0}
                  type="radio"
                  variant="primary"
                  name="radio"
                  value={0}
                  checked={!this.state.isPractice}
                  onChange={(e) => this.togglePractice(false)}
            >
                  Golf Course
            </ToggleButton>
            <ToggleButton
                  key={1}
                  type="radio"
                  variant="primary"
                  name="radio"
                  value={1}
                  checked={this.state.isPractice}
                  onChange={(e) => this.togglePractice(true)}
            >
                  Practice
            </ToggleButton>
         </ButtonGroup>
          <DrillMedian
             id="stroke-graph"
             handicap={this.props.handicap}
             active={this.state.showMedians}
             options={this.state.optionValues}
             onOptionChange={(value) => this.handleOptionChange(value)}
             onHandicapChange={(minRange, maxRange) => this.handleMedianChange(minRange, maxRange)}
             setActive={active => this.setState({ showMedians: active })}
          ></DrillMedian>
         <Form onSubmit={this.handleSubmit} className="strokegraph-footer">
            <div className="form-inline">
               <FormGroup className="ml-2 mt-2">
                  <Button type="button" onClick={() => this.removeStroke()} className="btn btn-primary" disabled={this.state.drills.total === 0}>Undo</Button>
               </FormGroup>
            </div>
          </Form>
         <LegendContainer>
             {!this.state.isPractice &&
                <Legend title="Golf Course Average" color="green" value={calculateMedian(this.state.drills.map(x => x.value))}></Legend>
             }
             {this.state.isPractice &&
                <Legend title="Practice Average" color="blue" value={calculateMedian(this.state.drills.map(x => x.value))}></Legend>
             }
             {this.state.showMedians &&
                <Legend title="Handicap Average" color="light-green" value={calculateMedian(this.state.medians.map(x => x.value))}></Legend>
             }
         </LegendContainer>
       </LineGraph>
    );
  }

  getLabels(series){
    let dates = series.map(x => moment(x.dateUpdated).format("YYYYMMDD HHmmss"));
    let sorted  = dates.sort((a,b) => a - b);
    let labels = sorted.map(x => {return { display: moment(x).format("DD-MMM-YYYY HH:mm:ss"), value: x };});

    return labels
  }

  setSeries({ strokes }){
   let series = this.props.strokes.series.filter(x => x.isPractice === this.state.isPractice);
   if(!series)
   {
      return;
   }

    let drills = series.map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), x.score));
     let medians = series.map(x => new DataPoint(moment(x.dateUpdated).format("YYYYMMDD HHmmss"), strokes ? strokes : x.medianScore));
     let labels = this.getLabels(series);

    this.setState({drills, medians, labels})
  }
}

export default connect(
   state => state.performanceStats,
   dispatch => bindActionCreators(actions, dispatch)
 )(StrokeGraph);

 function calculateMedian(arr){
   if(arr.length === 0)
   {
      return 0;
   }

   const mid = Math.floor(arr.length / 2),
      nums = [...arr].sort((a, b) => a - b);
    var result = arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    return result.toFixed(2);
 }