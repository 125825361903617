import React from "react";
import VideoComments from "./components/video-comments.component"

import authService from '../../services/auth-service';
  
export function mapBlock(block, index, caller){
   switch(block.blockType){
     case "video-comments": return <VideoComments key={index} tabId={caller.props.id} reference={caller.state.currentVideo} user={authService.getUser()} {...block}></VideoComments>;
      default: return null;
   }
}