import React from "react";

import './loader.css';

export default props => {
   return (
      <div className={`loader ${props.active ? "active" : ""}`}>
         <div className="spinner"></div>
      </div>
   );
}