import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cmsActions } from "../store/cms.store";

import { mapBlocks } from "./helpers/cms-block-helper";

import { apiErrors } from "../services/api-service";

import './cms.css';

class CmsPage extends Component {
  constructor() {
    super();

    this.state = {
      active: true,
      currentVideo: undefined,
      pageData: { title: "loading...", content: [] }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    if (this.props.pages[this.props.pageTag] && !this.props.refresh) {
      this.setState({ pageData: this.props.pages[this.props.pageTag] });
    } else if ((!this.props.pages[this.props.pageTag] && this.props.pageTag) || this.props.refresh) {
      this.updatePage();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageTag !== prevProps.pageTag && this.props.pageTag) {
      if (this.props.pages[this.props.pageTag]) {
        this.setState({ pageData: this.props.pages[this.props.pageTag] });
      } else if (!this.props.pages[this.props.pageTag] && this.props.pageTag) {
        this.updatePage();
      }
    }

    if (prevProps.pages[this.props.pageTag] !== this.props.pages[this.props.pageTag]) {
      this.setState({ pageData: this.props.pages[this.props.pageTag] });
    }

    let errorState = this.props.errorState[this.props.pageTag];
    if (errorState && prevProps.errorState[this.props.pageTag] !== errorState[this.props.pageTag]) {
      this.handleError();
    }

    if (prevProps.refresh !== this.props.refresh && this.props.refresh) {
      this.updatePage();
    }
  }

  updatePage() {
    this.props.requestPage(this.props.pageTag, this.props.api, this.props.query, this.props.secure);
  }

  changeVideo(videoId) {
    if (this.state.currentVideo === videoId) {
      return;
    }

    this.setState({ currentVideo: videoId });
  }

  hasTitle() {
    return this.state.pageData.title || this.state.pageData.subTitle;
  }

  handleError() {
    let error = this.props.errorState[this.props.pageTag];
  
    if (error.status === apiErrors.ERR404.status)
    {
      this.setState({ title: "loading...", content: [] })
      let url = "/not-found";
      url = error.text ? `${url}?content=${error.text}` : url;
      window.location.href = url;
      return;
    }
  
    if (error.status === apiErrors.ERR401.status)
    {
      let url = "/unauthorized";
      url = error.text ? `${url}?content=${error.text}` : url;
      window.location.href = url;
      return;
    }

  }

  render() {
    return (
      <Container className={`cms-page p-5 cms-page-${this.props.pageTag.replace("/", "_")} ${this.props.className}`}>
        {this.hasTitle() &&
          <Row className="title-row">
            <Col md={12}>
              <h2 className="cms-page-title h1 txt--upper txt--spacing rich-text__heading--background"><span>{this.state.pageData.title}</span></h2>
              <h3 className="cms-page-subtitle"><span>{this.state.pageData.subTitle}</span></h3>
            </Col>
          </Row>
        }
        <Row className="cms-page-body">
          <Col md={12}>
            {this.renderBlocks()}
          </Col>
        </Row>
      </Container>
    );
  }

  renderBlocks() {
    let blocks = this.state.pageData.content;
    return mapBlocks(blocks, this, this.props.mapper);
  }
}

export default connect(
  state => state.cms,
  dispatch => bindActionCreators(cmsActions, dispatch)
)(CmsPage);
