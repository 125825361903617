import React from "react";
import LeaderBoard from '../performance-stats/components/leaderboard/leaderboard.component';
import VideoResource from '../../Resources/components/video.resource.js';

import { useSelector } from "react-redux";

export default (props) => {
   const introvideo = { url: "https://pro-fitpt-1.wistia.com/medias/hcd39v21ar" };
    const userId = useSelector(state => state.users.user.userID);
   
   return (
      <>
         <LeaderBoard userId={userId}></LeaderBoard>
         <VideoResource {...introvideo} ></VideoResource>
      </>
   )
}