import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Login from "../../pages/login/login-page-component";
import Activate from "../../pages/login/activate-page-component";
import DefaultRouting from './default-routing.component';

const InsecureRouting = (props) => {
   return (
      <Switch>
         <Route path="/payment" render={() => <Redirect to={"https://www.philkenyonputting.com/academy%20closed"}></Redirect>}></Route>
         <Route path="/activate" render={() => <Activate></Activate>}></Route>
         <Route path="/login" render={() => <Login></Login>}></Route>
         <Route path="/" render={route => {
            return (<DefaultRouting {...props} default={GetRedirect(route.location)} />)
         }} />
      </Switch>
   );
}

export default InsecureRouting;

function GetRedirect(location) {
   let encoded = encodeURI(location.pathname);
   let search = encodeURI(location.search);
   
   return encoded === "/" ? "/login" : `/login?redirect=${encoded}${search}`;
}