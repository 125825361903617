import React, {Component} from "react";
import { Row, Col } from 'react-bootstrap';

import UserImage from '../components/user-image/user-image.component';

export default class UserProfile extends Component {
  render() {
    return (
       <Row className={`dashboard-profile ${this.props.className}`}>
         <Col xl={6}>
            <Row className="avatar">
               <UserImage className="dashboard-avatar" avatarId={this.props.user.avatarId}></UserImage>
            </Row>
         </Col>
         <Col xl={6}>
            <Row className="display-name">
              {this.props.user.displayName}
            </Row>
            <Row className="email-address">{this.props.user.emailAddress}</Row>
            <Row>
               {this.props.user.roles.map((r, i) => <span className="role-tag" key={i}>{r}</span>)}
            </Row>
         </Col>
      </Row>
    );
  }
}