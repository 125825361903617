import authService from "./auth-service";
import { config } from "../config";

let rootUrl = config.API_ROOT;

const apiErrors =  {
   ERR000 : { status: 0, text : "Unknown Error" },
   ERR400 : { status: 400, text : "Bad Request" },
   ERR401 : { status: 401, text : "Unauthorised" },
   ERR403 : { status: 403, text : "Forbidden" },
   ERR404 : { status: 404, text : "Not found" },
   ERR500 : { status: 500, text : "Server Error" },   

   parseError: function (resp) {
      switch(resp.status){
         case 400: return this.ERR400;
         case 401: return this.ERR401;
         case 403: return this.ERR403;
         case 404: return this.ERR404;
         default: return this.ERR000;
      }   
   }
}

const apiService = {
   get(uri, secure) {
      return fetchFromApi({ secure, method: 'get', uri })
   },

   put(uri, body, secure, contentType) {
      if(!contentType){
         contentType = "json";
      }
      return fetchFromApi({ secure, method: 'put', uri, body, contentType })
   },

   post(uri, body, secure, contentType) {
      if(!contentType){
         contentType = "json";
      }
      return fetchFromApi({ secure, method: 'post', uri, body, contentType })
   },

   delete(uri, secure) {
      return fetchFromApi({ secure, method: 'delete', uri })
   }
}

function addHeaders(secure, contentType){
   let headers = {};
   if(contentType === "json"){
      headers['Content-Type'] = 'application/json';
   }

    if(!secure){
       return headers;
    }

    var token = authService.getToken();
    headers['Authorization'] = 'Bearer ' + token;
    return headers;
}

function fetchFromApi(options) {
   let error = new Error("api exception");
   
   return new Promise((resolve, reject) => {
      if (options.secure && !authService.isAuthenticated()) {
         error.data = apiErrors.ERR401;
         
         return authService.clearUser()
            .then(() => { throw error });
      }
      
      var headers = addHeaders(options.secure, options.contentType);

      if (options.contentType === "json") {
         options.body = JSON.stringify(options.body);
      }

      fetch(`${rootUrl}/${options.uri}`, {
         method: options.method,
         headers: headers,
         body: options.body,
      })
      .then(resp => {
         if (!resp.ok) {
            error.response = resp;
            return handleErrors(error, options);
         }
         resolve(resp);
      })
         .catch(ex => handleErrors(ex, options))
         .catch(ex => reject(ex));
   });      
}

function handleErrors(error, options) {
   let data = apiErrors.parseError(error.response);
   
   return error.response.json()
      .then(
         (errObj) => {
            data.text = errObj.message ? errObj.message : data.text;
            return data;
         },
         (err) => data)
      .then(data => {
         if (options.secure && data === apiErrors.ERR401) {
            if (options.refresh) {
               return authService.clearUser().then(() => { throw error; });
            }
            return refreshToken()
               .then(() => fetch({...options, refresh: true}));
         }

         error.data = data;
         throw error;
      });
}

function refreshToken(refreshToken) {
   return new Promise((resolve, reject) => {
      let token = refreshToken ? refreshToken : authService.getRefreshToken();
      
      fetch({ secure: true, method: "post", uri: `${rootUrl}/api/user/refreshtoken/${token}`, refresh: true})
         .then(resp => resp.json())
         .then((result) => {
            authService.setUser(result.token, result.refToken, false);
            resolve();
         }).catch(err => reject(err));
   });
}

export {
   apiErrors
}

export default apiService;