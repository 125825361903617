import React, {Component} from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions, constants } from "../../../../store/performance-stats.store";

import LeaderboardItem from './leaderboard.item.component';

import '../../performance-stats.page.css';

class Leaderboard extends Component {
   constructor(props) {
      super();

      this.state = {
         currentPosition: { score: 0, handicap: 0 },
         lines: props.leaderboard.lines,
         containerRef: React.createRef(), 
         focusRef: React.createRef(),
         handicaps: mapHandicaps()
      };
   }

   componentDidMount(){
      this.props.requestLeaderboard(this.props.leaderboard.maxStep);
   }

   componentDidUpdate(prevProps){
     if(prevProps.leaderboard.lines === this.props.leaderboard.lines){
        return;
     }
      let currentPosition = this.props.leaderboard.lines.filter(x => x.userId === this.props.userId)[0];
     this.setState({currentPosition: currentPosition ? currentPosition : this.state.currentPosition, lines: this.props.leaderboard.lines, count: this.props.leaderboard.count}, () => this.scrollToRef());
   }

   scrollToRef(){
      let container = this.state.containerRef.current;
      let ref = this.state.focusRef.current;
      if(ref == null)
         return;

      container.scrollTop = ref.offsetTop; 
   }

   loadMore() {
      if(this.state.count <= this.props.leaderboard.maxStep){
         return;
      }
      
      let newMax = this.props.leaderboard.maxStep + constants.LeaderboardStep;
      this.props.requestLeaderboard(newMax);
   }

   loadLess() {
      if(this.props.leaderboard.maxStep < 15){
         return;
      }
      
      let newMax = this.props.leaderboard.maxStep - constants.LeaderboardStep;
      this.props.requestLeaderboard(newMax);
   }

   render() {
      return (
         <Container p={5} className="leaderboard">
            <Row>
               <Col md={6}>
                  <h3>100ft Drill Leaderboard</h3>
               </Col>
               <Col md={6} className="leaderboard-load">
                  <i className={`fa fa-plus-circle load load-more ${this.state.count <= this.props.leaderboard.maxStep ? "disabled" : ""}`} onClick={() => this.loadMore()}></i>
                  <i className={`fa fa-minus-circle load load-less ${this.props.leaderboard.maxStep <= constants.LeaderboardStep ? "disabled" : ""}`} onClick={() => this.loadLess()}></i>                  
               </Col>
            </Row>
            <Row className="leaderboard-item leaderboard-head">
               <Col xs={1}>#</Col>
               <Col xs={2} className="display-center">Avatar</Col>
               <Col xs={2}>Name</Col>
               <Col xs={2} className="display-center">Handicap</Col>
               <Col xs={2} className="display-center">Putt Distance</Col>
               <Col xs={2}>Last Updated</Col>
            </Row>
            <Row className="leaderboard-list" ref={this.state.containerRef}>
               <Col md={12}>
                  {this.renderItems()}
               </Col>
            </Row>
         </Container>
      );
   }

   renderItems() {
      var items = this.state.lines.map((v, i) => {
         v.handicap = v.handicap ? v.handicap : "999";
         let handicap = this.state.handicaps.filter(h => h.key === v.handicap.toString());

         v.handicap = handicap[0] ? handicap[0].value : v.handicap;

         return (<LeaderboardItem
            key={i}
            focusRef={this.state.currentPosition.position === v.position ? this.state.focusRef : undefined}
            current={this.state.currentPosition.position === v.position} {...v} />);
      });
      return items;
   }
}
export default  connect(
   state => state.performanceStats,
   dispatch => bindActionCreators(actions, dispatch)
)(Leaderboard);

function mapHandicaps() {
   var handicaps = [
      { key:"99", value:"Pro" },
      { key:"999", value:"N/A" },
   ];

   for (let h = 1; h < 30; h++)
   {
      handicaps.push({ key:`${h}`, value:`${h}` });
   }

   handicaps.push({ key: "30", value: "30+" });

   return handicaps;
}