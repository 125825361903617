import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import "../../css/custom.css";

export class LockerRoom extends Component {
  componentDidMount() {
  window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="container p-3">
        <div className="text-left">
          <h4 className="squashie">Locker Room </h4>
        </div>
        <hr></hr>
            <div className="row" style={{ paddingLeft: '0%'}}>
                <div className="col-md-6 col-sm-6 col-12 mt-4">
                  <Link to="/Locker-Room/Locker-Aim">
                  <img style={{width:'100%'}}
                src={require("../../assets/images/folder01.png")}
                alt="Aim Bias"
                      /> 
                    </Link>


                </div>

                <div className="col-md-6 col-sm-6 col-12 mt-4">
                <Link to="/Locker-Room/Locker-Start-Line">
                <img style={{width:'100%'}}
                        src={require("../../assets/images/folder02.png")}
                alt="Start Line"
                      /> 
                </Link>
                </div>

                <div className="col-md-6 col-sm-6 col-12 mt-4">
                <Link to="/Locker-Room/Locker-Speed">

                <img style={{width:'100%'}}
                        src={require("../../assets/images/folder03.png")}
                alt="Speed"
                      /> 
                    </Link>
                </div>

                <div className="col-md-6 col-sm-6 col-12 mt-4">
                <Link to="/Locker-Room/Locker-Green-Reading">
                <img style={{width:'100%'}}
                        src={require("../../assets/images/folder04.png")}
                alt="Green Reading"
                      /> 
                    </Link>
                </div>


                <div className="col-md-6 col-sm-6 col-12 mt-4">
                <Link to="/Locker-Room/Locker-Performance-Tests">
                <img style={{width:'100%'}}
                        src={require("../../assets/images/folder05.png")}
                alt="Performance Tests"
                      /> 
                    </Link>
                </div>
            </div>

            <hr></hr>
      </div>
    );
  }
}
