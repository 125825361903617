import React, {Component} from "react";
import { Row, Button, Modal } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../store/admin.store";

class GeneratePassword extends Component {
  constructor(){
    super();
    this.state = {
      generatePasswordDialog: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show !== this.props.show) {
      this.setState({ showWarning: this.props.show });
    }

    if (prevProps.newPassword !== this.props.newPassword) {
      if (this.props.newPassword) {
        this.setState({generatePasswordDialog: true})
      }
    }
  }

  closeWarningDialog() {
      this.setState({ showWarning: false }, () => this.props.onClose());    
  }

  generatePassword() {
    let user = this.props.user;
    this.closeWarningDialog();
    this.props.generatePassword(user.userId);
  }

  render() {
    return (
      <>
        <Modal className="user-admin-dialog" show={this.state.showWarning}>
            <Modal.Body>
              <p>
                Generating a new password will remove and replace the user's current password.
                Are you sure you want to continue?
              </p>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={() => this.closeWarningDialog()}>
                  Cancel
              </Button>
              <Button variant="primary" onClick={() => this.generatePassword()}>
                  Generate New Password
              </Button>
          </Modal.Footer>
        </Modal>
        <Modal className="user-admin-dialog" show={this.state.generatePasswordDialog}>
          <Modal.Body>
            <Row>
              <p>The following password has been generated for {this.props.user.displayName} and is currently active.</p>
            </Row>
            <Row>
              <p>Please send to the user at <a href={`mailto:${this.props.user.email}`}>{this.props.user.email}</a></p>
            </Row>
            <Row>
              <h1>{this.props.newPassword}</h1>
            </Row>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ generatePasswordDialog: false })}>
                  Close
              </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(
  state => state.admin,
  dispatch => bindActionCreators(actions, dispatch)
)(GeneratePassword);