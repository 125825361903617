import React, { Component } from "react";
import { Row } from "react-bootstrap";

export default class Question extends Component {
   constructor() {
      super();
      this.state = { selected: {} }
   }

   componentDidMount() {
      if (this.props.result && this.props.options) {
         let selected = this.props.options.find(x => x.id === this.props.result.id);
         this.setState({selected: selected ? selected : {}})         
      }
   }

   componentDidUpdate(prevProps) {
      if (this.props.result !== prevProps.result) {
         let selected = this.props.options.find(x => x.id === this.props.result.id);
         this.setState({selected: selected ? selected : {}})         
      }
   }

   setRadioValue(id) {
      if (this.state.selected.id === id) {
         return;
      }

      let selected = this.props.options.find(x => x.id === id);
      this.setState({selected: selected ? selected : {}}, () => this.props.onChange(selected))
   }

   render(){
      return (
         <div className="question">
            <Row>
               <p>{this.props.title}</p>
            </Row>
            <Row>
               {this.renderOptions()}
            </Row>
         </div>
      )
   }

   renderOptions() {
      return this.props.options.map((o, i) => 
         <label key={i} className="question-option">
            <input type="radio" value={o.id} checked={o.id === this.state.selected.id} onChange={e => this.setRadioValue(e.target.value)} />
            {o.displayName}
         </label>
      );
   }
}
