import React, {Component} from "react";
import { Row, Col } from 'react-bootstrap';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../store/admin.store";

class UserList extends Component {
  constructor(){
    super();
    this.state = {
      includeInactive: false,
      userList: [],
      sortBy: "lastName"
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userList !== this.props.userList) {      
      this.updateUserList(true);
    }

    if (prevState.includeInactive !== this.state.includeInactive) {
      this.updateUserList(false);
    }

    if (prevState.sortBy !== this.state.sortBy) {
      this.updateUserList(false);
    }
  }

  updateUserList(reset) {
   let filtered = this.props.userList.filter(x => this.state.includeInactive || x.isActive === true);

   let sortBy = this.state.sortBy;
   let sorted = filtered.sort((a, b) => {
   if ( a[sortBy] < b[sortBy] ){
      return -1;
   }
   if ( a[sortBy] > b[sortBy] ){
      return 1;
   }
   return 0;      
   });

   let selected = sorted.find(x => x.userId === this.props.user.userId);
   if (!selected) {
      selected = { ...sorted[0] };
   }
     
   this.setState({ userList: sorted }, () => this.selectUser(selected.userId, reset));    
  }

  selectUser(userId, reset) {
   let selected = this.props.userList.find(x => x.userId === userId);
   let user = { ...selected, roles: [...selected.roles] };

   this.props.onChange(user, reset);
  }

  changeActiveState(e) {
    e.stopPropagation();
    this.setState({ includeInactive: e.target.checked });
  }

   renderUsers() {
    return this.state.userList.map((user, i) =>
      <Row key={i} className={`user-item ${!user.isActive ? "inactive" : ""} ${user.userId === this.props.user.userId ? "active" : ""}`} onClick={() => this.selectUser(user.userId)}>
        <Col md={6}>{`${user.lastName} ${user.firstName}`}</Col>
        <Col md={6}>{user.email}</Col>
      </Row>
      );
  }

  render() {
    return (
      <>
         <Row className="user-list">
            <Col>
               {this.renderUsers()}
            </Col>
         </Row>
         <Row className="user-list-controls">
            <Col>
               <input type="checkbox" id="check-active" className="active-checkbox" checked={this.state.includeInactive} onChange={e => this.changeActiveState(e)}></input>
               <label htmlFor="check-active"> Include inactive</label>
            </Col>
            <Col>
               <label htmlFor="sort">Sort By</label>
                <select name="sort-by" id="sort" className="sort-select" value={this.state.sortBy} onChange={e => this.setState({sortBy: e.target.value})}>
                   <option value="lastName">Last Name</option>
                   <option value="email">Email Address</option>
               </select>
            </Col>
         </Row>
      </>
    );
  }
}

export default connect(
  state => state.admin,
  dispatch => bindActionCreators(actions, dispatch)
)(UserList);