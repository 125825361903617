import React, { Component } from "react";
import "../../../css/Dashboard.css";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import UserProfile from '../../../Dashboard/profile-component'; 
import VideoResource from '../../../Resources/components/video.resource.js';

import '../../../Dashboard/dashboard.page.css';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() { 
    const introvideo = { url: "https://pro-fitpt-1.wistia.com/medias/loembdjqab" };
    
    return (
      <React.Fragment>
        <div className="container p-3 dashboard-page">
          <div className="title-row row">
            <h2 className="cms-page-title h1 txt--upper txt--spacing rich-text__heading--background"><span>Welcome to your Putting Performance Dashboard</span></h2>
          </div>
          <Row className="dashboard-row">
            <Col xl={12}>
              <Row>
                  <Col xl={6} className="dashboard-introduction">
                    <UserProfile className="dashboard-profile__sm" user={this.props.user}></UserProfile>
                    <h4>Welcome to your Putting Performance Programme</h4>
                    <VideoResource {...introvideo} ></VideoResource>
                    <div className="dashboard-introduction-text">
                      <p>
                        This programme is designed to help focus your practice with a series of specifically designed performance drills that will help you dial in on the key areas you need to perform in to become a great putter.
                      </p>
                      <br/>
                      <p>
                        Within the Putting Performance Programme we have identified 7 “Academy preferred Performance Drills” designed to test and improve every aspect of your putting. These drills are scorable, so we can objectively measure and track your performance through our purpose built tracking system.
                      </p>
                    <br/>
                    <br/>
                      <div>
                        <p><strong>•	100 ft Drill</strong></p>
                        <p><strong>•	5ft Broadie Drill</strong></p>
                        <p><strong>•	10ft Broadie Drill</strong></p>
                        <p><strong>•	15ft Broadie Drill</strong></p>
                        <p><strong>•	4-8ft Drill</strong></p>
                        <p><strong>•	9 Hole Stroke Gained Drill</strong></p>
                        <p><strong>•	18 Hole Stroke Gained Drill</strong></p>
                    </div>
                    <br/>
                    <br/>
                    <p>
                      Performance Practice is an essential part of practice that will help you transfer the skills you are working on in practice to the golf course. It also encourages the development and practice of all the skills required to become a great putter. Start Line Speed and Read as well as the cognitive aspects of performance which can only be trained by recreating the demands we have on the course in practice.
                    </p>
                  </div>
                </Col>
                <Col xl={6} className="dashboard-profile-col">
                  <UserProfile className="dashboard-profile__xl" user={this.props.user}></UserProfile>
                  <div>
                    <p>
                      Tracking your performance over time will allow you to monitor the level you are performing at in the key statistical areas, which are primarily inside 20ft
                    </p>
                    <p>
                      The drills are designed to recreate the putt characteristics and intensity you experience on the golf course. <strong>If you want to get better at performing in an environment, practice performing in that environment.</strong>
                    </p>
                      <br/>
                    <p>
                      The Academy Preferred Drills can be found in the <Link to="/Video-Lessons/Practice-Drills">practice drills</Link> section, this is where you can also comment and ask questions on the drills.
                      For quick reference they are also located on the <Link to="/Your-Stats">Performance Stats Page</Link> where you will find data input graphs to enable you to track and monitor your performance on the drills.
                      You will also find video instructions for each Performance Drill on the performance stats page.
                      The downloads section is where you will find our Preferred Drill Instructions and also score sheets for the relevant drills.
                    </p>
                    <br/>
                      <p>
                        As well as tracking your Performance Practice you can also track your on course Stroke Gained Putting and directly compare this to your Practice Stroke Gained Putting Challenges. You can also track your handicap improvement as a consequence over time within the Performance stats section.
                      </p>
                      <br/>
                      <p>
                        In your <Link to="/Video-Lessons">Video Library</Link> section, you can view introduction videos to the “Elements of Putting”, which form the structure of our main academy programme and as an added bonus we have also included 13 additional <a href="/Video-Lessons/Practice-Drills">performance drill videos</a> you can supplement your practice with aimed at bringing your putting to the next level. 
                      </p>
                    <br />
                     <p>
                        ... Of course, if in time you need more, including access to our community, all of the drills and lessons available, 300+ videos as well as a tailored 12 week journey towards your putting goals,
                        you are more than welcome to join us in the academy and we can simply <Link to="/upgrade">upgrade your membership</Link> at any time. 
                      </p>
                    <p>
                      Have Fun.
                    </p>
                    <br/>
                      <p>
                        To watch me take you step by step through how to get the best out of the putting performance programme click on the introduction video.
                      </p>
                  </div> 
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="divider"></Row>
          <div className="clearfix"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="text-left">
                <h4>Videos Categories </h4>
                <hr></hr>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
             
                <Link className="nav-link" to="/video-lessons/aim-bias">
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/01.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
              </div>
           

            <div className="col-md-6 mb-3">
                <Link className="nav-link" to="/video-lessons/stroke-dynamics/">
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/02.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
               
              </div>
          

            <div className="col-md-6 mb-3">
           
                <Link className="nav-link" to="/video-lessons/speed-dynamics/">
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/03.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
               
             
            </div>

            <div className="col-md-6 mb-3">
             
                <Link className="nav-link" to="/video-lessons/green-reading/">
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/04.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>

                
             
            </div>

            <div className="col-md-6 mb-3">
            
                <Link
                  className="nav-link"
                  to="/video-lessons/targeting-strategy/"
                >
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/05.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
               
            </div>

            <div className="col-md-6 mb-3">
            
                <Link
                  className="nav-link"
                  to="/video-lessons/ball-roll-dynamics/"
                >
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/06.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
                

            </div>

            <div className="col-md-6 mb-3">
              
                <Link className="nav-link" to="/video-lessons/putter-fitting">
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/07.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
              
          
            </div>

            <div className="col-md-6 mb-3">
            
                <Link className="nav-link" to="/video-lessons/practice-drills">
                  <img
                    className="card-img-top"
                    src={require("../../../assets/images/08.jpg")}
                    style={{ width: "100%" }}
                    alt="Card cap"
                  ></img>
                </Link>
              
            
            </div>
          </div>
          <div></div>
     
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => state.users
)(Dashboard);
