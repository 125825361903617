import React, { useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import DownloadButton from '../../components/downloadButton';
import "react-notifications-component/dist/theme.css";

import '../resources.css';

function ResourcesBreakCharts() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="container p-3">
          <div className="text-left">
            <h4 className="squashie">Break Charts</h4>
          </div>
          <hr></hr>

          <div className="blog-wrap">
            <div className="container">
              <div className="widget widget-categories">
                <Row mt={3}>
                  <Col md={12}>
                    <div className="resource-image-container">
                      <img className="resourceImage stimp-chart"
                        src={require("../../assets/images/golf-stimp-chart.jpg")}
                        alt="golf stimp chart"
                        />
                    </div>
                  </Col>
                </Row>
                    
                <Row mt={3}>
                  <Col md={6}>
                    <DownloadButton file={require('../../assets/downloads/pk_academy_8_9.pdf')}
                                      name="PKPA Break Chart Downloads Stimp 8 &amp; 9"/>
                  </Col>

                  <Col md={6}>
                    <DownloadButton file={require('../../assets/downloads/pk_academy.pdf')}
                                      name="PKPA Break Chart Downloads Stimp 10, 11, 12, 13"/>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourcesBreakCharts;
