import React from 'react';
import AnalyticsService from '../services/analytics-service';

const DownloadButton = props => {
  const url = props.newPage ? `${props.file}?inline=true` : props.file;

  return (
    <a href={url}
      style={{
      background: 'black',
      color: 'white',
      padding: '20px',
      borderRadius: '5px',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'block',
      margin: '10px 0px'
      }}
      onClick={e => AnalyticsService.addEvent("File Download", "Downloads", props.file)}
      download={props.newPage ? false : true}
      target={props.newPage ? "_blank" : "_self"}
      >{props.name}</a>
  );
}

export default DownloadButton;
